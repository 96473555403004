import { useWindowDimensions, View, Image, TextInput } from 'react-native'
import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/native'
import { Footer, Header, IvyPresto, IvyPrestoSemiBold, SSTextMed, SSTextBold, } from '../constants/GlobalElements'
import { useHover } from 'react-native-web-hooks'
import { useNavigation } from '@react-navigation/native'
import { goCheckout } from '../components/ProductGrid/ProductCheckout'
import { UserAuth } from '../context/AuthContext'
import { auth } from '../firebaseConfig'
import { underW1025, underW821, underW769, underW1367, underW1181, underW376underH668, standardStyles } from '../styles/SignUpScreen.styles'

const textColor = "#49381f"

const Container = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #f4ecd9;
    min-height: 100vh;
`
const UIContainer = styled.View`
    width: 100%;
    align-items: center;
    height: calc(100vh - 190px)
`
const SignUpCore = styled.View`
    background: #fffcf3;
    width: 100%;
    height: 600px;
    border-radius: 28px;
    padding: 20px;
    padding-left: 5%;
    padding-right: 5px;
    flex-direction: row;
    border: 1px solid rgba(226, 222, 204, .7);
    box-shadow: 0 5px 32px rgba(181, 150, 104, .1);
`
const Column = styled.View`
    display: flex;
    justify-content: center;
    // border: 1px dashed yellow;
    flex: 1;
    padding: 45px;
`

const ButtonBox = styled.View`
    align-items: end;
    justify-content: center;
    height: max-content;
    width: 100%;
    padding-top: 30px;
    // display: block;
`
const ConfirmButton = styled.Pressable`
    display: flex;
    border-radius: 999px;
    padding: 5px;
    width: max-content;
    height: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all .2s ease;
`
const Line = styled.View`
    border-top-color: rgba(226, 222, 204, .7);
    border-top-width: 1px;
    margin-top: 19px;
    width: 400px;
`
const Input = styled.TextInput`
    height: 45px;
    width: 100%;
    border: 1px solid rgba(226, 222, 204, 1);
    border-radius: 7px;
    margin-top: 7px;
    background: #fcfaf2;
    padding-left: 12px;
    color: rgb(117, 99, 73);
`
const SignUpGifteeDetailsScreen = ({ route }) => {
    const { width, height } = useWindowDimensions();
    // TAKES PRODUCT ID FROM 'GET STARTED PAGE' AND ACCESSES DATABASE FOR TITLE AND ASSOCIATED PICTURE FOR THAT ID
    const { productID = "5", productTitle = "Engagement Viddy", stripePriceID = "stripePriceID", productPrice = "$Free.99", productPicUrl = "https://i.pinimg.com/564x/d3/15/30/d31530fa3c0da6109aab53c14e92cd96.jpg", videoIsFor = "purchaser" } = route.params ?? {};
    const { user } = UserAuth();
    const videoFor = videoIsFor;
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const navigation = useNavigation();

    // FOR FUTURE DATA PASSING
    const [gifteeFirstName, setGifteeFirstName] = useState('')
    const [gifteeLastName, setGifteeLastName] = useState('')
    const [gifteeEmail, setGifteeEmail] = useState('')

    const [styles, setStyles] = useState(standardStyles)

    useEffect(() => {
        setStyles(standardStyles)
        // IPHONE SE

        // IPAD/IPAD MINI PORTRAIT
        if (width < 769) {
            setStyles(underW769)
        }
        // IPAD/IPAD MINI PORTRAIT LANDSCAPE
        else if (width < 1025) {
            setStyles(underW1025)
        }
        else if (width < 1181) {
            setStyles(underW1181)
        }
        else if (width < 1367) {
            setStyles(underW1367)
        }

    }, [width, height])

    const confirmDetails = () => {

        // 🔴 IF NOT SIGNED IN
        if (auth.currentUser == null) {
            navigation.navigate('SignUpRegistration', {
                // LATER SHOULD INCLUDE GIFTEE PROPS TO PASS TO SUCCESS URL WITHIN GOCHECKOUT
                videoIsFor: videoFor,
                productID: productID,
                productPrice: productPrice,
                productTitle: productTitle,
                productPicUrl: productPicUrl,
                stripePriceID: stripePriceID
            })
            // 🟢 IF SIGNED IN
        } else if (auth.currentUser != null) {
            goCheckout(user, stripePriceID, gifteeEmail, gifteeFirstName, gifteeLastName);
        }
    }
    return (
        <Container>
            <UIContainer>
                <Header width={width} height={height} />
                <View
                    nativeID='mainBody'
                    style={[{
                        width: "60%",
                        justifyContent: "center",
                        height: "100%"
                    },
                    styles.mainBody,
                    width < 500 && {
                        width: '90%'
                    },
                    height < 415 && {
                        width: '60%',
                        height: 'unset',
                        minHeight: '80%'

                    },
                    ]}>
                    <SignUpCore
                        style={[
                            styles.signUpCore,
                            width < 500 && {
                                flexDirection: 'column-reverse',
                                height: 'unset',
                                marginTop: '-3%',
                                padding: '10px',
                                paddingBottom: '15px',
                                justifyContent: 'flex-start'
                            },

                        ]}>
                        <Column
                            nativeID='firstColumn'
                            style={[{
                                borderRightColor: "rgba(226, 222, 204, .7)",
                                borderRightWidth: "1px",
                                flexBasis: "55%",
                                justifyContent: "center",
                                paddingRight: '7%'
                            },
                            styles.firstColumn,
                            width < 500 && {
                                borderRightWidth: '0px',
                                width: '100%',
                                padding: '10px',
                                flexBasis: 'unset',
                                justifyContent: 'center',
                                alignItems: 'center',
                                paddingTop: '0px',
                            },

                            ]}>
                            <View nativeID="viewInsideFirstColumn" style={[{ justifyContent: "center", height: "70%" }, width < 500 && { width: '95%', justifyContent: "center", paddingTop: '0px', height: "290px" }]}>
                                <IvyPresto fontSize={width < 500 ? '19px' : '25px'} color={textColor} lineHeight='110%'>
                                    Gift Recipient Details
                                </IvyPresto>
                                <Line style={[width < 500 && { width: '100%' }, styles.line]} />
                                <View style={[{ paddingTop: "25px" }, width < 500 && { paddingTop: '20px', paddingLeft: '0px', display: 'block' }, styles.wrapperOfInputs]}>
                                    <View style={{ flexDirection: "row" }}>
                                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                                            First Name
                                        </SSTextMed>
                                        <SSTextMed color="tomato" fontSize={width < 500 ? '12px' : '14px'}>
                                            *
                                        </SSTextMed>
                                    </View>
                                    <Input
                                        onChangeText={text => setGifteeFirstName(text)}
                                        keyboardType="default"
                                        style={[width < 500 && { height: '40px' }, styles.input]}
                                    />
                                    <View style={[{ flexDirection: "row", paddingTop: "20px" }, width < 500 && { paddingTop: '10px' }, styles.labelWrap]}>
                                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                                            Last Name
                                        </SSTextMed>
                                        <SSTextMed color="tomato" fontSize={width < 500 ? '12px' : '14px'}>
                                            *
                                        </SSTextMed>
                                    </View>
                                    <Input
                                        onChangeText={text => setGifteeLastName(text)}
                                        keyboardType="default"
                                        style={[width < 500 && { height: '40px' }, styles.input]}
                                    />
                                    <View style={[{ flexDirection: "row", paddingTop: "20px" }, width < 500 && { paddingTop: '10px' }, styles.labelWrap]}>
                                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'} >
                                            Gift Recipient's E-mail Address
                                        </SSTextMed>
                                        <SSTextMed color="tomato" fontSize={width < 500 ? '12px' : '14px'}>
                                            *
                                        </SSTextMed>
                                    </View>
                                    <Input
                                        onChangeText={text => setGifteeEmail(text)}
                                        keyboardType="email-address"
                                        style={[width < 500 && { height: '40px' }, styles.input]}
                                    />
                                </View>
                            </View>
                            <ButtonBox style={[width < 500 && { width: '95%', paddingTop: '10px' }, styles.buttonBox]}>
                                <ConfirmButton
                                    ref={ref}
                                    onPress={() => confirmDetails()}
                                    style={[{
                                        background: "#c3cc32"
                                    },
                                    isHovered && {
                                        background: '#bec730',
                                        transform: "translateY(-1px)"
                                    },
                                    width < 500 && {
                                        padding: '4px'
                                    }]}>
                                    <View style={{ paddingRight: "10px", paddingLeft: "20px" }}>
                                        {
                                            auth.currentUser == null ?
                                                <SSTextBold color={isHovered ? "#f7f5ea" : "#f7f5ea"} fontSize={width < 500 ? "13px" : "15px"}>Next</SSTextBold> :
                                                <SSTextBold color={isHovered ? "#f7f5ea" : "#f7f5ea"} fontSize={width < 500 ? "13px" : "15px"}>Checkout</SSTextBold>
                                        }

                                    </View>
                                    <View
                                        style={[{
                                            background: "#cbd536",
                                            borderRadius: "999px",
                                            height: "33px",
                                            width: "33px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            transitionProperty: "background",
                                            transitionDuration: ".2s",
                                            transitionTimingFunction: "ease",
                                        },
                                        isHovered && {
                                            background: "#c7d135"
                                        },
                                        width < 500 && {
                                            height: '30px',
                                            width: '30px'
                                        }]}>
                                        <Image
                                            resizeMode="contain"
                                            source={require('../../assets/darkgreenarrow.png')}
                                            style={[{
                                                height: '15px',
                                                width: '15px'
                                            },
                                            width < 500 && {
                                                height: '13px',
                                                width: '13px'
                                            }]} />
                                    </View>
                                </ConfirmButton>
                            </ButtonBox>
                        </Column>
                        <Column style={[{ flexBasis: "45%", alignItems: "center" }, styles.secondColumn, width < 500 && { flexBasis: 'unset', width: '100%', padding: '10px', display: 'block' },]}>
                            <Image
                                source={{ uri: productPicUrl }}
                                style={[{
                                    width: "300px",
                                    height: "370px",
                                    borderRadius: "17px",
                                    borderColor: "#eae7d5",
                                    borderWidth: "9px"
                                },
                                styles.image,
                                width < 500 && {
                                    width: '100%',
                                    height: '200px'
                                },
                                ]} />
                            <View style={[{ width: "300px", paddingLeft: "10px", paddingTop: "5px" }, styles.titleWrapper, width < 500 && { display: 'none' }]}>
                                <IvyPrestoSemiBold color={textColor} lineHeight="100%" fontSize='25px'>
                                    {productTitle}
                                </IvyPrestoSemiBold>
                            </View>
                        </Column>
                    </SignUpCore>
                </View>
            </UIContainer>
            <Footer width={width} height={height} />
        </Container>
    )
}

export default SignUpGifteeDetailsScreen;
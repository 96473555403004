import { View, Image } from 'react-native'
import React, { useRef, useEffect } from 'react'
import styled from '@emotion/native'
import { Footer, Header, IvyPresto, SSText, SSTextBold } from '../constants/GlobalElements'
import { useHover } from 'react-native-web-hooks'

// import styled, { css } from '@emotion/native'
// import { H1 } from '../constants/GlobalElements'
import { AbsoluteFill, Video } from 'remotion';
import { VideoPlayer } from '../components/VideoPlayer';
// import { IvyPresto, IvyPrestoItalic, IvyPrestoSemiBold, IvyPrestoSemiBoldItalic, SSText, SSTextItalic, SSTextMed, SSTextMedItalic, SSTextBold, SSTextBoldItalic } from '../constants/GlobalElements'

import { httpsCallable } from '@firebase/functions';
import { storage, db, functions, auth } from '../firebaseConfig';
import { ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage'
import { UserAuth } from '../context/AuthContext';
import { orderBy, doc, getDoc } from 'firebase/firestore'

const Container = styled.View`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
`;

const FinishedInterviewScreen = ({ route, navigation }) => {
  const { user } = UserAuth();
  const uid = user.uid;
  const videoId = route.params.videoId;
  console.log(route.params.questions[0].question);

  // storage ref to the user's video folder that order by name
  const storageRef = ref(storage, "videos/" + user.uid + "/" + videoId + "/");
  const dbRef = doc(db, "users/" + user.uid + "/videos/" + videoId);
  const imageRef = ref(storage, 'images/' + user.uid + '/temp/');
  const listRef = listAll(storageRef, { orderBy: orderBy("Name") });

  const [videoUrl, setVideoUrl] = React.useState([]);
  const [metaData, setMetaData] = React.useState([]);
  const [pictureUrl, setPictureUrl] = React.useState([]);
  const [doneUrl, setDoneUrl] = React.useState("");

  useEffect(() => {
    getDoc(dbRef)
      .then((doc) => {
        if (doc.exists()) {
          console.log("Document data:", doc.data());
          setVideoUrl(doc.data().url);
          setMetaData(doc.data().durationInSeconds);
          // setPictureUrl(doc.data().pictureUrl);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  const videoUrlArray = videoUrl.map((url) => {
    return url;
  });

  const metaDataArray = metaData.map((metaData) => {
    return parseFloat(metaData);
  });

  const pictureUrlArray = pictureUrl.map((url) => {
    return url;
  });

  const render = () => {
    // const shotstack = httpsCallable(functions, "shotStackFunctions-shotstack");
    // shotstack({
    //   urls: videoUrlArray,
    //   duration: metaDataArray,
    //   user: uid,
    //   videoId: videoId,
    //   questions: route.params.questions
    // })
    //   .then((result) => {
    //     console.log(result.data);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // remotion server side rendering
    const start = httpsCallable(functions, 'start');
    start({
      videoUrlArray: videoUrlArray,
      metaDataArray: metaDataArray,
      pictureUrlArray: pictureUrlArray,
      uid: user.uid,
      questions: route.params.questions
    }).then(result => {
      console.log(result);
    }).catch(error => {
      console.log(error);
    });

    navigation.navigate("UserDashboard");
  };

  const download = () => {
    const a = document.createElement("a");
    a.href = doneUrl;
    a.download = doneUrl.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

    return (
      <AbsoluteFill>
        <button onClick={render}>Render Shotstack</button>
      </AbsoluteFill>
    );
};

export default FinishedInterviewScreen;
import { View, Image, useWindowDimensions } from "react-native";
import React, { useState, useRef, useEffect } from "react";
import styled, { css } from "@emotion/native";
import { useNavigation } from "@react-navigation/native";
import {
  Footer,
  Header,
  IvyPresto,
  IvyPrestoItalic,
  IvyPrestoSemiBold,
  SSTextMed,
  SSTextBold,
} from "../constants/GlobalElements";
import { useHover } from "react-native-web-hooks";
import { UserAuth } from "../context/AuthContext";
import { goCheckout } from "../components/ProductGrid/ProductCheckout";
import { underW1025, underW821, underW769, underW1367, underW1181, underW376underH668, standardStyles } from '../styles/SignUpScreen.styles'


const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #f4ecd9;
  min-height: 100vh;
`;
const UIContainer = styled.View`
  width: 100%;
  align-items: center;
  height: calc(100vh - 190px);
`;
const SignUpCore = styled.View`
  background: #fffcf3;
  width: 100%;
  height: 600px;
  border-radius: 28px;
  padding: 20px;
  padding-left: 5%;
  padding-right: 5px;
  flex-direction: row;
  border: 1px solid rgba(226, 222, 204, 0.7);
  box-shadow: 0 5px 32px rgba(181, 150, 104, 0.1);
`;
const Column = styled.View`
  display: flex;
  justify-content: center;
  // border: 1px dashed yellow;
  flex: 1;
  padding: 45px;
`;
const textColor = "#49381f";

const ButtonBox = styled.View`
  align-items: end;
  justify-content: center;
  // height: 20%;
  width: 100%;
`;
const ConfirmButton = styled.Pressable`
  display: flex;
  border-radius: 999px;
  padding: 5px;
  width: max-content;
  height: max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
`;
const ButtonTemplate = styled.Pressable`
  display: flex;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  flex: 1;
  height: 45px;
  flex-direction: row;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
`;

const DetailsBox = styled.View`
  border: 1px dashed tomato;
  padding: 20px;
  border-radius: 5px;
  position: absolute;
  top: 13px;
`;
const ConfirmationButton = (props) => {
  const email = props.email;
  const password = props.password;
  const firstName = props.firstName;
  const lastName = props.lastName;
  const stripePriceID = props.stripePriceID;
  const width = props.width;
  const { emailSignUp, user } = UserAuth();
  const navigation = useNavigation();
  const handleEmailSignUp = () => {
    console.log("trying Email Signup Main Return");
    try {
      emailSignUp(email, password, firstName, lastName, stripePriceID).then(
        goCheckout(user, stripePriceID)
      );
      // navigation.navigate("SignUpLogin", { email: email, password: password, stripePriceID: stripePriceID, firstName: firstName, lastName: lastName });

    } catch (err) {
      console.log(err);
    }
  };
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <ConfirmButton
      onPress={() => handleEmailSignUp(email, password, firstName, lastName)}
      ref={ref}
      style={[{
        background: "#c3cc32"
      },
      isHovered && {
        background: '#bec730',
        transform: "translateY(-1px)"
      },
      width < 500 && {
        padding: '4px'
      }
      ]}
    >
      <View style={{ paddingRight: "10px", paddingLeft: "20px" }}>
        <SSTextBold color='#fdfffa' fontSize={width < 500 ? "13px" : "15px"}>
          Confirm
        </SSTextBold>
      </View>
      <View
        style={[
          {
            background: "#cbd536",
            borderRadius: "999px",
            height: "33px",
            width: "33px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transitionProperty: "background",
            transitionDuration: ".2s",
            transitionTimingFunction: "ease",
          },
          isHovered && { background: "#c7d135" },
          width < 500 && {
            height: '30px',
            width: '30px',
          }
        ]}
      >
        <Image
          resizeMode="contain"
          source={require("../../assets/darkgreenarrow.png")}
          style={[{ height: "15px", width: "15px" }, width < 500 && { height: '13px', width: '13px' }]}
        />
      </View>
    </ConfirmButton>
  );
};

const GoogleButton = ({ width, stripePriceID, route }) => {
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);
  console.log("Found a Stripe Price ID", stripePriceID);
  const navigation = useNavigation();
  const { googleSignIn, user } = UserAuth();
  const handleGoogleSignIn = async () => {
    console.log("trying Google Signin");
    try {
      await googleSignIn();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ButtonTemplate
      onPress={handleGoogleSignIn}
      ref={ref}
      nativeID="googleButton"
      style={[
        {
          background: "white",
          shadowColor: '#B59668',
          shadowOffset: { width: 0, height: 0 },
          shadowOpacity: 0.15,
          shadowRadius: 16,
        },
        isHovered && {
          transform: "translateY(-1px)",
          shadowOffset: { width: 0, height: 3 },
          shadowOpacity: 0.15,
          shadowRadius: 16,
        },
        width < 500 && {
          height: '38px'
        }
      ]}
    >
      <Image
        resizeMode="contain"
        source={require("../../assets/google.png")}
        style={[{ height: "25px", width: "25px" }, width < 500 && { height: '20px', width: '20px' }]}
      />
      <View style={{ paddingRight: "10px", paddingLeft: "10px" }}>
        <SSTextMed color="rgba(0,0,0,.7)" fontSize={width < 500 ? "13px" : "15px"}>Sign up with Google</SSTextMed>
      </View>
    </ButtonTemplate>
  );
};
const SignInButton = ({ width }) => {
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);
  const navigation = useNavigation();
  return (
    <ButtonTemplate
      ref={ref}
      onPress={() => navigation.navigate("SignUpLogin")}
      style={[
        {
          background: "#eee3ce",
          borderRadius: 999,
          flex: "1",
          flexBasis: "50px",
          justifyContent: "space-between",
        },
        isHovered && {
          background: "#eadfcc",
          transform: "translateY(-1.5px)"
        },
        width < 500 && {
          height: '38px',
          flexBasis: '20%',
          justifyContent: 'flex-end',
          paddingRight: '4px'
        }
      ]}
    >
      <View style={{ paddingRight: "10px", paddingLeft: "20px" }}>
        <SSTextBold color={isHovered ? "#3a2a1d" : "#5A4736"} fontSize={width < 500 ? "13px" : "15px"}>
          Sign In
        </SSTextBold>
      </View>
      <View
        style={[
          {
            borderRadius: "999px",
            height: "33px",
            width: "33px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#F2EADA",
            transitionProperty: "background",
            transitionDuration: ".2s",
            transitionTimingFunction: "ease",
          },
          isHovered && { background: "#ede5d5" },
          width < 500 && {
            height: '30px',
            width: '30px'
          }
        ]}
      >
        <Image
          resizeMode="contain"
          source={require("../../assets/brownrightarrow.png")}
          style={[{ height: "14px", width: "14px" }, width < 500 && { height: '13px', width: '13px' }]}
        />
      </View>
    </ButtonTemplate>
  );
};
const Line = styled.View`
  border-top-color: rgba(226, 222, 204, 0.7);
  border-top-width: 1px;
  margin-top: 19px;
  width: 100%;
`;
const Input = styled.TextInput`
  height: 45px;
  width: 100%;
  border: 1px solid rgba(226, 222, 204, 1);
  border-radius: 7px;
  margin-top: 7px;
  background: #fcfaf2;
  padding-left: 12px;
  color: rgb(117, 99, 73);
`;
const SignUpRegistrationScreen = ({ route }) => {
  const { width, height } = useWindowDimensions();
  // receive parameters with backups
  const {
    productID = "5",
    productTitle = "Engagement Viddy",
    stripePriceID = "",
    productPrice = "$Free.99",
    productPicUrl = "https://i.pinimg.com/564x/d3/15/30/d31530fa3c0da6109aab53c14e92cd96.jpg",
    videoIsFor = "purchaser",
  } = route.params ?? {};

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  console.log("Sign Up Registration Stripe ID:", stripePriceID);
  const { user } = UserAuth();

  if (user) {
    console.log("user" + user);
    if (stripePriceID != "") {
      console.log("Trying to Checkout");
        goCheckout(user, stripePriceID);
    } else {
      console.log("Stripe Price ID not set, Preventing Checkout.");
    }
  }

  const [styles, setStyles] = useState(standardStyles)

  useEffect(() => {
    setStyles(standardStyles)

    // IPAD/IPAD MINI PORTRAIT
    if (width < 769) {
      setStyles(underW769)
    }
    // IPAD/IPAD MINI PORTRAIT LANDSCAPE
    else if (width < 1025) {
      setStyles(underW1025)
    }
    else if (width < 1181) {
      setStyles(underW1181)
    }
    else if (width < 1367) {
      setStyles(underW1367)
    }
  }, [width, height])

  return (
    <Container>
      <UIContainer
        nativeID='UIContainer'
        style={[
          height < 415 && {
            minHeight: '100vh',
            height: 'unset'
          }]}
      >
        <Header width={width} height={height} />
        <View
          nativeID='mainBody'
          style={[{
            width: "60%",
            justifyContent: "center",
            height: "100%"
          },
          styles.mainBody,
          width < 500 && {
            width: '90%'
          },
          height < 415 && {
            width: '60%',
            height: 'unset',
            minHeight: '80%'
          },
          ]}>
          <SignUpCore
            nativeID='signUpCore'
            style={[
              styles.signUpCore,
              width < 500 && {
                flexDirection: 'column-reverse',
                height: 'unset',
                marginTop: '-3%',
                padding: '10px',
                justifyContent: 'flex-start'
              },
              height < 415 && {
                height: 'unset',
                justifyContent: 'space-between',
                height: '80%',
                padding: 10,
                top: '-2%',
                gap: 20
              },
            ]}>
            <Column
              nativeID='firstColumn'
              style={[{
                borderRightColor: "rgba(226, 222, 204, .7)",
                borderRightWidth: "1px",
                flexBasis: "55%",
                justifyContent: "space-between",
                paddingRight: '7%'
              },
              styles.firstColumn,
              width < 500 && {
                borderRightWidth: 0,
                width: '100%',
                padding: '10px',
                flexBasis: 'unset',
                justifyContent: 'center',
                alignItems: 'center'
              },
              height < 415 && {
                padding: '5%',
                // paddingBottom: 0,
                paddingTop: '8%',
                width: '35%',
                flexBasis: '35%',
                borderRightWidth: 0
              },
              ]}>
              <View nativeID="viewInsideFirstColumn" style={[{ justifyContent: "center", height: "50%" }, width < 500 && { width: '95%', paddingTop: '7px', height: "unset" }]}>
                <IvyPresto fontSize={height < 415 ? '17px' : width < 500 ? '19px' : '25px'} color={textColor} lineHeight='110%'>
                  Register
                </IvyPresto>
                <Line style={[width < 500 && { width: '100%' }, styles.line]} />
                <View style={[{ paddingTop: "25px" }, width < 500 && { paddingTop: '15px', paddingLeft: '0px', display: 'block' }, styles.wrapperOfInputs]}>
                  {/* <View
                    style={{
                      flexDirection: "row",
                      gap: "15px",
                      marginBottom: "15px",
                    }}
                  >
                    <View style={{ width: "50%", flex: "1" }}>
                      <View style={{ flexDirection: "row" }}>
                      <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                          First Name
                        </SSTextMed>
                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                          *
                        </SSTextMed>
                      </View>
                      <Input
                        onChangeText={(text) => setFirstName(text)}
                        keyboardType="default"
                      />
                    </View>
                    <View style={{ width: "50%", flex: "1" }}>
                      <View style={{ flexDirection: "row" }}>
                      <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                          Last Name
                        </SSTextMed>
                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                          *
                        </SSTextMed>
                      </View>
                      <Input
                        onChangeText={(text) => setLastName(text)}
                        keyboardType="default"
                      />
                    </View>
                  </View> */}
                  <View style={{ flexDirection: "row" }}>
                    <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                      E-mail
                    </SSTextMed>
                    <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                      *
                    </SSTextMed>
                  </View>
                  <Input
                    onChangeText={(text) => setEmail(text)}
                    keyboardType="email-address"
                    style={[width < 500 && { height: '40px' }, styles.input]}
                  />
                  <View style={{ flexDirection: "row", paddingTop: '10px' }}>
                    <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                      Password
                    </SSTextMed>
                    <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                      *
                    </SSTextMed>
                  </View>
                  <Input
                    value={password}
                    onChangeText={(text) => setPassword(text)}
                    keyboardType="new-password"
                    secureTextEntry
                    style={[width < 500 && { height: '40px' }, styles.input]}
                  />
                </View>
              </View>
              <ButtonBox style={[{ paddingTop: '18px', paddingBottom: '15px' }, styles.buttonBox, width < 500 && { width: '95%', paddingTop: '18px', paddingBottom: '8px' }]}>
                <ConfirmationButton
                  email={email}
                  password={password}
                  firstName={firstName}
                  lastName={lastName}
                  width={width}
                />
              </ButtonBox>
              <View
                style={[{
                  flexDirection: "row",
                  gap: "15px",
                  justifyContent: "center",
                  height: "30px",
                  width: "100%",
                },
                width < 500 && {
                  width: '95%',
                }
                ]}
              >
                <Line style={{ marginTop: "9px", flex: "1" }} />
                <SSTextMed color="#756349" fontSize={width < 500 ? "13px" : "14px"}>
                  Or
                </SSTextMed>
                <Line style={{ marginTop: "9px", flex: "1" }} />
              </View>
              <View nativeID='alternativeLogins' style={[{ flexDirection: "row", gap: "15px", paddingTop: '5px', justifyContent: "space-between" }, width < 500 && { gap: '8px', width: '95%' }]}>
                <SignInButton width={width} />
                <GoogleButton stripePriceID={stripePriceID} width={width} />
              </View>
            </Column>
            <Column
              nativeID='secondColumn'
              style={[{
                flexBasis: "45%",
                alignItems: "center"
              },
              styles.secondColumn,
              width < 500 && {
                flexBasis: 'unset',
                width: '100%',
                padding: '10px',
                display: 'block'
              },
              height < 415 && {
                padding: 0,
                width: "25%",
                flexBasis: '13%',
              },
              ]}>
              {/* PRODUCT SIDE OF THE SIGNUP BOX*/}
              <Image
                source={{ uri: productPicUrl }}
                style={[{ width: "300px", height: "370px", borderRadius: "17px", borderColor: "#eae7d5", borderWidth: "9px" }, styles.image, width < 500 && { width: '100%', height: '200px' }]} />
              <View style={[{ width: "300px", paddingLeft: "10px", paddingTop: "5px" }, styles.titleWrapper, width < 500 && { display: 'none' }]}>
                <IvyPrestoSemiBold color={textColor} lineHeight="100%" fontSize="25px">
                  {productTitle}
                </IvyPrestoSemiBold>
              </View>
            </Column>
          </SignUpCore>
        </View>
      </UIContainer>
      <Footer width={width} height={height} />
    </Container>
  );
};

export default SignUpRegistrationScreen;

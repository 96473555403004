import { View, Image, useWindowDimensions, TextInput } from 'react-native'
import React, { useState, useRef, useEffect } from 'react'
import styled from '@emotion/native'
import { Footer, Header, IvyPresto, IvyPrestoSemiBold, SSTextMed, SSTextBold, } from '../constants/GlobalElements'
import { underW1025, underW821, underW769, underW1367, underW1181, underW376underH668, standardStyles } from '../styles/SignUpScreen.styles'

import { TouchableOpacity } from 'react-native-web'
import { useHover } from 'react-native-web-hooks'
import { useNavigation } from '@react-navigation/native'
import { goCheckout } from '../components/ProductGrid/ProductCheckout'
import { UserAuth } from '../context/AuthContext'
import { doc, getDoc, getDocs, getFirestore, collection, query, addDoc } from "firebase/firestore"
import { storage, auth } from '../firebaseConfig';
import Loader from "../components/Loader";

const textColor = "#49381f"

const Container = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #f4ecd9;
    min-height: 100vh;
`
const UIContainer = styled.View`
    width: 100%;
    align-items: center;
    height: calc(100vh - 190px)
`
const SignUpCore = styled.View`
    background: #fffcf3;
    width: 100%;
    height: 600px;
    border-radius: 28px;
    padding: 20px;
    padding-left: 5%;
    padding-right: 5px;
    flex-direction: row;
    border: 1px solid rgba(226, 222, 204, .7);
    box-shadow: 0 5px 32px rgba(181, 150, 104, .1);
`
const Column = styled.View`
    display: flex;
    justify-content: center;
    // border: 1px dashed yellow;
    flex: 1;
    padding: 45px;
`

const ButtonBox = styled.View`
    align-items: end;
    justify-content: center;
    height: 30%;
    width: 100%;
`
const ConfirmButton = styled.Pressable`
    display: flex;
    border-radius: 999px;
    padding: 5px;
    width: max-content;
    height: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all .2s ease;
`
const Line = styled.View`
    border-top-color: rgba(226, 222, 204, .7);
    border-top-width: 1px;
    margin-top: 19px;
    width: 400px;
`
const Input = styled.TextInput`
    height: 45px;
    width: 50%;
    border: 1px solid rgba(226, 222, 204, 1);
    border-radius: 7px;
    margin-top: 7px;
    background: #fcfaf2;
    padding-left: 12px;
    color: rgb(117, 99, 73);
`

const SignUpScreen = ({ route }) => {
    const { width, height } = useWindowDimensions()
    
    // TAKES PRODUCT ID FROM 'GET STARTED PAGE' AND ACCESSES DATABASE FOR TITLE AND ASSOCIATED PICTURE FOR THAT ID
    const { productID = "5", productTitle = "Engagement Viddy", stripePriceID = "stripePriceID", productPrice = "$3.99", productPicUrl = "https://i.pinimg.com/564x/d3/15/30/d31530fa3c0da6109aab53c14e92cd96.jpg" } = route.params ?? {};
    const { user } = UserAuth();
    // console.log("route params" + JSON.stringify(route.params.productTitle));
    console.log("route params " + productID);
    // console.log(user.uid)

    useEffect(() => {
        if (!user) {
          navigation.push("SignIn" , {productID});
        }
      }, [user]);

    // console.log("user object " + JSON.stringify(user))
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const navigation = useNavigation();
    const [loader, setLoader] = useState(false);

    const [selected, setSelected] = useState(1)
    const [videoFor, setVideoFor] = useState("purchaser")
    const [text, setText] = useState('');
    const [coupon, setCoupon] = useState('');

    const getUserData = async () => {
        const db = getFirestore();
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            console.log("%c Document exists", "color: #049688");
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }

    const getCouponsData = async () => {
        try {
            const db = getFirestore();
            const couponsRef = collection(db, 'Coupons');
            const querySnapshot = await getDocs(couponsRef);
            querySnapshot.forEach(doc => {
                setCoupon(doc.data().code0)
            });
        } catch (error) {
            console.error('Error getting documents: ', error);
        }
    };

    getCouponsData();

    const getOwnedProducts = async () => {
        try {
            const db = getFirestore();
            const userDocRef = doc(db, "users", user.uid);
            const userDocSnap = await getDoc(userDocRef);
    
            if (userDocSnap.exists()) {
                console.log("User document data:", userDocSnap.data());
    
                // Accessing the subcollection "ownedProducts"
                const ownedProductsRef = collection(userDocRef, "ownedProducts");
    
                // Query existing products to avoid duplicates
                const existingProductsQuerySnapshot = await getDocs(ownedProductsRef);
                const existingProductIds = existingProductsQuerySnapshot.docs.map(doc => doc.data().productId);
                
                let newProductId = productID;
                let index = 0;

                existingProductIds.forEach((id) => {
                    if(id.slice(0, -1) == newProductId){
                        index++;
                    }
                })
    
                // Add a new document to the "ownedProducts" subcollection with the generated product ID
                const newOwnedProductRef = await addDoc(ownedProductsRef, { productId: newProductId + index });
                console.log("New product added to ownedProducts with ID:", newOwnedProductRef.id);
            } else {
                console.log("No such user document!");
            }
        } catch (error) {
            console.error("Error adding product to ownedProducts:", error);
        }
    };
    

    useEffect(() => {
        // 🔴 IF NOT SIGNED IN
        if (auth.currentUser == null) {
            console.log("%c User not logged in", "color: #ff8c00");
            // 🟢 IF SIGNED IN
        } else if (auth.currentUser != null) {
            getUserData();

        }


    });
    
    const [styles, setStyles] = useState(standardStyles)

    useEffect(() => {
        setStyles(standardStyles)
        // IPHONE SE

        // IPAD/IPAD MINI PORTRAIT
        if (width < 769) {
            setStyles(underW769)
        }
        else if (width < 821) {
            setStyles(underW821)
        }
        // IPAD/IPAD MINI PORTRAIT LANDSCAPE
        else if (width < 1025) {
            setStyles(underW1025)
        }
        else if ( width < 1181) {
            setStyles(underW1181)
        }
        else if ( width < 1367) {
            setStyles(underW1367)
        }

    }, [width, height])

    // OBJECT BELOW INCLUDES DATA REQUIRED FOR NEXT PAGE - "PURCHASER" OR "GIFTEE"
    const radios = [
        {
            id: "1",
            innerText: "Myself",
            for: "purchaser"
        },
        {
            id: "2",
            innerText: "Someone Else",
            for: "giftee"
        }
    ]

    const setParameters = (val) => {
        setSelected(val.id)
        setVideoFor(val.for)
        console.log(val.for);
    }

    const confirmDetails = () => {
        // 🔴 IF NOT SIGNED IN
        setLoader(true);
        if (auth.currentUser == null) {
            if(text == coupon){
                getOwnedProducts();
                navigation.navigate('UserDashboard')
            }
            // IF PURCHASE IS FOR PURCHASER
            else if (videoFor == "purchaser") {
                navigation.navigate('SignUpRegistration', {
                    videoIsFor: videoFor,
                    productID: productID,
                    productPrice: productPrice,
                    productTitle: productTitle,
                    productPicUrl: productPicUrl,
                    stripePriceID: stripePriceID
                })
            } else {
                // IF PURCHASE IS FOR SOMEONE ELSE
                navigation.navigate('SignUpGifteeDetails', {
                    videoIsFor: videoFor,
                    productID: productID,
                    productPrice: productPrice,
                    productTitle: productTitle,
                    productPicUrl: productPicUrl,
                    stripePriceID: stripePriceID
                })
            }
            // 🟢 IF SIGNED IN
        } else if (auth.currentUser != null) {
            if(text == coupon){
                getOwnedProducts();
                navigation.navigate('UserDashboard')
            }
            // IF PURCHASE IS FOR PURCHASER
            else if (videoFor == "purchaser") {
                goCheckout(user, stripePriceID);
            } else {
                // IF PURCHASE IS FOR SOMEONE ELSE
                navigation.navigate('SignUpGifteeDetails', {
                    videoIsFor: videoFor,
                    productID: productID,
                    productPrice: productPrice,
                    productTitle: productTitle,
                    productPicUrl: productPicUrl,
                    stripePriceID: stripePriceID
                })
            }
        }

    }

    return (
        <Container nativeID='container'>
            <UIContainer
                nativeID='UIContainer'
                style={[
                    height < 415 && {
                        minHeight: '100vh',
                        height: 'unset'
                    }]}
            >
                <Header width={width} height={height} />
                <View
                    nativeID='mainBody'
                    style={[{
                        width: "60%",
                        justifyContent: "center",
                        height: "100%"
                    },
                    styles.mainBody,
                    width < 500 && {
                        width: '90%'
                    },
                    height < 415 && {
                        width: '60%',
                        height: 'unset',
                        minHeight: '80%'

                    },
                    ]}>
                    <SignUpCore
                        nativeID='signUpCore'
                        style={[
                            styles.signUpCore,
                            width < 500 && {
                                flexDirection: 'column-reverse',
                                height: 'unset',
                                marginTop: '-3%',
                                padding: '10px',
                                justifyContent: 'flex-start'
                            },
                            height < 415 && {
                                height: 'unset',
                                justifyContent: 'space-between',
                                height: '80%',
                                padding: 10,
                                top: '-2%',
                                gap: 20
                            },
                            ]}>
                        <Column
                            nativeID='firstColumn'
                            style={[{
                                borderRightColor: "rgba(226, 222, 204, .7)",
                                borderRightWidth: "1px",
                                flexBasis: "55%",
                                justifyContent: "space-between",
                                paddingRight: '7%'
                            },
                            styles.firstColumn,
                            width < 500 && {
                                borderRightWidth: 0,
                                width: '100%',
                                padding: '10px',
                                flexBasis: 'unset',
                                justifyContent: 'center',
                                alignItems: 'center'
                            },
                            height < 415 && {
                                padding: '5%',
                                // paddingBottom: 0,
                                paddingTop: '8%',
                                width: '35%',
                                flexBasis: '35%',
                                borderRightWidth: 0
                            },
                            ]}>
                            <View
                                nativeID="viewInsideFirstColumn"
                                style={[{
                                    justifyContent: "center",
                                    height: "60%"
                                },
                                width < 500 && {
                                    width: '95%',
                                    justifyContent: "center",
                                    paddingTop: '0px'
                                },
                                height < 415 && {
                                    paddingTop: 0
                                }]}>
                                <IvyPresto fontSize={height < 415 ? '17px' : width < 500 ? '19px' : '25px'} color={textColor} lineHeight='110%'>
                                    Who will be making the memory video?
                                </IvyPresto>
                                <Line style={[width < 500 || height < 415 && { width: '100%' }, styles.line]} />
                                <View
                                    style={[{
                                        paddingTop: "25px"
                                    },
                                    height < 415 && {
                                        paddingTop: 17
                                    }]}>
                                    {radios.map((val) => {
                                        return (
                                            <TouchableOpacity
                                                key={val.id}
                                                onPress={() => { setParameters(val) }}
                                                style={[{
                                                    flexDirection: "row",
                                                    gap: "20px",
                                                    paddingBottom: "20px"
                                                },
                                                width < 500 && {
                                                    paddingBottom: '15px'
                                                },
                                                height < 415 && {
                                                    paddingBottom: 10
                                                }]}>
                                                <View
                                                    style={[{
                                                        height: 18,
                                                        width: 18,
                                                        borderRadius: 999,
                                                        borderWidth: 1.5,
                                                        borderColor: textColor,
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        transitionTimingFunction: 'ease',
                                                        transitionProperty: 'all',
                                                        transitionDuration: '.2s'
                                                    },
                                                    height < 415 && {
                                                        height: 14,
                                                        width: 14
                                                    }]}>
                                                    {
                                                        val.id == selected ?
                                                            <View
                                                                style={[{
                                                                    height: 9,
                                                                    width: 9,
                                                                    borderRadius: 999,
                                                                    backgroundColor: "#756349",
                                                                    transitionTimingFunction: 'ease',
                                                                    transitionProperty: 'all',
                                                                    transitionDuration: '.2s'
                                                                },
                                                                height < 415 && {
                                                                    height: 8,
                                                                    width: 8
                                                                }]} />
                                                            : null
                                                    }
                                                </View>
                                                <SSTextMed color={textColor} fontSize={height < 415 ? '12px' : width < 500 ? '13px' : '16px'}>{val.innerText}</SSTextMed>
                                            </TouchableOpacity>
                                        )
                                    })}
                                </View>
                                {videoFor !== "giftee" && user && (
                                    <>
                                        <View style={[{ flexDirection: "row", paddingTop: "20px" }, width < 500 && { paddingTop: '10px' }, styles.labelWrap]}>
                                            <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'} >
                                                Promo Code
                                            </SSTextMed>
                                        </View>
                                        <Input
                                            onChangeText={(text) => setText(text)}
                                            keyboardType="default"
                                            style={[width < 500 && { height: '40px' }, styles.input]}
                                        />
                                    </>
                                )}
                            </View>
                            <ButtonBox
                                style={[
                                    width < 500 && {
                                        width: '95%',
                                        paddingTop: '20px'
                                    },
                                    height < 415 && {
                                        height: 'unset'
                                    }]}>
                                {!loader ?
                                <ConfirmButton
                                    ref={ref}
                                    onPress={() => confirmDetails()}
                                    style={[{
                                        background: "#c3cc32"
                                    },
                                    isHovered && {
                                        background: '#bec730',
                                        transform: "translateY(-1px)"
                                    },
                                    width < 500 && {
                                        padding: 4
                                    },
                                    height < 415 && {
                                        padding: 3
                                    }]}>
                                        <View style={[{ paddingRight: "10px", paddingLeft: "20px" }, width < 500 || height < 415 && { paddingRight: '8px', paddingLeft: '15px' }]}>
                                            {
                                                videoFor == "purchaser" ?
                                                    <SSTextBold color={isHovered ? "#f7f5ea" : "#f7f5ea"} fontSize={height < 415 ? '12px' : width < 500 ? "13px" : "15px"}>Checkout</SSTextBold> :
                                                    <SSTextBold color={isHovered ? "#f7f5ea" : "#f7f5ea"} fontSize={height < 415 ? '12px' : width < 500 ? "13px" : "15px"}>Next</SSTextBold>
                                            }
                                        </View>
                                    <View
                                        style={[
                                            {
                                                background: "#cbd536",
                                                borderRadius: "999px",
                                                height: "33px",
                                                width: "33px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                transitionProperty: "background",
                                                transitionDuration: ".2s",
                                                transitionTimingFunction: "ease",
                                            },
                                            isHovered && {
                                                background: "#c7d135"
                                            },
                                            width < 500 && {
                                                height: '30px',
                                                width: '30px',
                                            },
                                            height < 415 && {
                                                height: '27px',
                                                width: '27px'
                                            }
                                        ]}>
                                        <Image
                                            resizeMode="contain"
                                            source={require('../../assets/darkgreenarrow.png')}
                                            style={[{
                                                height: '15px',
                                                width: '15px'
                                            },
                                            width < 500 && {
                                                height: '13px',
                                                width: '13px'
                                            },
                                            height < 415 && {
                                                height: '13px',
                                                width: '13px'
                                            }]} />
                                    </View>
                                </ConfirmButton>
                                :
                                <Loader />
                            }
                            </ButtonBox>
                        </Column>
                        <Column
                            nativeID='secondColumn'
                            style={[{
                                flexBasis: "45%",
                                alignItems: "center"
                            },
                            width < 500 && {
                                flexBasis: 'unset',
                                width: '100%',
                                padding: '10px',
                                display: 'block'
                            },
                            height < 415 && {
                                padding: 0,
                                width: "25%",
                                flexBasis: '13%',
                            },
                            styles.secondColumn]}>
                            <Image
                                source={{ uri: productPicUrl }}
                                resizeMode="cover"
                                style={[
                                    
                                    {
                                        width: "300px",
                                        height: "370px",
                                        borderRadius: "17px",
                                        borderColor: "#eae7d5",
                                        borderWidth: "9px"
                                    },
                                    styles.image,
                                    width < 500 && {
                                        width: `100%`,
                                        height: '200px'
                                    },
                                    height < 415 && {
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        borderWidth: 0,
                                        height: '100%',
                                        width: '100%'
                                    },
                                ]} />
                            <View style={[{ width: "300px", paddingLeft: "10px", paddingTop: "5px" }, styles.titleWrapper, (width < 500 || height) < 415 && { display: 'none' }]}>
                                <IvyPrestoSemiBold color={textColor} lineHeight="100%" fontSize='25px'>
                                    {productTitle}
                                </IvyPrestoSemiBold>
                            </View>
                        </Column>
                    </SignUpCore>
                </View>
            </UIContainer>
            <Footer width={width} height={height} />
        </Container>
    )
}

export default SignUpScreen;
import { View, Pressable, Image, useWindowDimensions } from 'react-native'
import React, { useEffect, useState } from 'react'
import styled from '@emotion/native'
import Animated, {
    withSpring,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
    withDelay,
} from 'react-native-reanimated';
import {
    Header,
    Footer,
    SSText
} from '../constants/GlobalElements'
import {
    WordAnimator,
    NavButtonLeft,
    NavButtonRight,
    StartRecordingButton,
    FinishRecordingButton,
    SubmitRecordingButton,
    DiscardButton,
    SubmittedAlert,
    GifComponent
} from '../constants/InterviewElements';

const Questions =
    [
        {
            id: "1",
            question: "Did you participate in any high school activities?"
        },
        {
            id: "2",
            question: "What are your favorite family traditions?"
        },
        {
            id: "3",
            question: "What is the best advice you have given or received?"
        },

    ]

const Container = styled.View`
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    // min-height: calc(100vh + 90px);
    background: #f4ecd9;
`
const MainBody = styled.View`
    display: flex;
    width:80%;
    min-height:70vh;
    flex-direction: column;
`

const TextWrapper = styled.View`
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
`
const Column = styled.View`
    display: flex;
    width: 45%;
    height: 100%;
    justify-content: center;
`
const LowerBody = styled.View`
`
const NavigationSection = styled.View`
    display: flex; 
    flex-direction: row; 
    align-items: center; 
    justify-content: center;
    // border: 1px dashed tomato; 
`
const InterviewSection = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 100%;
    padding-left: .5%;
    padding-right: .5%;
    justify-content: space-between;
    margin-top: 40px;
`
const ButtonArea = styled.View`
    padding-top: 12px;
    flex-direction: row;
    justify-content: flex-start;
    width: 600px;
    flex-wrap: wrap;
`
const ImageContainer = styled.View`
    z-index: 777;
    position: relative;
`
const RedDot = styled.View`
    position: absolute;
    height: 15px;
    width: 15px;
    background: #E47434;
    borderRadius: 999px;
    top: 15px;
    right: 15px;
    z-index: 999
`
const InsertPhotoPressable = styled.Pressable`
    
    borderRadius: 999px;
    // bottom: 15px;
    // right: 15px;
    z-index: 998
`

const TryItScreen = () => {
    const { width, height } = useWindowDimensions();

    const [count, setCount] = useState(0);
    const [textArray, setText] = useState([]);
    const [seed, setSeed] = useState(1);
    const [recordButtonState, setRB] = useState(false);
    const [finishButtonState, setFB] = useState(true);
    const [submitButtonState, setSB] = useState(true);
    const [discardButtonState, setDB] = useState(true);
    const [insertPhotoButtonState, setIPB] = useState(true);
    const [recording, setRecording] = useState(false);
    const [ticking, setTicker] = useState(false);
    const [gifSource, setGifSource] = useState(null);

    // ANIMATION START VALUES
    // start recording button values
    const offsetRecordButton = useSharedValue(0);
    const visibilityRecordButton = useSharedValue(1);
    const visibilityDurationRecordButton = useSharedValue(100);

    // finish recording button values
    const offsetFinishButton = useSharedValue(-43);
    const visibilityFinishButton = useSharedValue(0);
    const visibilityDurationFinishButton = useSharedValue(400);

    // submit answer button values
    const offsetSubmitButton = useSharedValue(-86);
    const visibilitySubmitButton = useSharedValue(0);
    // discard answer button values
    const offsetDiscardButton = useSharedValue(-86);
    const visibilityDiscardButton = useSharedValue(0);
    const discardButtonDelay = useSharedValue(50);

    // 'submitted!' alert values 
    const offsetSubmittedAlert = useSharedValue(-43);
    const visibilitySubmittedAlert = useSharedValue(0);
    const visibilityDurationSubmittedAlert = useSharedValue(400);

    // insert photo values 
    const offsetInsertPhotoButton = useSharedValue(-30);
    const visibilityInsertPhotoButton = useSharedValue(0);

    // submit button group visibility duration
    const buttonGroupVisibilityDuration = useSharedValue(400);

    const OnRecordStart = () => {
        // ANIMATION END VALUES
        // start recording button exiting
        offsetRecordButton.value = 43;
        visibilityRecordButton.value = 0;
        setRB(true);
        // .8s per frame .gif -> https://i.imgur.com/IoeKGVm.gif
        setGifSource(`https://i.imgur.com/TG38tgG.gif?${Date.now()}`)
        setTicker(true);

        setTimeout(() => {
            setTicker(false);
            setRecording(true);
            // finish recording button entering
            offsetFinishButton.value = 0;
            visibilityFinishButton.value = 1;
            setFB(false);

            // prepping submit button group for entrance 
            buttonGroupVisibilityDuration.value = 400;
            discardButtonDelay.value = 50;
            offsetSubmitButton.value = -86;
            offsetDiscardButton.value = -86;
            offsetInsertPhotoButton.value = -30;
            visibilityDurationSubmittedAlert.value = 400;

            // prepping 'submitted!' alert for entrance
            offsetSubmittedAlert.value = -43;
            // change opacity change duration on the finish button from 400 to 100 
            setTimeout(() => {
                visibilityDurationFinishButton.value = 100;
            }, 400);
        }, 3000)

    }
    const OnRecordFinish = () => {
        // finish button exit and disabling
        offsetFinishButton.value = 43;
        visibilityFinishButton.value = 0;
        setFB(true);
        setRecording(false);

        // move start recording button to below submit button and change opacity change duration
        offsetRecordButton.value = -43
        visibilityDurationRecordButton.value = 400

        // submit button entering/enabling
        offsetSubmitButton.value = -43;
        visibilitySubmitButton.value = 1;
        setSB(false);

        // discard button entering/enabling
        offsetDiscardButton.value = -43;
        visibilityDiscardButton.value = 1;
        setDB(false);

        // insert photo button entering/enabling
        offsetInsertPhotoButton.value = 0;
        visibilityInsertPhotoButton.value = 1;
        setIPB(false);

        // change exit animation opacity change duration (faster on exit)
        setTimeout(() => {
            buttonGroupVisibilityDuration.value = 100;
        }, 400);

        // change transform delay to zero (should be the same for all buttons on exit)
        setTimeout(() => {
            discardButtonDelay.value = 0;
        }, 500);
    }
    const OnSubmit = () => {

        // prep finish recording button for entrance
        offsetFinishButton.value = -43;
        visibilityDurationFinishButton.value = 400;

        // submit button exiting/disabling
        offsetSubmitButton.value = 0;
        visibilitySubmitButton.value = 0;
        setSB(true);

        // discard button exiting/disabling
        offsetDiscardButton.value = 0;
        visibilityDiscardButton.value = 0;
        setDB(true);

        // insert photo button exiting/disabling
        offsetInsertPhotoButton.value = 30;
        visibilityInsertPhotoButton.value = 0;
        setIPB(true);

        offsetSubmittedAlert.value = 0;
        visibilitySubmittedAlert.value = 1;

        // change exit animation opacity change duration for record button (faster on exit)
        setTimeout(() => {
            visibilityDurationSubmittedAlert.value = 100;
        }, 400);

        // show submission feedback then exit
        setTimeout(() => {

            // start entrance animation for start recording button and enable
            offsetRecordButton.value = 0;
            visibilityRecordButton.value = 1;

            // start exit animation for 'submitted!' alert
            offsetSubmittedAlert.value = 43;
            visibilitySubmittedAlert.value = 0;
            setRB(false);
            LoadNewText();
            setTimeout(() => {
                visibilityDurationRecordButton.value = 100;
            }, 400);
        }, 1500)
    }
    const RecordButtonAnimationStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: (withSpring(offsetRecordButton.value, {
                        damping: 120,
                        stiffness: 510,
                    })),
                },
            ],
            opacity: [(withTiming(visibilityRecordButton.value, {
                duration: visibilityDurationRecordButton.value
            }))]
        };
    });
    const SubmittedAlertAnimationStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: (withSpring(offsetSubmittedAlert.value, {
                        damping: 120,
                        stiffness: 510,
                    })),
                },
            ],
            opacity: [(withTiming(visibilitySubmittedAlert.value, {
                duration: visibilityDurationSubmittedAlert.value
            }))]
        };
    });
    const FinishButtonAnimationStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: (withSpring(offsetFinishButton.value, {
                        damping: 120,
                        stiffness: 510,
                    })),
                },
            ],
            opacity: [(withTiming(visibilityFinishButton.value, {
                duration: visibilityDurationFinishButton.value
            }))]
        };
    });
    const SubmitButtonAnimationStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: (withSpring(offsetSubmitButton.value, {
                        damping: 120,
                        stiffness: 510,
                    })),
                },
            ],
            opacity: [(withTiming(visibilitySubmitButton.value, {
                duration: buttonGroupVisibilityDuration.value
            }))]
        };
    });
    const DiscardButtonAnimationStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    //previewButtonDelay.value is 100 and will change to 0 for it's exit
                    translateY: withDelay(discardButtonDelay.value, (withSpring(offsetDiscardButton.value, {
                        damping: 120,
                        stiffness: 510,
                    }))),
                },
            ],
            opacity: [withDelay(discardButtonDelay.value, (withTiming(visibilityDiscardButton.value, {
                duration: buttonGroupVisibilityDuration.value
            })))]
        };
    });
    const InsertPhotoAnimationStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: (withSpring(offsetInsertPhotoButton.value, {
                        damping: 120,
                        stiffness: 510,
                    })),
                },
            ],
            opacity: [(withTiming(visibilityInsertPhotoButton.value, {
                duration: buttonGroupVisibilityDuration.value
            }))]
        };
    });

    // reloads container so it will re-render the animation
    const reset = () => {
        setSeed(Math.random());
    }

    const LoadNewText = () => {
        if (count + 1 === Questions.length) {
            setCount(0);
            setText(Questions[count].question.split(' '));
            reset();
        } else {
            setCount(count + 1);
            setText(Questions[count].question.split(' '));
            reset();
        }
    }
    const LoadPreviousText = () => {
        if (count - 1 === -1) {
            setCount(Questions.length - 1);
            setText(Questions[count].question.split(' '));
            reset();
        } else {
            setCount(count - 1);
            setText(Questions[count].question.split(' '));
            reset();
        }
    }

    useEffect(() => {
        setText(Questions[count].question.split(' '));
    }, [Questions, count])

    return (
        <Container nativeID='container'>
            <Header width={width} height={height}/>
            <LowerBody nativeID='lowerBody' style={{ width: "100%", flexGrow: "1", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
                <MainBody
                    nativeID='mainBody'
                    style={[
                        (width < 1025 && height > 500) && {
                            width: '100%'
                        },
                        height < 415 && {
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                        },
                        // for larger iPad landscape dimensions
                        (width < 1181 && width > 500 && height < 821 && height > 768) && {
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '90vh'
                        },
                        // for iPad and iPad Mini landscape dimensions (w = 1024, h = 768)
                        (width < 1025 && height < 769 && width > 500) && {
                            width: '100%',
                            minHeight: '90vh',
                            alignItems: 'center'
                        }
                    ]}>
                    <InterviewSection
                        nativeID='interviewSection'
                        style={[
                            (width < 1025 && height > 500) && {
                                flexDirection: 'column-reverse',
                                marginTop: 0,
                                height: 'unset',
                                paddingLeft: 0,
                                paddingRight: 0
                            },
                            height < 415 && {
                                marginTop: 20,
                                height: 'unset',
                                width: '94%'
                            },
                            (width < 1181 && width > 500 && height < 821 && height > 768) && {
                                marginTop: 20,
                                height: 'unset',
                                width: '94%'
                            },
                            (width < 1025 && height < 769 && width > 500) && {
                                width: '94%',
                                marginTop: 20,
                                height: 'unset',
                                flexDirection: 'row'
                            }
                        ]}>
                        {/* LEFT COLUMN CONTAINING QUESTION */}
                        <Column
                            nativeID='firstColumn'
                            style={[
                                (width < 1025 && height > 500) && {
                                    height: 'unset',
                                    width: '100%',
                                    alignItems: 'center',
                                    paddingTop: 70
                                },
                                width < 500 && {
                                    paddingTop: 30
                                },
                                (width < 1025 && height < 769 && width > 500) && {
                                    width: '45%'
                                }]}>
                            <View
                                style={[{
                                    minHeight: "200px"
                                },
                                (width < 1025 && height > 500) && {
                                    width: `${width - 220}px`
                                },
                                width < 500 && {
                                    width: `${width - 40}px`
                                },
                                height < 415 && {
                                    minHeight: 150
                                },
                                (width < 1181 && height < 821 && height > 768) && {
                                    minHeight: 180
                                },
                                (width < 1025 && height < 769 && width > 500) && {
                                    width: '100%'
                                }]}>
                                <SSText color="#9D927B" fontSize={height < 415 ? '16px' : width < 500 ? '17px' : '20px'}>Question {count + 1}</SSText>
                                <View style={[{ paddingBottom: "15px" }, width < 500 && { paddingBottom: '10px' }, height < 415 && { paddingBottom: 10 }]} />
                                <TextWrapper key={seed}>
                                    {textArray.map((word, index) => {
                                        return (
                                            <WordAnimator key={`${word}-${index}`} index={index} delay={index * 50} word={word} width={width} height={height} />
                                        )
                                    })}
                                </TextWrapper>
                            </View>
                        </Column>
                        {/* RIGHT COLUMN CONTAINING VIDEO AND BUTTONS*/}
                        <Column
                            nativeID='secondColumn'
                            style={[{
                                alignItems: 'flex-end'
                            },
                            (width < 1025 && height > 500) && {
                                height: 'unset',
                                paddingTop: `70px`,
                                paddingBottom: '50px',
                                backgroundColor: '#2A2016',
                                width: '100%',
                                alignItems: 'center'
                            },
                            width < 500 && {
                                paddingTop: `15px`,
                                paddingBottom: '13.5px'
                            },
                            height < 415 && {
                                height: 'unset'
                            },
                            (width < 1025 && height < 769 && width > 500) && {
                                width: '45%',
                                background: 'transparent'
                            }]}>
                            <ImageContainer
                            nativeID='imageContainer'
                            >
                                {recording &&
                                    <RedDot />
                                }
                                {ticking &&
                                    <GifComponent source={gifSource} width={width} height={height} />
                                }
                                <Animated.View nativeID="IPBAnimatedView" style={[InsertPhotoAnimationStyles, {position: 'absolute', bottom: 15, right: 15, zIndex: 999}]}>
                                    <InsertPhotoPressable nativeID='IPB' disabled={insertPhotoButtonState}>
                                        <Image
                                            nativeID='IPBImage'
                                            source={require('../../assets/add-photo.png')}
                                            style={{
                                                height: 30,
                                                width: 30
                                            }}
                                        />
                                    </InsertPhotoPressable>
                                </Animated.View>

                                <View style={[{
                                    borderRadius: "8px",
                                    shadowColor: '#B59668',
                                    shadowOffset: { width: 0, height: 0 },
                                    shadowOpacity: .3,
                                    shadowRadius: 45
                                },
                                (width < 1025 && height > 500) && {
                                    shadowColor: 'transparent'
                                }
                                ]}>
                                    <Image
                                        // resizeMode="contain"
                                        source={require('../../assets/speakingToCam.jpg')}
                                        style={[{
                                            height: '400px',
                                            width: '600px',
                                            borderRadius: "8px",
                                            overflow: "hidden",
                                            shadowColor: '#B59668',
                                            shadowOffset: { width: 0, height: 10 },
                                            shadowOpacity: 1,
                                            shadowRadius: 20
                                        },
                                        (width < 1025 && height > 500) && {
                                            width: `${width - 200}px`,
                                            height: `${(400 * (width - 200)) / 600}px`,
                                            shadowColor: 'transparent'
                                        },
                                        width < 500 && {
                                            width: `${width - 30}px`,
                                            height: `${(400 * (width - 30)) / 600}px`,
                                            shadowColor: 'transparent'
                                        },
                                        height < 415 && {
                                            width: `${(width / 2) - 30}px`,
                                            height: `${(400 * ((width / 2) - 30)) / 600}px`
                                        },
                                        (height < 415 && width < 897 && width > 667) && {
                                            width: `${(width / 2) - 80}px`,
                                            height: `${(400 * ((width / 2) - 80)) / 600}px`
                                        },
                                        (height < 415 && width < 787 && width > 667) && {
                                            width: `${(width / 2) - 70}px`,
                                            height: `${(400 * ((width / 2) - 70)) / 600}px`
                                        },
                                        (width < 1181 && width > 500 && height < 821 && height > 768) && {
                                            width: `${(width / 2) - 80}px`,
                                            height: `${(400 * ((width / 2) - 80)) / 600}px`
                                        },
                                        (width < 1025 && height < 769 && width > 500) && {
                                            width: `${(width / 2) - 80}px`,
                                            height: `${(400 * ((width / 2) - 80)) / 600}px`
                                        }
                                        ]} />
                                </View>
                            </ImageContainer>
                            <ButtonArea style={[
                                (width < 1025 && height > 500) && {
                                    width: `${width - 200}px`,
                                },
                                width < 500 && {
                                    width: `${width - 30}px`,
                                },
                                height < 415 && {
                                    width: `${(width / 2) - 30}px`,
                                    paddingTop: 8
                                },
                                // styling for wide mobile landscape viewports (e.g. iPhone XR)
                                (height < 415 && width < 897 && width > 667) && {
                                    width: `${(width / 2) - 80}px`
                                },
                                (height < 415 && width < 787 && width > 667) && {
                                    width: `${(width / 2) - 70}px`,
                                },
                                (width < 1181 && width > 500 && height < 821 && height > 768) && {
                                    width: `${(width / 2) - 80}px`,
                                },
                                (width < 1025 && height < 769 && width > 500) && {
                                    width: `${(width / 2) - 80}px`,
                                }]}>
                                <View style={{ flexDirection: "column" }}>
                                    <Animated.View style={[RecordButtonAnimationStyles, { position: "absolute", zIndex: "10", }]}>
                                        <Pressable onPress={() => OnRecordStart()} disabled={recordButtonState}>
                                            <StartRecordingButton width={width} height={height} />
                                        </Pressable>
                                    </Animated.View>
                                    <Animated.View style={[FinishButtonAnimationStyles, { position: "absolute", zIndex: "9" }]}>
                                        <Pressable onPress={() => OnRecordFinish()} disabled={finishButtonState}>
                                            <FinishRecordingButton width={width} height={height} />
                                        </Pressable>
                                    </Animated.View>
                                    <Animated.View style={[SubmittedAlertAnimationStyles, { position: "absolute", zIndex: "3" }]}>
                                        <SubmittedAlert width={width} height={height} />
                                    </Animated.View>
                                </View>
                                <View style={{ flexDirection: "row", zIndex: "8", transform: "translateY(43px)" }}>
                                    <Animated.View style={[SubmitButtonAnimationStyles]}>
                                        <Pressable onPress={() => OnSubmit()} disabled={submitButtonState}>
                                            <SubmitRecordingButton width={width} height={height} />
                                        </Pressable>
                                    </Animated.View>
                                    <Animated.View style={[DiscardButtonAnimationStyles]}>
                                        <Pressable disabled={discardButtonState}>
                                            <DiscardButton width={width} height={height} />
                                        </Pressable>
                                    </Animated.View>
                                </View>
                            </ButtonArea>
                        </Column>
                    </InterviewSection>
                    <NavigationSection
                        nativeID='navigationSection'
                        style={[(
                            width < 1025 && height > 500) && {
                            paddingLeft: 96,
                            paddingRight: 100
                        },
                        width < 769 && {
                            paddingBottom: 50
                        },
                        width < 500 && {
                            paddingLeft: 7,
                            paddingRight: 7
                        },
                        height < 415 && {
                            justifyContent: 'space-between',
                            width: '97%'
                        },
                        (height < 415 && width < 897) && {
                            width: '96%',
                            paddingBottom: 20
                        },
                        (height < 415 && width < 897 && width > 667) && {
                            paddingTop: 10
                        },
                        (width < 1181 && width > 500 && height < 821 && height > 768) && {
                            justifyContent: 'space-between',
                            width: '94%',
                            paddingTop: 65
                        },
                        (width < 1025 && height < 769 && width > 500) && {
                            justifyContent: 'space-between',
                            width: '96%',
                            paddingTop: 65,
                            paddingLeft: 0,
                            paddingRight: 0
                        }]}>
                        <View style={[{ flex: "1", justifyContent: "left", width: "50%" }]}>
                            <Pressable
                                onPress={() => LoadPreviousText()}>
                                <NavButtonLeft width={width} height={height} />
                            </Pressable>
                        </View>
                        <View style={{ flex: "1", alignItems: "flex-end", width: "50%" }}>
                            <Pressable
                                onPress={() => LoadNewText()}>
                                <NavButtonRight width={width} height={height} />
                            </Pressable>
                        </View>
                    </NavigationSection>
                </MainBody>
                <Footer width={width} height={height}/>
            </LowerBody>
        </Container>
    )
};

export default TryItScreen;
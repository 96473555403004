import { db } from "../../firebaseConfig";
import { collection, query, where, onSnapshot, addDoc } from "firebase/firestore";

export const goCheckout = async (user, stripePriceID, gifteeEmail, gifteeFirstName, gifteeLastName) => {

  // ES9 Method
  try {
    console.log('tryingDoc');
    console.log(gifteeEmail);
    const docRef = await addDoc(
      collection(db, "users", user.uid, "checkout_sessions"),
      {
        mode: "payment",
        price: stripePriceID, // One-time price created in Stripe
        // success_url: "http://localhost:19006/dashboard",
        // cancel_url: "http://localhost:19006/dashboard",
        success_url: "https://app.storycorder.com/dashboard?from-stripe",
        cancel_url: "https://app.storycorder.com/get-started",
        metadata: {
          otherEmail: gifteeEmail ?? "none",
          gifteeFirstName:gifteeFirstName ?? "none",
          gifteeLastName: gifteeLastName ?? "none"
        },
      }
    );

    // Wait for the CheckoutSession to get attached by the extension
    const unsubscribe = onSnapshot(docRef, (snap) => {
      console.log("In Snapshot");
      const { error, url } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
        unsubscribe();
      }
      if (url) {
        // We have a Stripe Checkout URL, let's redirect.
        unsubscribe();
        window.location.assign(url);

      }
    });
  } catch (e) {
    console.log(e);
    unsubscribe();
  }

};

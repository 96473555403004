import { StyleSheet } from 'react-native';

export const standardStyles = StyleSheet.create({

});
export const underW500 = StyleSheet.create({
    UIContainer: {
        height: 'unset'
    },
    wrapper: {
        width: '90%'
    },
    signUpCore: {
        flexDirection: 'column-reverse',
        width: '100%',
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 25,
        paddingBottom: 25,
        minHeight: 'unset',
        marginTop: '10%'
    },

    firstColumn: {
        width: '100%',
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        borderRightWidth: 0,
        flexBasis: 'unset'
    },
    line: {
        width: '100%'
    },
    secondColumn: {
        flexBasis: 'unset',
        paddingTop: 20,
        paddingRight: 0,
        paddingBottom: 20,
        paddingLeft: 0
    },
    image: {
        left: '-10px',
        width: 200,
        height: 200,
    },
    buttonBox: {
        height: 'unset',
        marginTop: 30
    }
});
export const underW376underH668 = StyleSheet.create({
    UIContainer: {
        height: 'unset'
    },
    wrapper: {
        width: '90%',
        height: 'unset',
        marginBottom: '5%'
    },
    signUpCore: {
        flexDirection: 'column-reverse',
        width: '100%',
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 25,
        paddingBottom: 25,
        minHeight: 'unset',
        marginTop: '5%'
    },

    firstColumn: {
        width: '100%',
        paddingTop: 0,
        paddingRight: 0,
        paddingLeft: 0,
        paddingBottom: 0,
        borderRightWidth: 0,
        flexBasis: 'unset'
    },
    line: {
        width: '100%'
    },
    secondColumn: {
        flexBasis: 'unset',
        paddingTop: 20,
        paddingRight: 0,
        paddingBottom: 20,
        paddingLeft: 0
    },
    image: {
        left: '-10px',
        width: 200,
        height: 200,
    },
    buttonBox: {
        height: 'unset',
        marginTop: 30
    }
});
export const underW769 = StyleSheet.create({
    wrapper: {
        width: '80%'
    },
    signUpCore: {
        width: '100%',
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 25,
        paddingBottom: 25,
        minHeight: 'unset',
    },
    line: {
        width: '100%'
    },
    image: {
        width: 175,
        height: 175
    },
    firstColumn: {
        paddingTop: 20,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 20,
    }
});
export const underW821 = StyleSheet.create({
    wrapper: {
        width: '80%'
    },
    signUpCore: {
        width: '100%',
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 25,
        paddingBottom: 25,
        minHeight: 'unset',
    },
    line: {
        width: '100%'
    },
    image: {
        width: 175,
        height: 175
    },
    firstColumn: {
        paddingTop: 20,
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 0,
    }
});
export const underW1025 = StyleSheet.create({
    wrapper: {
        width: '70%'
    },
    signUpCore: {
        width: '100%',
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 25,
        paddingBottom: 25,
        minHeight: 'unset',
    },
    line: {
        width: '100%'
    },
    image: {
        width: 200,
        height: 200
    }
});

export const overW1300 = StyleSheet.create({
    signUpCore: {
        backgroundColor: 'yellow',
    },
    firstColumn: {
        color: 'green'
    },
    secondColumn: {
        color: 'green'
    },
    buttonBox: {
        background: 'azure'
    }
});

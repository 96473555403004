import { StyleSheet } from 'react-native';

export const standardStyles = StyleSheet.create({

});
export const underW500 = StyleSheet.create({
    
});
export const underW376underH668 = StyleSheet.create({

});
export const underW769 = StyleSheet.create({
    mainBody: {
        width: '88%'
    },
    wrapper: {
        width: '80%'
    },
    signUpCore: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 25,
        paddingBottom: 25,
        minHeight: 'unset',
        height: 'unset'
    },
    line: {
        width: '100%'
    },
    image: {
        width: 200,
        height: 275
    },
    firstColumn: {
        paddingTop: 0,
        paddingRight: 30,
        paddingLeft: 20,
        paddingBottom: 0,
    },
    titleWrapper: {
        width: '100%',
        paddingLeft: 5
    }
});
export const underW821 = StyleSheet.create({
    mainBody: {
        width: '88%'
    },
    wrapper: {
        width: '80%'
    },
    signUpCore: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 55,
        paddingBottom: 55,
        minHeight: 'unset',
        height: 'unset'
    },
    line: {
        width: '100%'
    },
    image: {
        height: 350,
        width: '100%'
    },
    firstColumn: {
        paddingTop: 0,
        paddingRight: 50,
        paddingLeft: 40,
        paddingBottom: 0,
        flexBasis: '50%'
    },
    secondColumn: {
        flexBasis: '50%',
        paddingLeft: 55,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 30
    },
    titleWrapper: {
        width: '100%',
        paddingLeft: 5
    },
    input: {
        height: 42
    },
    wrapperOfInputs: {
        paddingTop: 20
    },
    labelWrap: {
        paddingTop: 15
    },
    buttonBox: {
        paddingTop: 45
    }
});

export const underW1025 = StyleSheet.create({
    mainBody: {
        width: '88%'
    },
    wrapper: {
        width: '80%'
    },
    signUpCore: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 55,
        paddingBottom: 55,
        minHeight: 'unset',
        height: 'unset'
    },
    line: {
        width: '100%'
    },
    image: {
        height: 350,
        width: '100%'
    },
    firstColumn: {
        paddingTop: 0,
        paddingRight: 50,
        paddingLeft: 40,
        paddingBottom: 0,
    },
    secondColumn: {
        flexBasis: '35%',
        paddingLeft: 55,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 30
    },
    titleWrapper: {
        width: '100%',
        paddingLeft: 5
    },
    input: {
        height: 42
    },
    wrapperOfInputs: {
        paddingTop: 20
    },
    labelWrap: {
        paddingTop: 15
    },
    buttonBox: {
        paddingTop: 45
    }
});
// IPAD AIR LANDSCAPE
export const underW1181 = StyleSheet.create({
    mainBody: {
        width: '88%'
    },
    wrapper: {
        width: '80%'
    },
    signUpCore: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 55,
        paddingBottom: 55,
        minHeight: 'unset',
        height: 'unset'
    },
    line: {
        width: '100%'
    },
    image: {
        height: 375,
        width: '100%'
    },
    firstColumn: {
        paddingTop: 0,
        paddingRight: 50,
        paddingLeft: 40,
        paddingBottom: 0,
    },
    secondColumn: {
        flexBasis: '35%',
        paddingLeft: 55,
        paddingTop: 0,
        paddingBottom: 0
    },
    titleWrapper: {
        width: '100%',
        paddingLeft: 5
    },
    input: {
        height: 42
    },
    wrapperOfInputs: {
        paddingTop: 20
    },
    labelWrap: {
        paddingTop: 15
    },
    buttonBox: {
        paddingTop: 45
    }
});
export const underW1367 = StyleSheet.create({
    mainBody: {
        width: '78%'
    },
    wrapper: {
        width: '80%'
    },
    signUpCore: {
        paddingLeft: 25,
        paddingRight: 25,
        paddingTop: 55,
        paddingBottom: 55,
        minHeight: 'unset',
        height: 'unset'
    },
    line: {
        width: '100%'
    },
    image: {
        height: 400,
        width: '100%'
    },
    firstColumn: {
        paddingTop: 0,
        paddingRight: 50,
        paddingLeft: 40,
        paddingBottom: 0,
    },
    secondColumn: {
        flexBasis: '35%',
        paddingLeft: 50,
        paddingTop: 0,
        paddingBottom: 0,
        paddingRight: 40
    },
    titleWrapper: {
        width: '100%',
        paddingLeft: 5
    },
    input: {
        height: 42
    },
    wrapperOfInputs: {
        paddingTop: 20
    },
    labelWrap: {
        paddingTop: 15
    },
    buttonBox: {
        paddingTop: 45
    }
});


// const Container = styled.View`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 50px;
// `;

// const WatchInterviewScreen = () => {
 
//   if (doneUrl == "") {
//     return (
//       <AbsoluteFill>
//         <button onClick={render}>Render Shotstack</button>
//       </AbsoluteFill>
//     );
//   } else {
//     return (
//       <AbsoluteFill>
//         <IvyPresto>Watch Your Video Here</IvyPresto>
//         <button onClick={render}>Render Shotstack</button>
//         <button onClick={download}>Download Video</button>
        
//         <VideoPlayer />
//       </AbsoluteFill>
//     );
//   }

  // while(doneUrl != ''){
  //   return (
  //     <AbsoluteFill>
  //       <IvyPresto>
  //         Watch Your Video Here
  //       </IvyPresto>
  //       <button onClick={render}>Render Shotstack</button>
  //       <button onClick={download}>Download Video</button>
  //       <video width="640" height="480" controls>
  //         <source src={doneUrl} type="video/mp4"></source>
  //       </video>
  //       <VideoPlayer />
  //     </AbsoluteFill>
  //   )
  // }
// };

// export default WatchInterviewScreen;
import { View, Image, useWindowDimensions } from 'react-native'
import React, { useRef, useEffect } from 'react'
import styled from '@emotion/native'
import { httpsCallable } from '@firebase/functions';
import { storage, db, functions, auth } from '../firebaseConfig';
import { ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage'
import { UserAuth } from '../context/AuthContext';
import { Footer, Header, SSTextBold } from '../constants/GlobalElements'
import { useHover } from 'react-native-web-hooks';
import { VideoPlayer } from '../components/VideoPlayer';
import { AbsoluteFill, Video } from 'remotion';
import Loader from "../components/Loader";
import RenderInterview from '../components/RenderInterview';
import { getDoc, doc } from 'firebase/firestore';

const textColor = "#49381F"
const Container = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #F4ECD9;
    min-height: 100vh;
`
const UIContainer = styled.View`
    width: 100%;
    align-items: center;
    height: calc(100vh - 190px)
`
const VideoContainer = styled.View`
  background: #F7F4E9;
  width: max-content;
  min-height: 400px;
  border-radius: 28px;
  padding: 30px;
  border: 1px solid rgba(226, 222, 204, .7);
  max-width: 90vw;
  box-shadow: 0 5px 30px rgba(181, 150, 104, .15);
  align-items: end;
`
const ButtonTemplate = styled.View`
    display: flex;
    border-radius: 999px;
    padding: 5px;
    width: max-content;
    height: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all .2s ease;
    cursor: pointer;
`
const DownloadButton = ({ doneUrl, videoId }) => {
  // try {
  //   const videoUrl = doneUrl;
  //   const videoRequest = new Request(videoUrl);
  //   fetch(videoRequest)
  //     .then(() => {
  //       const link = document.createElement('a');
  //       link.href = videoUrl;
  //       link.setAttribute('download', videoId);
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     });
  // } catch (error) {
  //   console.error(error);
  // }
  // delete link;
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <a href={doneUrl} target='_blank' style={{ textDecoration: 'none' }}>
    <ButtonTemplate ref={ref} style={[{ background: "#E8E2D5", marginTop: 20 }, isHovered && { background: "#E2DDD0", transform: "translateY(-1.5px)" }]} onPress={() => (window.open(doneUrl))}>
      <View style={{ paddingRight: "10px", paddingLeft: "20px" }}>
        <SSTextBold color={isHovered ? "#3A2A1D" : "#5A4736"}>Download Video</SSTextBold>
      </View>
      <View
        style={[{ borderRadius: "999px", height: "33px", width: "33px", display: "flex", alignItems: "center", justifyContent: "center", background: "#F2EADA", transitionProperty: "background", transitionDuration: ".2s", transitionTimingFunction: "ease" },
        isHovered && { background: "#EDE5D5" }]}>
        <Image
          resizeMode="contain"
          source={require('../../assets/downloads.png')}
          style={{ height: '16px', width: '16px' }} />
      </View>
    </ButtonTemplate>
    </a>
  )
}
export default function WatchInterviewScreen({ route, navigation }) {
  const { width, height} = useWindowDimensions()
  const user = route.params.user
  const uid = route.params.user
  console.log(route.params);
  const videoId = route.params.videoId;
  const storageRef = ref(storage, "videos/" + uid + "/" + videoId + "/");
  const dbRef = doc(db, "users/" + uid + "/videos/" + videoId);
  // const doneUrl = route.params.doneUrl;

  const videoWidth = width > 640 ? 640 : width * 0.7; // 40px for some padding
  const videoHeight = (videoWidth * 9) / 16;

  const [videoUrl, setVideoUrl] = React.useState([]);
  const [metaData, setMetaData] = React.useState([]);
  const [doneUrl, setDoneUrl] = React.useState("");
  useEffect(() => {
    listAll(storageRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setVideoUrl((prev) => [...prev, url]);
        });
        getMetadata(item).then((metadata) => {
          setMetaData((prev) => [...prev, metadata]);
        });
      });
    });

    getDoc(dbRef).then((doc) => {
      if (doc.exists()) {
        console.log("Document data:", doc.data());
        setDoneUrl(doc.data().completed);
      } else {
        console.log("No such document!");
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });

    // const shotstackDownload = httpsCallable(
    //   functions,
    //   "shotStackFunctions-download"
    // );
    // shotstackDownload({
    //   urls: videoUrlArray,
    //   duration: metaDataArray,
    //   user: uid,
    //   videoId: videoId,
    // })
    //   .then((result) => {
    //     setDoneUrl(result.data.data[0].attributes.url);
    //     console.log(result.data.data[0].attributes.url);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // const shotstackDownload = httpsCallable(
    //   functions,
    //   "shotStackFunctions-download"
    // );
    // shotstackDownload({
    //   urls: videoUrlArray,
    //   duration: metaDataArray,
    //   user: uid,
    //   videoId: videoId,
    // })
    //   .then((result) => {
    //     setDoneUrl(result.data.data[0].attributes.url);
    //     console.log(result.data.data[0].attributes.url);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }, []);
  const videoUrlArray = videoUrl.map((url) => {
    return url;
  });
  const metaDataArray = metaData.map((metadata) => {
    return parseFloat(metadata.customMetadata.durationInSeconds);
  });
  // const render = () => {
  //   const shotstack = httpsCallable(functions, "shotStackFunctions-shotstack");
  //   shotstack({
  //     urls: videoUrlArray,
  //     duration: metaDataArray,
  //     user: uid,
  //   })
  //     .then((result) => {
  //       console.log(result.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  console.log(doneUrl);

  if (doneUrl == "") {
    return (
      <Container>
        <UIContainer>
          <Header width={width} height={height}/>
          <View style={{ minWidth: "55%", justifyContent: "center", height: "100%", alignItems: "center" }}>
            <Loader />
          </View>
        </UIContainer>
        <Footer width={width} height={height}/>
      </Container>    
    );
  } else {
    return (
      <Container>
      <UIContainer>
        <Header width={width} height={height}/>
        <View style={{ minWidth: "55%", justifyContent: "center", height: "100%", alignItems: "center" }}>
          <VideoContainer>
          <video style={{ borderRadius: "8px", width: videoWidth, height: videoHeight }} controls>
            <source src={doneUrl} type="video/mp4"></source>
          </video>
        {/* <VideoPlayer /> */}
            <DownloadButton doneUrl={doneUrl} videoId={route.params.videoId}/>
          </VideoContainer>
        </View>
      </UIContainer>
      <Footer width={width} height={height}/>
    </Container>
    );
  }

  // return (
  //   <Container>
  //     <UIContainer>
  //       <Header />
  //       <View style={{ minWidth: "55%", justifyContent: "center", height: "100%", alignItems: "center" }}>
  //         <VideoContainer>
  //         <video style={{borderRadius: "8px"}} width="640" height="480" controls>
  //         <source src={doneUrl} type="video/mp4"></source>
  //       </video>
  //           <DownloadButton />
  //         </VideoContainer>
  //       </View>
  //     </UIContainer>
  //     <Footer />
  //   </Container>
  // )
}

import { StyleSheet } from 'react-native';

export const standardStyles = StyleSheet.create({

});
export const underW500 = StyleSheet.create({
    
});
export const underW376underH668 = StyleSheet.create({

});
export const underW769 = StyleSheet.create({
    
});
export const underW821 = StyleSheet.create({
    
});

export const underW1025 = StyleSheet.create({
    progressAlertCard: {
        width: '100%',
        gap: 30,
        paddingLeft: 23,
        paddingRight: 17,
        paddingTop: 23

    }
});

export const underW1400 = StyleSheet.create({
    progressAlertCard: {
       
        paddingLeft: 23,
        paddingTop: 23
    }
});
import React, { useRef, useState, useCallback, useEffect } from 'react'
import { View, Image, Pressable, TouchableOpacity } from 'react-native'
import styled from '@emotion/native'
import { SSText, SSTextMed } from '../constants/GlobalElements'
import { useHover } from 'react-native-web-hooks'
import { useNavigation } from '@react-navigation/native'
import Animated, {
    withSpring,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
    withDelay,
    Keyframe
} from 'react-native-reanimated';
import { auth } from '../firebaseConfig'
import { signOut } from 'firebase/auth'

// Updated

const LoggedInUserSection = styled.Pressable`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    transition: all .25s ease;
    z-index: 777
`
const LoggedInUserBadge = styled.View`
    display: flex;
    border-radius: 999px;
    border: 5px solid #f4ebd0;
    z-index: 777
`
const Dropdown = styled.View`
    // top: 60px;
    // right: 0px;
    background: #f7f4ea;
    border-radius: 14px;
    padding: 5px;
    align-items: center;
    height: max-content;
    width: 200px;
    // position: absolute;
    // z-index: 777;
`
const Hoverable = styled.Pressable`
    transition: all .2s ease;
    padding: 10px;
    padding-left: 14px;
    width: 100%;
    // height: 85px;
    background: transparent;
    z-index: 777
`
const Line = styled.View`
    border-top-color: #efece1;
    border-top-width: 1px;
    width: 95%;
`
const NavHoverable = ({ text, topBR, bottomBR, action, height, width }) => {

    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <>
            <Hoverable
                ref={ref}
                style={[{
                    borderTopLeftRadius: topBR,
                    borderTopRightRadius: topBR,
                    borderBottomLeftRadius: bottomBR,
                    borderBottomRightRadius: bottomBR
                }, isHovered && {
                    background: "#efece1"
                }]}
                onPress={action}>
                <SSTextMed fontSize={height < 415 ? '12px' : width < 500 ? '13px' : "14px"} color="#1b1306">{text}</SSTextMed>
            </Hoverable>
        </>
    )
}

const UserBadge = ({ width, height }) => {
    const navigation = useNavigation();
    const dropdownOpacity = useSharedValue(0)
    const dropdownOffset = useSharedValue(-10)
    const delay = 150;

    const [open, setOpen] = useState(false)
    const ref = useRef()

    const openDropdown = () => {
        setOpen(true)
        dropdownOpacity.value = 1
        dropdownOffset.value = 0

    }

    const closeOpenMenu = useCallback(
        (e) => {
            if (
                ref.current &&
                open &&
                !ref.current.contains(e.target)
            ) {

                dropdownOpacity.value = 0
                dropdownOffset.value = -10
                setTimeout(() => {
                    setOpen(false);
                }, delay)
            }
        },
        [open]
    );

    useEffect(() => {
        document.addEventListener("mousedown", closeOpenMenu);
    }, [closeOpenMenu]);

    const dropdownAnimationStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: (withSpring(dropdownOffset.value, {
                        damping: 120,
                        stiffness: 510,
                    })),
                },
            ],
            opacity: [(withTiming(dropdownOpacity.value, {
                duration: delay
            }))]
        };
    });

    return (
        <LoggedInUserSection
            ref={ref}
            onPress={() => openDropdown()}>
            <LoggedInUserBadge
                style={[
                    width < 500 && {
                        borderWidth: 4
                    },
                    height < 415 && {
                        borderWidth: 4
                    }]}>
                <Image
                    resizeMode="contain"
                    source={require('../../assets/usericon.png')}
                    style={[{
                        height: 45,
                        width: 45,
                        borderRadius: 999
                    },
                    width < 500 && {
                        height: 35,
                        width: 35
                    },
                    height < 415 && {
                        height: 30,
                        width: 30
                    }]} />
            </LoggedInUserBadge>
            <View>
                <Image
                    resizeMode="contain"
                    source={require('../../assets/downtriangle.png')}
                    style={[{
                        height: 11,
                        width: 11
                    },
                    height < 415 && {
                        height: 10,
                        width: 10
                    }]} />
            </View>

            <Animated.View
                style={[
                    dropdownAnimationStyles, {
                        display: open ? "inline" : "none",
                        top: height < 415 ? 40 : width < 500 ? 50 : 60,
                        position: "absolute",
                        right: width < 500 ? -5 : 0
                    }]} >
                <Dropdown

                    style={{
                        shadowColor: '#B59668',
                        shadowOffset: { width: 0, height: 5 },
                        shadowOpacity: 0.2,
                        shadowRadius: 17,
                    }}
                >
                    <NavHoverable topBR="9px" bottomBR="3px" text="Dashboard" width={width} height={height} action={() => navigation.navigate("UserDashboard")} />
                    <Line />
                    <NavHoverable bottomBR="9px" topBR="3px" text="Sign Out" width={width} height={height} action={() => signOut(auth).then(() => {
                        navigation.navigate("GetStarted")
                    })} />
                </Dropdown>
            </Animated.View>

        </LoggedInUserSection >
    )
}

export default UserBadge
import { Player } from "@remotion/player";
import RenderInterview from "./RenderInterview";
import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { useEffect } from 'react';
import styled, { css } from '@emotion/native'
import { storage, auth } from '../firebaseConfig';
import { ref, listAll, getMetadata, getDownloadURL } from 'firebase/storage'
import { Composition } from "remotion";
import { UserAuth } from '../context/AuthContext';

export const VideoPlayer = () => {
  const { user } = UserAuth();
  const storageRef = ref(storage, 'videos/' + user.uid + '/first-dentist-appointment/');
  const imageRef = ref(storage, 'images/' + user.uid + '/first-dentist-appointment/');
  const listRef = listAll(storageRef);
  let totals = 0;
  let pinged = false;

  const [metaData, setMetaData] = React.useState([]);
  const [images, setImages] = React.useState([]);

  useEffect(() => {
    listAll(storageRef).then((response) => {
      response.items.forEach((item) => {
        getMetadata(item).then((metadata) => {
          pinged = true;
          setMetaData((prev) => [...prev, metadata]);
        })
      });
    });
    listAll(imageRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImages((prev) => [...prev, url]);
        });
      });
    });
  }, []);
  
  metaData.map((metadata) => {
    totals += parseInt(metadata.customMetadata.durationInSeconds * 30);
  });

  images.map((image) => {
    pinged = true;
    totals += 150;
  });

  while (totals !== 0 && pinged === true) {
    console.log(totals);
    console.log(pinged);
    return (
      <>
        <Player
          durationInFrames={totals}
          fps={30}
          compositionHeight={320}
          compositionWidth={640}
          controls={true}
          component={RenderInterview}
        />
      </>
    );
  }
};
import styled from '@emotion/native'
import { useRef, useState, useEffect } from 'react'
import { View, Image, Pressable } from 'react-native'
import { IvyPresto, SSTextItalic, SSTextMed, SSText } from '../../constants/GlobalElements'
import { useHover } from 'react-native-web-hooks';
import { useNavigation } from '@react-navigation/native';
import { UserAuth } from '../../context/AuthContext';
import { underW913, underW821, underW769, underW500, underW376underH668, standardStyles } from '../../styles/ProductGridElements.styles.js';

export const ProductGridContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background: #f4ecd9;
    gap: 15px;
    padding-bottom: 30px;
    // min-height: 80vh;
    align-content: flex-start
`
export const Produit = styled.Pressable`
    flex: 0 1 32%;
    flex-direction: column;
    border-radius: 22px;
    background: #f9f7ed;
    border: 1px solid rgba(226, 222, 204, .7);
    // width: 32%;
    padding: 10px;
    transition: all .25s ease;
`
export const DescriptionBox = styled.View`
    padding: 5px;
    display: unset;
`
export const Pill = styled.View`
    display: flex;
    width: 100%;
    margin-top: 5px;
    flex-direction: row;
    border: 1px solid rgba(226, 222, 204, .5);
    border-right-color: transparent;
    border-left-color: transparent;
`
export const Line = styled.View`
    flex: 1;
    height: 1px;
    width: 100%;
`
export const CardButton = styled.View`
    align-items: center;
    padding: 15px;
    width: 100%;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    transition: all .15s ease;
    background: #f4eee1;
`

export const ProductButton = ({ text }) => {
    const ref = useRef(null);
    // Pass that ref to the hooks...
    const isHovered = useHover(ref);
    return (
        <CardButton ref={ref} style={[isHovered && { background: "#efe9dc" }]}>
            <SSTextMed color={isHovered ? "#604f39" : "#2b1a02"}>
                {text}
            </SSTextMed>
        </CardButton>
    )
}

export const Product = (props) => {

    const { user } = UserAuth(user)
    const navigation = useNavigation();
    // Create a ref to bind the hooks to
    const ref = useRef(null);
    // Pass that ref to the hooks...
    const isHovered = useHover(ref);
    
    const [styles, setStyles] = useState(standardStyles)
  
    useEffect(() => {
      setStyles(standardStyles)
      // IPHONE SE
      if (props.width < 376 && props.height < 668) {
        setStyles(underW376underH668)
        console.log('under width of 376 and a height of 668')
      }
      // MOST MOBILE DEVICES
      else if (props.width < 500) {
        setStyles(underW500)
        console.log('under width of 500')
      }
      // IPAD/IPAD MINI PORTRAIT
      else if (props.width < 769) {
        setStyles(underW769)
      }
      else if (props.width < 821) {
        setStyles(underW821)
      }
      // SURFACE PRO PORTRAIT
      else if (props.width < 913) {
        setStyles(underW913)
      }
    }, [props.width, props.height])

    // console.log("this is testing product id and other props " + JSON.stringify(props));
  
    return (
        <>
            <Produit
                nativeID='Product'
                title={props.title}
                key={props.id}
                onPress={() => navigation.navigate('SignUp', {
                    productID: props.productID,
                    productPrice: props.price,
                    productTitle: props.title,
                    productPicUrl: props.pictureUrl,
                    stripePriceID: props.stripePriceID
                })}
                ref={ref}
                style={[{
                    shadowColor: '#B59668',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.2,
                    shadowRadius: 2,
                },
                isHovered && {
                    shadowOffset: { width: 0, height: 5 },
                    shadowOpacity: 0.2,
                    shadowRadius: 7,
                    background: "#fffcf2",
                    transform: "translateY(-1px)"
                },
                props.width < 500 && {
                    width: '100%'
                },
                styles.product]}>
                <View
                    nativeID='contentContainer'
                    style={{ flex: '1', justifyContent: 'space-between' }}>
                    <View>
                        <Image
                            source={{ uri: props.pictureUrl }}
                            style={{
                                width: "100%",
                                height: "300px",
                                borderTopLeftRadius: "13px",
                                borderTopRightRadius: "13px",
                                borderBottomLeftRadius: "7px",
                                borderBottomRightRadius: "7px"
                            }} />
                        <DescriptionBox>
                            <View style={{ paddingBottom: "8px" }}>
                                <IvyPresto fontSize="25px" color="#382203" lineHeight="110%">{props.title}</IvyPresto>
                            </View>
                            <Line style={{ borderBottomColor: 'rgba(226, 222, 204, .5)', borderBottomWidth: "1px", marginBottom: "8px" }} />
                            <SSText fontSize="15px" color="#49381f">{props.excerpt}</SSText>
                        </DescriptionBox>
                    </View>
                    <View nativeID='lowerContent'>
                        <Pill>
                            <View style={{ padding: "8px", borderRightColor: "rgba(226, 222, 204, .5)", borderRightWidth: "1px", width: "44%", alignItems: "center" }}>
                                <SSTextMed fontSize="10px" color="#3f3824">{props.numberOfQuestions} QUESTIONS</SSTextMed>
                            </View>
                            <View style={{ padding: "8px", width: "56%", justifyContent: "center", alignItems: "center" }}>
                                <SSTextMed fontSize="10px" color="#3f3824"> TAKES ABOUT {props.estTime}</SSTextMed>
                            </View>
                        </Pill>
                        <DescriptionBox style={{ paddingBottom: "10px" }}>
                            <View>
                                <SSTextMed color="#382203" fontSize="20px">{props.price}</SSTextMed>
                            </View>
                        </DescriptionBox>
                        <ProductButton text="Purchase" />
                    </View>
                </View>


            </Produit>
        </>
    )
}

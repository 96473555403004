import { useWindowDimensions, View, Image } from "react-native";
import React, { useState, useRef, useEffect } from "react";
import { TouchableOpacity } from "react-native-web";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import styled from "@emotion/native";
import { useHover } from "react-native-web-hooks";
import { Footer, Header, SSTextMed } from "../constants/GlobalElements";
import {
  DashboardContent,
  ProfileContent,
  MyVideosContent,
  SettingsContent,
} from "../constants/DashboardElements";
import { UserAuth } from "../context/AuthContext";
import Loader from "../components/Loader";
import { useNavigation } from "@react-navigation/native";

const textColor = "#49381f";

const Container = styled.View`
  display: flex;
  box-sizing: border-box;
  min-height: 100vh;
  background: #f4ecd9;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;
const MainBody = styled.View`
  display: flex;
  width: 79%;
  box-sizing: border-box;
  align-items: center;
`;
const TabularContentContainer = styled.View`
  display: flex;
  width: 100%;
  flex-direction: row;
  min-height: max-content;
  padding-left: 0.5%;
  padding-right: 0.5%;
  justify-content: space-between;
  margin-top: 40px;
`;
const Column = styled.View`
  display: flex;
  // min-height: 500px;
  justify-content: flex-start;
  // border: 1px dashed yellow;
`;
const TabSpace = styled.View`
  padding-top: 30px;
  display: flex;
  gap: 5px;
  flex-direction: column;
`;
const TabContentContainer = styled.View`
  padding: 30px;
  width: 100%;
`;

const TabWrap = ({ keyValue, children, width, height }) => {
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <View
      ref={ref}
      key={keyValue}
      style={[
        {
          borderRadius: "8px",
          minWidth: "max-content",
          width: "80%",
          flexDirection: "row",
          transitionTimingFunction: "ease",
          transitionDuration: ".2s",
          transitionProperty: "background",
        },
        width < 1130 && {
          width: '90%'
        },
        (width < 1181 && height < 821) && {
          width: '90%'
        },
        width < 821 && {
          width: 'max-content'
        },
        isHovered && {
          background: "#ede5d3",
        },
      ]}
    >
      {children}
    </View>
  );
};

const UserDashboardScreen = ({ route }) => {
  const { width, height } = useWindowDimensions();
  const { user } = UserAuth();
  const navigation = useNavigation();
  
  // if( window.localStorage ) {
  //       console.log("localStorage is supported");
  //       if( !localStorage.getItem('firstLoad')){
  //           localStorage['firstLoad'] = true;
  //           window.location.reload();
  //       } else
  //           localStorage.removeItem('firstLoad');
  //   } else {
  //       console.log("localStorage is not supported");
  //   }
  // const [newEmail, setNewEmail] = useState("");
  // const [displayName, setDisplayName] = useState("");
  // const [firstName, setFirstName] = useState("");
  // const [lastName, setLastName] = useState("");

  // console.log(user.uid);
    // const handleDeletion = () => {
  //   deleteUser(user)
  //     .then(() => {
  //       // User deleted.
  //       console.log("User deleted");
  //     })
  //     .catch((error) => {
  //       // An error ocurred
  //       // ...
  //       console.log("Could not delete user");
  //     });
  // };

  // const handleUpdateProfile = async () => {
  //   if (newEmail) {
  //     updateEmail(user, newEmail)
  //       .then(() => {
  //         // Email updated!
  //         // ...
  //         console.log(newEmail + "Email Updated");
  //       })
  //       .catch((error) => {
  //         // An error occurred
  //         // ...
  //         console.log(newEmail + " Email NOT Updated");
  //       });
  //   }

  //   if (displayName) {
  //     updateProfile(user, {
  //       displayName: displayName,
  //       photoURL: "https://example.com/jane-q-user/profile.jpg",
  //     })
  //       .then(() => {
  //         // Profile updated!
  //         // ...
  //         console.log(displayName + "Display name Updated");
  //       })
  //       .catch((error) => {
  //         // An error occurred
  //         // ...
  //         console.log(displayName + "Display name NOT updated");
  //       });
  //   }

  //   try {
  //     const docRef = await addDoc(collection(db, "users"), {
  //       display: displayName,
  //       first: firstName,
  //       last: lastName,
  //       email: user.email,
  //     });

  //     console.log("Document written with ID: ", docRef.id);
  //   } catch (e) {
  //     console.error("Error adding document: ", e);
  //   }
  // };

  // ⬇️ UX ANIMATION PARAMS ⬇️

//  const [selected, setSelected] = useState(1);
  // same as above but a ternary operator that checks if route.params is undefined and also sets dashboardOpacity to 0 if it is and myVideosOpacity to 1 if it is
  console.log(window.location.href);
  if (window.location.href == "https://app.storycorder.com/dashboard?from-stripe") {
    navigation.reset({
      index: 0,
      routes: [{ name: "UserDashboard" }]
    });
  }
  
  const [selected, setSelected] = useState(
    route.params?.dashboardLocation 
    ? route.params.dashboardLocation
    : 1
  );
  // const [dashboardOpacity, setDashboardOpacity] = useState(1);
  // const [myVideosOpacity, setMyVideosOpacity] = useState(0);

    const dashboardOpacity = useSharedValue(1);
    const myVideosOpacity = useSharedValue(0);


  useEffect(() => {
    if (route.params?.dashboardLocation) {
      setSelected(route.params.dashboardLocation);
      dashboardOpacity.value = 0;
      myVideosOpacity.value = 1;
    } else {
      setSelected(1);
      dashboardOpacity.value = 1;
      myVideosOpacity.value = 0;
    }
  }, [route.params?.dashboardLocation]);

  console.log(selected);

  // const dashboardOpacity = useSharedValue(1);
  const profileOpacity = useSharedValue(0);
  // const myVideosOpacity = useSharedValue(0);
  const settingsOpacity = useSharedValue(0);

  const tabs = [
    {
      id: "1",
      tabText: "Dashboard",
      icon: require("../../assets/dashboard.png"),
    },
    {
      id: "2",
      tabText: "Profile",
      icon: require("../../assets/user.png"),
    },
    {
      id: "3",
      tabText: "My Videos",
      icon: require("../../assets/video-player.png"),
    },
    {
      id: "4",
      tabText: "Settings",
      icon: require("../../assets/settings.png"),
    },
  ];
  const timing = 100;
  const dashboardAnimationStyles = useAnimatedStyle(() => {
    return {
      opacity: [
        withTiming(dashboardOpacity.value, {
          duration: timing,
        }),
      ],
    };
  });

  const profileAnimationStyles = useAnimatedStyle(() => {
    return {
      opacity: [
        withTiming(profileOpacity.value, {
          duration: timing,
        }),
      ],
    };
  });

  const myVideosAnimationStyles = useAnimatedStyle(() => {
    return {
      opacity: [
        withTiming(myVideosOpacity.value, {
          duration: timing,
        }),
      ],
    };
  });

  const settingsAnimationStyles = useAnimatedStyle(() => {
    return {
      opacity: [
        withTiming(settingsOpacity.value, {
          duration: timing,
        }),
      ],
    };
  });
  const delay = 100;
  const setParameters = (val) => {
    // if already selected: no action taken
    if (selected == val.id) return;

    // set selected to id of tab (1-4)
    setSelected(val.id);

    // reset
    dashboardOpacity.value = 0;
    profileOpacity.value = 0;
    myVideosOpacity.value = 0;
    settingsOpacity.value = 0;

    if (val.id == "1") {
      setTimeout(() => {
        dashboardOpacity.value = 1;
      }, delay);
    } else if (val.id == "2") {
      setTimeout(() => {
        profileOpacity.value = 1;
      }, delay);
    } else if (val.id == "3") {
      setTimeout(() => {
        myVideosOpacity.value = 1;
      }, delay);
    } else if (val.id == "4") {
      setTimeout(() => {
        settingsOpacity.value = 1;
      }, delay);
    }
  };

  if (!user) {
    <Loader />;
  } else {
    return (
      <Container style={[width < 500 && { height: '140vh' }, width < 391 && { height: '150vh' }, width < 376 && { height: '185vh' }]}>
        <View
          style={{
            width: "100%",
            // justifyContent: "center",
            alignItems: "center",
            minHeight: '100vh'
          }}
        >
          <Header width={width} height={height}/>
          <MainBody style={[
            width < 1181 && {
              width: '95%',
              // height: '110vh'
            },
            (width < 1181 && height < 821) && {
              height: '100vh'
            },
            (width < 1130 && height < 800) && {
              height: '110vh'
            },
            width < 821 && {
              width: '100%',
              padding: '20px',
            }
          ]}>
            <TabularContentContainer
              style={[
                width < 1367 && {
                  marginTop: 15
                },
                width < 821 && {
                  flexDirection: "column",
                  marginTop: '7px',
                  justifyContent: 'start'
                }
              ]}>
              {/* LEFT COLUMN */}
              <Column nativeID="LEFTCOLUMN"
                style={[{
                  flex: "1.5"
                },
                width < 1130 && {
                  flex: '1'
                },
                width < 821 && {
                  height: '70px',
                  flex: 'unset'
                }]}>
                <TabSpace style={[width < 821 && { flexDirection: "row", paddingTop: '0px', gap: '0px', justifyContent: 'space-around' }, width < 600 && { justifyContent: 'space-between' }]}>
                  {tabs.map((val) => {
                    return (
                      <TabWrap keyValue={`tabWrap-${val.id}`} width={width} height={height}>
                        <TouchableOpacity
                          style={{
                            borderRadius: 8,
                            padding: "15px",
                            background:
                              val.id == selected ? "#ede5d3" : "transparent",
                            paddingLeft: "18px",
                            alignItems: "start",
                            transitionTimingFunction: "ease",
                            transitionProperty: "all",
                            transitionDuration: ".2s",
                            width: "100%",
                            flexDirection: "row",
                          }}
                          onPress={() => {
                            setParameters(val);
                          }}
                        >
                          <Image
                            resizeMode="contain"
                            source={val.icon}
                            style={[
                              {
                                height: "17px",
                                width: "17px",
                                transitionTimingFunction: "ease",
                                transitionProperty: "all",
                                transitionDuration: ".2s",
                                opacity: val.id == selected ? 1 : 0.5,
                              },
                              width < 821 && {
                                display: "none"
                              }
                            ]}
                          />
                          <View style={{ paddingLeft: width < 821 ? '0px' : "20px" }} />
                          <SSTextMed
                            color={val.id == selected ? textColor : "#837458"}
                            fontSize={width < 412 ? '11px' : width < 500 ? '13px' : '15px'}
                          >
                            {val.tabText}
                          </SSTextMed>
                        </TouchableOpacity>
                      </TabWrap>
                    );
                  })}
                </TabSpace>
              </Column>
              {/* LINE IMAGE BETWEEN THE COLUMNS */}
              <Image
                resizeMode="contain"
                source={require("../../assets/Line17.png")}
                style={[
                  {
                    height: "700px",
                    width: "3px",
                    transform: "translateY(19px)",
                  },
                  width < 821 && { display: 'none' }
                ]}
              />
              <View style={[{ borderBottomColor: '#e5ddcc', borderBottomWidth: '1px', borderStyle: 'solid' }, width > 821 && { display: 'none' }]} />
              {/* RIGHT COLUMN */}
              <Column
                nativeID='rightColumn'
                style={[{
                  flex: "4",
                  paddingLeft: 30,
                  marginTop: -12
                },
                width < 1181 && {
                  paddingLeft: 0,
                  marginLeft: 0
                },
                width < 821 && {
                  paddingLeft: 0,
                  marginTop: 0,
                  flex: 1,
                  marginLeft: 0
                }]}
              >
                <TabContentContainer nativeID="tabContentStacks" style={[width < 821 && { padding: 0, paddingTop: 15 }, width < 1025 && { paddingRight: 0 }]}>
                  <View style={{ width: "100%" }}>
                    <Animated.View
                    nativeID="firstAnimatedView"
                      style={[
                        dashboardAnimationStyles,
                        {
                          width: "100%",
                          position: "absolute",
                          zIndex: selected == 1 ? "100" : "3",
                          display: selected == 1 ? "flex" : "none"
                        },
                      ]}
                    >
                      <DashboardContent width={width} height={height}/>
                    </Animated.View>
                    <Animated.View
                      style={[
                        profileAnimationStyles,
                        {
                          width: "100%",
                          position: "absolute",
                          zIndex: selected == 2 ? "100" : "3",
                          display: selected == 2 ? "flex" : "none"
                        },
                      ]}
                    >
                      <ProfileContent width={width} />
                    </Animated.View>
                    <Animated.View
                      style={[
                        myVideosAnimationStyles,
                        {
                          width: "100%",
                          position: "absolute",
                          zIndex: selected == 3 ? "100" : "3",
                          display: selected == 3 ? "flex" : "none"
                        },
                      ]}
                    >
                      <MyVideosContent width={width} />
                    </Animated.View>
                    <Animated.View
                      style={[
                        settingsAnimationStyles,
                        {
                          width: "100%",
                          position: "absolute",
                          zIndex: selected == 4 ? "100" : "3",
                          display: selected == 4 ? "flex" : "none"
                        },
                      ]}
                    >
                      <SettingsContent width={width} />
                    </Animated.View>
                  </View>
                </TabContentContainer>
              </Column>
            </TabularContentContainer>
          </MainBody>
        </View>
        <Footer width={width} height={height}/>
      </Container>
    );
  }
};

export default UserDashboardScreen;
import { View, Image, Pressable, ImageBackground, useWindowDimensions } from 'react-native'
import { React, useRef } from 'react';
import styled from '@emotion/native'
import { Header, Footer, IvyPrestoSemiBoldItalic, SSTextBold, } from '../constants/GlobalElements'
import { useHover } from 'react-native-web-hooks';
import { useNavigation } from '@react-navigation/native';
import ProductGrid from '../components/ProductGrid/ProductGrid';

const Container = styled.View`
  display: flex;
  min-height: 100vh;
  background: #f4ecd9;
  align-items: center;
`
const MainBody = styled.View`
  display: flex;
  width: 79%;
  padding-top: 15px;
  min-height: 90vh;
  align-items: start;
`
const TopRow = styled.View`
  display: flex;
  width: 100%;
  gap: 70px;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  background: #F7F4E9;
  // border: 1px solid #edc5af;
  border-radius: 3px;
`
const IntroductionBox = styled.View`
  flex: 12 1 500px;
  flex-wrap: wrap;
  // good pink -> #F7D5C3
  padding-left: 46px;
  padding-right: 46px;
  padding-bottom: 56px;
  // flex-basis: 400px;
  width: 400px;
`
// component below allows for hover effects (a bit more involved with react native) and limits volume of code needed
// uses the RN <Pressable> component

const LogoArea = styled.View`
  width: 100%;
  height: max-content;
  flex-direction: row;
  justify-content: flex-end;
  align-items: end;
  // background: #b1c185;
`
const TryIt = styled.Pressable`
  flex: 1;
  padding: 30px;
  flex-direction: column;
  border-radius: 22px;
  justify-content: space-between;
  transition: all .35s ease;
  width: 100%;
  // background: #b8c693;
`
const Overlay = styled.View`
  background: rgba(255,255,255,.09);
  border: 1px solid rgba(255,255,255, .15);
  width: 100%;
  height: 100%;
  border-radius: 22px;
  transition: background .5s ease;

`
const TryItBox = ({ navigateTo, width }) => {

  const navigation = useNavigation();
  // Create a ref to bind the hooks to
  const ref = useRef(null);
  // Pass that ref to the hooks...
  const isHovered = useHover(ref);
  return (
    <View nativeID='viewContainer' style={[width < 500 && { width: '100%', height: '165px' }, width < 1367 && {height: '235px'}]}>
      <ImageBackground
        source={require('../../assets/tryitbackground.png')}
        ref={ref}
        imageStyle={{ borderRadius: 22, width: "100%", }}
        style={[{
          shadowColor: '#B59668',
          borderRadius: "22px",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.3,
          shadowRadius: 6,
          flex: "1",
          flexBasis: "300px",
          width: width < 500 ? '100%' : '300px',
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
          transitionTimingFunction: "ease",
          transitionDuration: ".5s",
          transitionProperty: "all"
        },
        isHovered && {
          transform: "translateY(-1px)",
          shadowOffset: { width: 2, height: 12 },
          shadowOpacity: 0.4,
          shadowRadius: 22
        }]} >
        <TryIt
          onPress={() => navigation.navigate(navigateTo)}
          style={[
            width < 500 && {
              padding: '20px'
            }
          ]}
        >
          <Overlay style={[{ position: "absolute", top: 0, left: 0 }, isHovered && { background: "rgba(255,255,255, .18)" }]} />
          <View style={[
            width < 500 && {
              flexBasis: "70%",
              width: '70%',
              flex: '1'
            }
          ]}>
            <SSTextBold color="#FEFCF2" fontSize={width < 1367 ? '20px' : width < 500 ? '20px' : '26px'} lineHeight="100%">Take the unique Storycorder experience for a spin.</SSTextBold>
          </View>
          <LogoArea style={[width < 500 && { top: '-10px', flexBasis: '30%', flex: '1' }]}>

            <Image
              resizeMode="contain"
              source={require('../../assets/littlewhitecorvette.png')}
              style={{ height: '45px', width: '45px', opacity: .4, transform: "translate(5px, 10px)" }} />
          </LogoArea>
        </TryIt>
      </ImageBackground>
    </View>
  )
}

const GetStartedScreen = () => {
  const { width, height } = useWindowDimensions()
  return (
    <Container>
      <Header width={width} height={height}/>
      <MainBody
        style={[
          width < 1181 && {
            width: '94%'
          },
          width < 500 && {
            width: '100%',
            padding: 20
          }
        ]}
      >
        <TopRow
          style={[
            width < 500 && {
              padding: 20,
              paddingTop: 0,
              gap: '10px',
              justifyContent: 'flex-start'
            }
          ]}
        >
          <IntroductionBox
            style={[
              width < 1367 && {
                paddingLeft: 32,
                paddingBottom: 32
              },
              width < 500 && {
                padding: 0
              }
            ]}
          >
            <View style={{ display: "flex", justifyContent: "space-between", alignItems: "start", flexDirection: "row", width: "100%", paddingBottom: "0px" }}>
              <IvyPrestoSemiBoldItalic fontSize={width < 1367 ? '75px' : '95px'} color="#EBB595" style={{ flex: "1" }}>Start Here</IvyPrestoSemiBoldItalic>
            </View>
            <SSTextBold fontSize={width < 1367 ? '18px':'20px'} color="#dfaa8b">
              Looking to create memories that last a lifetime? Capture the stories that matter so you can cherish and share them with those who are special to you for generations. Record, review, and save your favorite memories faster than ever before with our easy-to-use program!
            </SSTextBold>
          </IntroductionBox>
          <TryItBox navigateTo="TryIt" width={width} />
        </TopRow>
        <View style={{ paddingBottom: "15px" }} />
        <ProductGrid width={width} />
      </MainBody>
      <Footer width={width} height={height}/>
    </Container>
  )
}

export default GetStartedScreen;
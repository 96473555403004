import { View, useWindowDimensions } from 'react-native'
import styled from '@emotion/native'
import { Header, Footer, IvyPrestoSemiBold } from '../constants/GlobalElements'
import ProductGrid from '../components/ProductGrid/ProductGrid';

const Container = styled.View`
  display: flex;
  min-height: 100vh;
  background: #f4ecd9;
  align-items: center;
`
const MainBody = styled.View`
  display: flex;
  width: 79%;
  padding-top: 15px;
  min-height: 90vh;
  align-items: start;
`

const StoreScreen = () => {
  const { width, height } = useWindowDimensions();
  return (
    <Container>
      <Header width={width} height={height}/>
      <MainBody style={[
        width < 1181 && {
          width: '93%'
        },
        width < 500 && {
          width: '100%',
          padding: '20px'
        }]}>
        <View style={{ paddingBottom: "10px", paddingLeft: "5px" }}>
          <IvyPrestoSemiBold color="#382203" fontSize="25px">Interviews</IvyPrestoSemiBold>
        </View>

        <ProductGrid width={width} height={height} />
      </MainBody>
      <Footer width={width} height={height}/>
    </Container>
  )
}

export default StoreScreen;
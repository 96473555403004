import React, { useRef, useState, useEffect } from 'react';
import { Text, View, Image, Pressable, InputText, Linking, Alert } from 'react-native';
import styled from '@emotion/native'
import { useHover } from 'react-native-web-hooks';
import { auth, db } from '../firebaseConfig'
import { signOut, deleteUser, updateEmail, updateProfile, onAuthStateChanged } from "firebase/auth"
// import UserBadge from '../components/UserBadge'
import { SSText, SSTextMed, SSTextBold, IvyPrestoSemiBold, IvyPresto } from '../constants/GlobalElements'
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { UserAuth } from '../context/AuthContext';
import { collection, addDoc, updateDoc, doc, getDoc, getDocs, setDoc, onSnapshot } from "firebase/firestore"
import { useNavigation } from '@react-navigation/native'
import { underW1025, underW1400, underW821, underW769, underW500, underW376underH668, standardStyles } from '../styles/DashboardElements.styles'

const textColor = "#392404"

const Button = styled.Pressable`
    display: flex;
    border-radius: 999px;
    padding: 5px;
    background: #F5A477;
    width: max-content;
    height: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all .2s ease;
`
const ContinueButton = ({ product, user }) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const navigation = useNavigation();
    // console.log(product)

    return (
        <Button ref={ref} style={[isHovered && { background: "#efa275", transform: "translateY(-1.5px)" }]} onPress={() => navigation.navigate("InterviewInterface", { videoId: product?.id.productId, user: user, uid: user.uid, email: user.email })}>
            <View style={{ paddingRight: "10px", paddingLeft: "20px" }}>
                <SSTextBold color={isHovered ? "#fff" : "#fff"}>Continue</SSTextBold>
            </View>
            <View
                style={[{ background: "#eab596", borderRadius: "999px", height: "33px", width: "33px", display: "flex", alignItems: "center", justifyContent: "center", transitionProperty: "background", transitionDuration: ".2s", transitionTimingFunction: "ease" },
                isHovered && { background: "#e8b192" }]}>
                <Image
                    resizeMode="contain"
                    source={require('../../assets/ruddyvideoplayer.png')}
                    style={{ height: '20px', width: '20px' }} />
            </View>
        </Button>
    )
}

const ProgressAlertCard = styled.View`
    gap: 90px;
    width: max-content;
    padding: 15px;
    flex-direction: row;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 20px;
    justify-content: space-between;
    align-items: center;
    background: #F7F4E9;
    margin-top: 20px;
`
const FinishedBadgeCard = styled.View`
    flex-basis: 250px;
    width: 250px;
    flex: 1;
    padding: 25px;
    border-radius: 10px;
    align-items: center;
    background: #F7F4E9;
    margin-top: 20px;
    min-height: 260px;
    height: max-content;
    text-align: center;
`
const CardTemplate = styled.View`
    flex-basis: 250px;
    flex: 1;
    border-radius: 10px;
    align-items: center;
    background: #F7F4E9;
    margin-top: 20px;
    min-height: min-content;
    height: min-content;
    width: max-content;
`
const Line = styled.View`
    border-top-color: #e8e2ce;
    border-top-width: 1px;
    width: 55%;
    margin-bottom: 10px;
`
const UpdateButton = ({ children, updateFunction }) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <Pressable ref={ref} onPress={updateFunction} style={[{ borderRadius: "8px", padding: "15px", textAlign: "center" }, isHovered && { background: "#f2eee1", }]}>
            {children}
        </Pressable>
    )
}

export const DashboardContent = ({ width, height }) => {
    const { user } = UserAuth();
    const [ownedProducts, setOwnedProducts] = useState([])
    const [completedProducts, setCompletedProducts] = useState([])
    const progress = 55;

    const [products, setProducts] = useState([])

    const [styles, setStyles] = useState(standardStyles)

    useEffect(() => {
        setStyles(standardStyles)
        // IPHONE SE

        // IPAD/IPAD MINI PORTRAIT
        // if (width < 769) {
        //     setStyles(underW769)
        // }
        // IPAD/IPAD MINI LANDSCAPE
        if (width < 1025) {
            setStyles(underW1025)
        } else if ( width < 1400) {
            setStyles(underW1400)
        }
        

    }, [width, height])

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "Products"), (snapshot) => {
            setProducts(snapshot.docs.map(doc => ({
                id: doc.id,
                title: doc.data().title,
                pictureUrl: doc.data().pictureUrl,
                videoId: doc.id
            })))
        })
        return unsubscribe
    }, [])

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "users", user.uid, "ownedProducts"), (snapshot) => {
            setOwnedProducts(snapshot.docs.map(doc => ({
                id: doc.data()
            })))
        })
        return unsubscribe
    }, [])
    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "users", user.uid, "videos"), (snapshot) => {
            setCompletedProducts(snapshot.docs.map(doc => ({
                // terniary operator to check if completed is undefined
                id: doc.id,
                completed: doc.data().completed,
            })))
        })
        return unsubscribe
    }, [])

    // console.log(completedProducts[0]?.id)
    // console.log("currentProduct aaaahhhhh", currentProduct)
    // console.log(window.location.href);
    if (window.location.href == "https://app.storycorder.com/dashboard?from-stripe") {
        navigation.reset({
        index: 0,
        routes: [{ name: "UserDashboard" }]
        });
    }

    const productTitle = (currentProduct) => {
        const title = products.findIndex(product => product.id === currentProduct.slice(0, -1))
        return products[title].title + " " + (parseInt(currentProduct.slice(-1)) + 1)
    }

    // check if currentProduct is in completedProducts

    // const currentProduct = ownedProducts.filter(product => completedProducts.findIndex(completedProduct => completedProduct.id === product.id.productId && completedProduct.completed !== undefined ) === 1)


    const currentProduct = ownedProducts.filter(product => {
        // if the completedProducts array does not have the current product id, return true
        if(completedProducts.findIndex(completedProduct => completedProduct.id === product.id.productId) === -1) {
            return true
        } else {
        return completedProducts.some(completedProduct => {
          return completedProduct?.id === product?.id.productId && completedProduct?.completed === undefined;
        });
    }
      });

    // const currentProduct = ownedProducts.filter(product => {
    //     return completedProducts.some(completedProduct => {
    //       return completedProduct?.id === product.id?.productId && completedProduct?.completed !== undefined;
    //     });
    //   });
      
    // console.log("currentProduct", currentProduct)
    // console.log("ownedProducts", ownedProducts)
    // let totalLength = products.questions.length

    return (
        <>
            <View style={{ paddingLeft: width < 500 ? '3px' : "6px" }}>
                <IvyPrestoSemiBold color={textColor} fontSize={width < 500 ? '25px' : '40px'}>Welcome back {user?.displayName ? `, ${user.displayName}!` : "!"}</IvyPrestoSemiBold>
            </View>
            <ProgressAlertCard
                style={[{
                    shadowColor: '#B59668',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.2,
                    shadowRadius: 2,
                    marginBottom: 20
                },
                width < 500 && {
                    width: '100%',
                    flexDirection: 'column',
                    gap: 30
                },
                styles.progressAlertCard]}>
                {/* {products.map((product, index) => ( */}
                <SSTextBold color="#413412" fontSize="16px">Your purchased interviews will show up here. {"\n"}Please allow a few minutes after purchase to prep the interview.</SSTextBold>
                {/* ))} */}
            </ProgressAlertCard>
            {currentProduct.map((product, index) => (
                product.completed === undefined || product.completed === 'rendering' ? (
                    <ProgressAlertCard
                        style={[{
                            shadowColor: '#B59668',
                            shadowOffset: { width: 0, height: 2 },
                            shadowOpacity: 0.2,
                            shadowRadius: 2
                        },
                        width < 1400 && {
                            width: '100%',
                            flexDirection: 'column',
                            gap: 30
                        },
                        width < 1400 && {

                        },
                        styles.progressAlertCard]}>
                        <View style={[
                            width < 1400 && {
                                alignItems: 'flex-start',
                                width: '100%'
                            }
                        ]}>
                            <SSTextBold color="#413412" fontSize="16px">
                                Your {productTitle(product.id.productId)} video interview is not yet complete!
                                {width > 1400 ?
                                    <Text>{"\n"} </Text> :
                                    <Text> </Text>
                                }Pick up where you left off.
                            </SSTextBold>
                        </View>
                        <View
                            style={[
                                width < 1400 && {
                                    alignItems: 'end',
                                    width: '100%'
                                }
                            ]}
                        >
                            <ContinueButton product={product} user={user} />
                        </View>
                    </ProgressAlertCard>
                ) : null
            ))}
            {/* {currentProduct[0]?.id == undefined && ownedProducts[0]?.id == undefined ? (
                <FinishedBadgeCard style={{ shadowColor: '#B59668', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 2 }}>
                    <Image
                        resizeMode="contain"
                        source={require('../../assets/badge.png')}
                        style={{ height: '120px', width: '120px', marginBottom: "10px" }} />
                    <Line />
                    <SSTextBold color="#413412" fontSize="18px">
                        Sorry, you haven't purchased any interviews yet!
                    </SSTextBold>
                </FinishedBadgeCard>
            ) : (null)} */}
        </>
    )
}
const LoggedInUserBadge = styled.View`
    display: flex;
    border-radius: 999px;
    border: 8px solid #f4ebd0;
    z-index: 777
`
const Input = styled.TextInput`
    height: 45px;
    width: 100%;
    border: 1px solid rgba(226, 222, 204, 1);
    border-radius: 7px;
    margin-top: 5px;
    background: #fcfaf2;
    padding-left: 12px;
    color: rgb(117, 99, 73);
`

export const ProfileContent = ({ width }) => {
    const { user } = UserAuth();
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const [firstName, onChangeFirstName] = useState('');
    const [lastName, onChangeLastName] = useState('');
    const [updatedFirstName, onChangeUpdatedFirstName] = useState('');
    const [updatedLastName, onChangeUpdatedLastName] = useState('');
    const [email, onChangeEmail] = useState('');
    const [updatedEmail, onChangeUpdatedEmail] = useState('');
    const [location, onChangeLocation] = useState('');
    const [updatedLocation, onChangeUpdatedLocation] = useState('');
    const [imageUpload, setImageUpload] = useState(null);
    const [nameExists, setNameCondition] = useState(false)
    // const uploadImage = async () => {
    //     // ('#OpenImgUpload').click(function(){ $('#imgupload').trigger('click'); });
    //     if (imageUpload == null) return;
    //     console.log(imageUpload);
    //     const imageRef = ref(storage, 'images/' + user.uid + '/pfp/' + imageUpload.name)
    //     uploadBytes(imageRef, imageUpload).then(() => {
    //       alert("Image Uploaded")
    //     });
    //   };

    const handleUpdateProfile = async () => {

        if (email) {
            updateEmail(user, email).then(() => {
                // Email updated! 
                // ...
                console.log(email + "Email Updated")
            }).catch((error) => {
                // An error occurred
                // ...
                console.log(email + " Email NOT Updated")
            });
        }

        try {
            await updateDoc(doc(db, "users", user.uid), {
                // display: displayName,
                first: firstName,
                last: lastName,
                email: email,
                location: location,
            }).then(() => {
                console.log("Document successfully updated!");
            });

            //   console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }
    const setCondition = () => {
        setNameCondition(updatedLastName == '' || updatedFirstName == '')
    }

    useEffect(() => {
        // if( window.localStorage ) {
        //     console.log("localStorage is supported");
        //     if( !localStorage.getItem('firstLoad')){
        //         localStorage['firstLoad'] = true;
        //         window.location.reload();
        //     } else
        //         localStorage.removeItem('firstLoad');
        // } else {
        //     console.log("localStorage is not supported");
        // }
        const getUserData = async () => {
            const docRef = doc(db, "users", user.uid);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                // console.log("Document data:", docSnap.data());
                onChangeUpdatedFirstName(docSnap.data().first);
                onChangeUpdatedLastName(docSnap.data().last);
                onChangeUpdatedEmail(docSnap.data().email);
                onChangeUpdatedLocation(docSnap.data().location);
            } else {
                // doc.data() will be undefined in this case
                console.log("No such document!");
            }
        }
        getUserData();
    }, []);

    // console.log(user?.email);
    return (
        <>
            <View style={{ paddingLeft: "6px" }}>
                <IvyPrestoSemiBold color={textColor} fontSize={width < 500 ? '25px' : '30px'}>Profile</IvyPrestoSemiBold>
            </View>
            <CardTemplate style={[{
                flexDirection: "row",
                gap: width < 500 ? '0px' : "50px",
                width: width < 500 ? "100%" : "721px",
                justifyContent: "space-between",
                shadowColor: '#B59668',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.2,
                shadowRadius: 2
            },
            width < 500 && {
                flexDirection: 'column-reverse'
            }
            ]}>
                <View style={[{ flexDirection: "column", padding: "35px", paddingLeft: "50px" }, width < 500 && { padding: 0, paddingBottom: "55px", textAlign: 'center' }]}>
                    <SSTextBold color="#413412" fontSize="35px">
                        {firstName == '' && lastName == '' ?
                        // `${updatedFirstName + " " + updatedLastName}` ?? user?.displayName
                            updatedFirstName == undefined && updatedLastName == undefined ? user?.displayName : `${updatedFirstName + " " + updatedLastName}`
                        : `${firstName + " " + lastName}`}
                    </SSTextBold>
                    <SSText color="#b2ad9e" fontSize="15px">
                        Member since {user?.metadata.creationTime.slice(8, -12)}
                    </SSText>
                    <SSText color="#b2ad9e" fontSize="15px">
                        {updatedLocation}
                    </SSText>
                    <SSText color="#b2ad9e" fontSize="15px">
                        {email == '' ?
                            updatedEmail == undefined ? user?.email : updatedEmail
                        : email}
                    </SSText>
                </View>
                <View style={[{ borderLeftColor: "#e8e2ce", borderLeftWidth: width < 500 ? '0px' : "1px", padding: "45px", position: "relative" }, width < 500 && { paddingBottom: '25px' }]}>
                    <LoggedInUserBadge>
                        <Image
                            resizeMode="contain"
                            source={require('../../assets/usericon.png')}
                            style={{ height: '145px', width: '145px', borderRadius: 999 }} />
                    </LoggedInUserBadge>
                    <Pressable
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "5px",
                            borderRadius: "8px",
                            borderColor: "#f4ebd0",
                            background: "#f5f4ec",
                            borderWidth: 4,
                            padding: "5px",
                            paddingLeft: "8px",
                            width: "max-content",
                            marginTop: "-45px",
                            position: "relative",
                            zIndex: '999',
                            transitionDuration: '.2s',
                            transitionTimingFunction: 'ease',
                            transitionProperty: 'all',
                        }}>
                        <SSTextMed color="#413412" fontSize="14px">
                            Edit
                        </SSTextMed>
                        <Image
                            resizeMode="contain"
                            source={require('../../assets/edit.png')}
                            style={{ height: '17px', width: '17px', transform: "translateY(-1px)", zIndex: "999" }} />
                    </Pressable>
                </View>
            </CardTemplate>
            <CardTemplate style={{
                width: width < 500 ? "100%" : "721px",
                justifyContent: "center",
                shadowColor: '#B59668',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.2,
                shadowRadius: 2
            }}>
                <View style={[{
                    paddingTop: "30px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    paddingBottom: "20px",
                    justifyContent: "flex-start",
                    width: "100%",
                    borderBottomColor: "#e8e2ce",
                    borderBottomWidth: "1px"
                },
                width < 500 && {
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingTop: '25px'
                }]}>

                    <SSTextBold color="#413412" fontSize="25px" lineHeight="150%">
                        Update Profile
                    </SSTextBold>
                    <SSText color="#b2ad9e" fontSize="15px">
                        Manage your profile details
                    </SSText>
                </View>
                <View style={[{
                    flexDirection: 'row',
                    gap: "15px", width: "100%",
                    paddingTop: "20px",
                    paddingLeft: "40px",
                    paddingRight: "40px"
                },
                width < 500 && {
                    flexDirection: 'column',
                    paddingLeft: '30px',
                    paddingRight: '30px'
                }]}>
                    <View style={{ flex: 1 }}>
                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                            First Name
                        </SSTextMed>
                        <Input
                            value={firstName}
                            onChangeText={text => onChangeFirstName(text)}
                            keyboardType="default"
                            style={[
                                width < 500 && {
                                    height: '40px'
                                }]}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                            Last Name
                        </SSTextMed>
                        <Input
                            value={lastName}
                            onChangeText={text => onChangeLastName(text)}
                            keyboardType="default"
                            style={[
                                width < 500 && {
                                    height: '40px'
                                }]}
                        />
                    </View>
                </View>
                <View style={[{
                    flexDirection: 'row',
                    gap: "15px",
                    width: "100%",
                    paddingTop: "20px",
                    paddingBottom: "35px",
                    paddingLeft: "40px",
                    paddingRight: "40px"
                },
                width < 500 && {
                    flexDirection: 'column',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                }]}>
                    
                    <View style={{ flex: 1 }}>
                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                            E-mail
                        </SSTextMed>
                        <Input
                            value={email}
                            onChangeText={text => onChangeEmail(text)}
                            keyboardType="email-address"
                            style={[
                                width < 500 && {
                                    height: '40px'
                                }]}
                        />
                    </View>
                    {/* <View style={{ flex: 1 }}>
                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                            Location
                        </SSTextMed>
                        <Input
                            value={location}
                            onChangeText={text => onChangeLocation(text)}
                            keyboardType="default"
                            style={[
                                width < 500 && {
                                    height: '40px'
                                }]}
                        />
                    </View> */}
                </View>
                <View style={{
                    padding: '8px',
                    width: "100%",
                    borderTopColor: "#e8e2ce",
                    borderTopWidth: "1px",
                }}>
                    <Pressable ref={ref} style={[{ borderRadius: "8px", padding: "15px", textAlign: "center" }, isHovered && { background: "#f2eee1", }]} onPress={handleUpdateProfile}>
                        <SSTextMed color="#413412" fontSize="15px">
                            Update
                        </SSTextMed>
                    </Pressable>
                    {/* <UpdateButton updateFunction={() => { Linking.openURL(`${navigateTo}`) }} >
                        <SSTextMed color="#413412" fontSize="15px">
                            Update
                        </SSTextMed>
                    </UpdateButton> */}
                </View>
            </CardTemplate>
        </>
    )
}
const ProductGridContainer = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 15px;
    padding-bottom: 30px;
`
const Produit = styled.Pressable`
    flex-basis: 275px;
    border-radius: 15px;
    background: #f9f7ed;
    border: 1px solid rgba(226, 222, 204, .7);
    // width: 300px;
    padding: 8px;
    transition: all .25s ease;
    height: max-content;
`
const CardButton = styled.View`
    align-items: center;
    padding: 10px;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    transition: all .15s ease;
    background: #f4eee1;
`
const ProductButton = ({ text }) => {
    const ref = useRef(null);
    // Pass that ref to the hooks...
    const isHovered = useHover(ref);
    return (
        <CardButton ref={ref} style={[isHovered && { background: "#efe9dc" }]}>
            <SSTextMed fontSize="12px" color={isHovered ? "#604f39" : "#2b1a02"}>
                {text}
            </SSTextMed>
        </CardButton>
    )
}
const Product = ({ pictureUrl, id, title, videoId, user, width, completed }) => {

    // const { user } = UserAuth(user)
    // Create a ref to bind the hooks to
    const ref = useRef(null);
    // Pass that ref to the hooks...
    const isHovered = useHover(ref)
    const navigation = useNavigation();
    console.log("user", completed)

    return (
        <>
            <Produit
                title={title}
                key={id}
                ref={ref}
                onPress={() => { navigation.navigate("WatchInterview", { videoId: videoId, user: user.uid, doneUrl: completed }) }}
                style={[{
                    shadowColor: '#B59668',
                    shadowOffset: { width: 0, height: 2 },
                    shadowOpacity: 0.2,
                    shadowRadius: 2,
                },
                isHovered && {
                    shadowOffset: { width: 0, height: 5 },
                    shadowOpacity: 0.2,
                    shadowRadius: 7,
                    background: "#fffcf2",
                    transform: "translateY(-1px)"
                },
                width < 500 && {
                    width: '100%',
                    flexBasis: 'unset',
                    display: 'block',
                }
                ]}>
                <Image
                    source={{ uri: pictureUrl }}
                    style={[{
                        width: "100%",
                        height: "175px",
                        borderTopLeftRadius: "6px",
                        borderTopRightRadius: "6px",
                        borderBottomLeftRadius: "3px",
                        borderBottomRightRadius: "3px"
                    },
                    width < 500 && {
                        height: 250
                    }]} />
                <View style={{ paddingBottom: 5, paddingLeft: 3 }}>
                    <IvyPresto fontSize={'17px'} color="#382203" >{title}</IvyPresto>
                </View>
                <ProductButton text="View" />
            </Produit>
        </>
    )
}

export const MyVideosContent = ({ width, height }) => {

    const [Products, setProducts] = useState([])
    const [ownedProducts, setOwnedProducts] = useState([])
    const [styles, setStyles] = useState(standardStyles)
    const { user } = UserAuth();

    useEffect(() => {
        setStyles(standardStyles)
        // IPHONE SE

        // IPAD/IPAD MINI PORTRAIT
        // if (width < 769) {
        //     setStyles(underW769)
        // }
        // IPAD/IPAD MINI LANDSCAPE
        if (width < 1025) {
            setStyles(underW1025)
        }

    }, [width, height])


    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "Products"), (snapshot) => {
            setProducts(snapshot.docs.map(doc => ({
                id: doc.id,
                title: doc.data().title,
                pictureUrl: doc.data().pictureUrl,
                videoId: doc.id,
            })))
        })
        return unsubscribe
    }, [])

    useEffect(() => {
        const unsubscribe = onSnapshot(collection(db, "users", user.uid, "videos"), (snapshot) => {
            setOwnedProducts(snapshot.docs.map(doc => ({
                id: doc.id,
                completed: doc.data().completed,
            })))
        })
        return unsubscribe
    }, [])

    // create a new aray of from the Products array that only includes the products that are in the ownedProducts array
    // const currentProduct = Products.filter(product => ownedProducts.find(ownedProduct => ownedProduct.id === product.id && ownedProduct.completed !== undefined && ownedProduct.completed !== 'rendering'))
    // const currentProduct = ownedProducts.filter(product => product.completed !== undefined && product.completed !== 'rendering')
    // const displayProducts = Products.filter(product => currentProduct.find(ownedProduct => ownedProduct.id.slice(0, -1) === product.id && ownedProduct.completed !== undefined && ownedProduct.completed !== 'rendering'))
    // console.log(Products)
    // console.log(ownedProducts)

    const notCompleted = ownedProducts.filter(product => product.completed !== undefined && product.completed !== 'rendering')
    const currentProduct = notCompleted.map(product => product.id.slice(0, -1))
    // create a new array that includes Products that match each item in the currentProduct array
    const displayProducts = currentProduct.map(product => Products.find(item => item.id === product))

    // console.log("currentProduct", displayProducts)
    return (
        <>
            <View style={{ paddingLeft: "6px", paddingBottom: "20px" }}>
                <IvyPrestoSemiBold color={textColor} fontSize={width < 500 ? '25px' : '30px'}>My Videos</IvyPrestoSemiBold>
            </View>
            <View>
                <ProgressAlertCard
                    style={[{
                        shadowColor: '#B59668',
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.2,
                        shadowRadius: 2,
                        marginBottom: 20
                    },
                    width < 500 && {
                        width: '100%',
                        flexDirection: 'column',
                        gap: 30
                    },
                    styles.progressAlertCard]}>
                    {/* {products.map((product, index) => ( */}
                    <SSTextBold color="#413412" fontSize="16px">Once your videos are complete, you can watch them here. {"\n"}Please allow up to 15 minutes for your video to finish {"\n"}processing before it shows up here.</SSTextBold>
                    {/* ))} */}
                </ProgressAlertCard>
                <ProductGridContainer style={[
                    width < 500 && {
                        minHeight: '20vh',
                        gap: '10px',
                        width: '100%'
                    }]}>
                    {displayProducts.map((product, index) => (
                        // console.log(product?.completed),
                        product.completed === undefined ?
                            <Product
                                key={`${index}`}
                                productID={product.id}
                                title={product.title + " " + (parseInt(ownedProducts[index]?.id.slice(-1)) + 1)}
                                // navigateTo={product.navigateTo}
                                pictureUrl={product.pictureUrl}
                                videoId={ownedProducts[index]?.id}
                                user={user}
                                width={width}
                                completed={product.completed}
                            />
                            // (products.find(product => product.id === ownedProducts[index]?.id.productId))
                            // console.log(currentProduct)
                            // (ownedProducts[index]?.id.productId.includes(product.id))
                            // (product.id.includes(ownedProducts[index]?.id.productId))
                            // ?
                        //)
                        :
                        null


                    ))}
                </ProductGridContainer>
            </View>
        </>
    )
}

export const SettingsContent = ({ width }) => {

    const [newsletterIsSelected, setNewsletterSelection] = useState(true);
    const [textNotificationsIsSelected, setTextNotifications] = useState(false);
    const [promotionalEmailsIsSelected, setPromotionalEmails] = useState(true);
    const [newpassword, onChangePassword] = useState('');
    const [newpasswordconfirmation, onChangeConfirmPassword] = useState('');
    return (
        <>

            <View style={{ paddingLeft: "6px" }}>
                <IvyPrestoSemiBold color={textColor} fontSize={width < 500 ? '25px' : '30px'}>Settings</IvyPrestoSemiBold>
            </View>
            {/* BELOW COMPONENT COMPRISED OF EMAIL, TEXT, ETC. NOTIFICATION SETTINGS */}
            {/* <CardTemplate style={{ minWidth: "721px", justifyContent: "center", shadowColor: '#B59668', shadowOffset: { width: 0, height: 2 }, shadowOpacity: 0.2, shadowRadius: 2 }}>
                <View style={{
                    paddingTop: "30px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    paddingBottom: "20px",
                    justifyContent: "flex-start",
                    width: "100%",
                    borderBottomColor: "#e8e2ce",
                    borderBottomWidth: "1px",
                }}>

                    <SSTextBold color="#413412" fontSize="25px" lineHeight="150%">
                        Notifications
                    </SSTextBold>

                    <SSText color="#b2ad9e" fontSize="15px">
                        Manage your notification settings
                    </SSText>
                </View>
                <View style={{ flexDirection: "row", width: "100%", paddingTop: "27px", paddingLeft: "40px", paddingRight: "40px" }}>
                    <BouncyCheckbox
                        size={18}
                        fillColor={newsletterIsSelected ? "#91b207" : "#bfb09a"}
                        isChecked={newsletterIsSelected}
                        innerIconStyle={{ borderWidth: 2, borderRadius: "4px" }}
                        iconStyle={{ borderRadius: "4px" }}
                        onPress={() => setNewsletterSelection(!newsletterIsSelected)}
                    />
                    <SSTextMed color="#756349" fontSize="14px">
                        Newsletter
                    </SSTextMed>
                </View>
                <View style={{ flexDirection: "row", width: "100%", paddingTop: "20px", paddingLeft: "40px", paddingRight: "40px" }}>
                    <BouncyCheckbox
                        size={18}
                        fillColor={textNotificationsIsSelected ? "#91b207" : "#bfb09a"}
                        isChecked={textNotificationsIsSelected}
                        innerIconStyle={{ borderWidth: 2, borderRadius: "4px" }}
                        iconStyle={{ borderRadius: "4px" }}
                        onPress={() => setTextNotifications(!textNotificationsIsSelected)}
                    />
                    <SSTextMed color="#756349" fontSize="14px">
                        Text Notifications
                    </SSTextMed>
                </View>
                <View style={{ flexDirection: "row", width: "100%", paddingTop: "20px", paddingLeft: "40px", paddingRight: "40px", paddingBottom: "30px" }}>
                    <BouncyCheckbox
                        size={18}
                        fillColor={promotionalEmailsIsSelected ? "#91b207" : "#bfb09a"}
                        isChecked={promotionalEmailsIsSelected}
                        innerIconStyle={{ borderWidth: 2, borderRadius: "4px" }}
                        iconStyle={{ borderRadius: "4px" }}
                        onPress={() => setPromotionalEmails(!promotionalEmailsIsSelected)}
                    />
                    <SSTextMed color="#756349" fontSize="14px">
                        Promotional Emails
                    </SSTextMed>
                </View>

                <View style={{
                    padding: '8px',
                    width: "100%",
                    borderTopColor: "#e8e2ce",
                    borderTopWidth: "1px",
                }}>
                    <UpdateButton updateFunction={() => { Linking.openURL(`${navigateTo}`) }} >
                        <SSTextMed color="#413412" fontSize="15px">
                            Save Preferences
                        </SSTextMed>
                    </UpdateButton>
                </View>
            </CardTemplate> */}
            <CardTemplate style={{
                width: width < 500 ? "100%" : "721px",
                justifyContent: "center",
                shadowColor: '#B59668',
                shadowOffset: { width: 0, height: 2 },
                shadowOpacity: 0.2,
                shadowRadius: 2
            }}>
                <View style={[{
                    paddingTop: "30px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    paddingBottom: "20px",
                    justifyContent: "flex-start",
                    width: "100%",
                    borderBottomColor: "#e8e2ce",
                    borderBottomWidth: "1px"
                },
                width < 500 && {
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    paddingTop: '25px'
                }]}>

                    <SSTextBold color="#413412" fontSize="25px" lineHeight="150%">
                        Password
                    </SSTextBold>
                    <SSText color="#b2ad9e" fontSize="15px">
                        Edit your saved password
                    </SSText>
                </View>
                <View style={[{
                    gap: "15px",
                    width: "100%",
                    paddingTop: "20px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    paddingBottom: "30px"
                },
                width < 500 && {
                    paddingLeft: '30px',
                    paddingRight: '30px',
                }]}>
                    <View style={{ flex: 1 }}>
                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                            Password
                        </SSTextMed>
                        <Input
                            onChangeText={onChangePassword}
                            keyboardType="default"
                            secureTextEntry={true}
                        />
                    </View>
                    <View style={{ flex: 1 }}>
                        <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                            Confirm Password
                        </SSTextMed>
                        <Input
                            onChangeText={onChangeConfirmPassword}
                            keyboardType="default"
                            secureTextEntry={true}
                        />
                    </View>
                </View>

                <View style={{
                    padding: '8px',
                    width: "100%",
                    borderTopColor: "#e8e2ce",
                    borderTopWidth: "1px",
                }}>
                    <UpdateButton updateFunction={() => { Linking.openURL(`${navigateTo}`) }} >
                        <SSTextMed color="#413412" fontSize="15px">
                            Save
                        </SSTextMed>
                    </UpdateButton>
                </View>
            </CardTemplate>
        </>
    )
}
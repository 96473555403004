import { StyleSheet, Text, View, Image, useWindowDimensions } from 'react-native'
import React, { useState, useRef, useEffect, useLayoutEffect } from 'react'
import styled, { css } from '@emotion/native'
import { TouchableOpacity } from 'react-native-web';
import { useHover } from 'react-native-web-hooks';
import { useNavigation } from '@react-navigation/native';
import { httpsCallable } from '@firebase/functions';
import { storage, db, functions, auth } from '../firebaseConfig';
import { ref, listAll, getDownloadURL, getMetadata } from 'firebase/storage'
import { UserAuth } from '../context/AuthContext';
import { orderBy, doc, getDoc } from 'firebase/firestore'
import { underW1025, underW821, underW769, underW500, underW376underH668, standardStyles } from '../styles/VideoBakingScreen.styles';
import {
  Footer,
  Header,
  IvyPresto,
  SSText,
  SSTextMed,
  SSTextBold,
} from "../constants/GlobalElements";

const textColor = "#49381f"
const Container = styled.View`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #f4ecd9;
    min-height: 100vh;
`
const UIContainer = styled.View`
    width: 100%;
    align-items: center;
    height: calc(100vh - 190px)
`
const SignUpCore = styled.View`
    background: #F7F4E9;
    width: 60%;
    min-height: 400px;
    border-radius: 28px;
    padding: 20px;
    padding-right: 5px;
    flex-direction: row;
    border: 1px solid rgba(226, 222, 204, .7);
    box-shadow: 0 5px 30px rgba(181, 150, 104, .15);
`
const Column = styled.View`
    display: flex;
    justify-content: center;
    // border: 1px dashed yellow;
    flex: 1;
    padding: 45px;
`
const Line = styled.View`
    border-top-color: rgba(226, 222, 204, .7);
    border-top-width: 1px;
    margin-top: 19px;
    margin-bottom: 19px;
    width: 330px;
`
const ButtonBox = styled.View`
    align-items: start;
    justify-content: start;
    height: 60px;
    width: 100%;
    flex-direction: row;
    margin-top: 20px;
    margin-bottom: 20px;
    space-between: 10px;
`
const ConfirmButton = styled.Pressable`
    display: flex;
    border-radius: 999px;
    padding: 5px;
    width: max-content;
    height: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s ease;
`;

const ConfirmationButton = ({ videoUrlArray, pictureUrlArray, metaDataArray, questionIndexArray, uid, videoId, questions, navigation, styles, email, multipleVideoId }) => {
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);


  console.log(uid)
  const render = () => {
    const shotstack = httpsCallable(functions, "shotStackFunctions-shotstack");
    shotstack({
      videoUrlArray: videoUrlArray,
      metaDataArray: metaDataArray,
      pictureUrlArray: pictureUrlArray,
      questionIndexArray: questionIndexArray,
      videoId: videoId,
      multipleVideoId: multipleVideoId,
      questions: questions,
      uid: uid,
      email: email
    }).then(result => {
      console.log(result);
    }).catch(error => {
      console.log(error);
    });

    navigation.navigate("UserDashboard" , {dashboardLocation: 3});

    // const shotstack = httpsCallable(functions, "shotStackFunctions-shotstack");
    // shotstack({
    //   urls: videoUrlArray,
    //   duration: metaDataArray,
    //   user: uid,
    //   videoId: videoId,
    //   questions: questions
    // })
    //   .then((result) => {
    //     console.log(result.data);
    //     navigation.navigate('UserDashboard');
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    // navigation.navigate('UserDashboard');
  };

  return (
    <ConfirmButton
      onPress={() => {
        render();
      }}
      ref={ref}
      style={[{ background: "#91B207" }, isHovered && { background: "#8BAB07", transform: "translateY(-1.5px)" }]}>
      <View style={{ paddingRight: "10px", paddingLeft: "20px" }}>
        <SSTextBold color={isHovered ? "#F7F5EA" : "#F7F5EA"}>Finished!</SSTextBold>
      </View>
      <View
        style={[{ background: "#9ABA0B", borderRadius: "999px", height: "33px", width: "33px", display: "flex", alignItems: "center", justifyContent: "center", transitionProperty: "background", transitionDuration: ".2s", transitionTimingFunction: "ease" },
        isHovered && { background: "#96B20A" }]}>
        <Image
          resizeMode="contain"
          source={require('../../assets/darkgreenarrow.png')}
          style={{ height: '15px', width: '15px' }} />
      </View>
    </ConfirmButton>
  )
}


const VideoBakingScreen = ({ route, navigation }) => {

  // TURN ON EVERYTHING FROM HERE...
  const { user } = UserAuth();
  const uid = route.params.uid ?? 777;
  const email = route.params.email ?? "will@jubilantdigital.com";
  console.log("THIS IS THE EMAIL" + email);
  const videoId = route.params.videoId ?? 99;
  const multipleVideoId = route.params.multipleVideoId ?? 99;
  console.log(route.params.questions[0].question);
  console.log("THIS IS THE ID" + route.params.questions[0].id);

  // storage ref to the user's video folder that order by name
  const storageRef = ref(storage, "videos/" + uid + "/" + videoId);
  const dbRef = doc(db, "users/" + uid + "/videos/" + videoId);
  const imageRef = ref(storage, 'images/' + uid + '/' + videoId);
  const listRef = listAll(storageRef, { orderBy: orderBy("Name") });

  const [videoUrl, setVideoUrl] = React.useState([]);
  const [metaData, setMetaData] = React.useState([]);
  const [questionIndex, setQuestionIndex] = React.useState([]);
  const [pictureUrl, setPictureUrl] = React.useState([]);
  const [doneUrl, setDoneUrl] = React.useState("");

  useEffect(() => {
    getDoc(dbRef)
      .then((doc) => {
        if (doc.exists()) {
          console.log("Document data:", doc.data());
          setVideoUrl(doc.data().url);
          setMetaData(doc.data().durationInSeconds);
          setQuestionIndex(doc.data().questionIndex);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
      
    listAll(imageRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setPictureUrl((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  const videoUrlArray = videoUrl.map((url) => {
    return url;
  });

  console.log(questionIndex);

  const questionIndexArray = questionIndex.map((index) => {
    return index;
  });

  const metaDataArray = metaData.map((metaData) => {
    return parseFloat(metaData * 30);
  });

  const pictureUrlArray = pictureUrl.map((url) => {
    return url;
  });

  // const render = () => {
  //   const shotstack = httpsCallable(functions, "shotStackFunctions-shotstack");
  //   shotstack({
  //     urls: videoUrlArray,
  //     duration: metaDataArray,
  //     user: uid,
  //     videoId: videoId,
  //     questions: route.params.questions
  //   })
  //     .then((result) => {
  //       console.log(result.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  // render();
  const [styles, setStyles] = useState(standardStyles)
  const { width, height } = useWindowDimensions()

  useEffect(() => {
    setStyles(standardStyles)
    // IPHONE SE
    if (width < 376 && height < 668) {
      setStyles(underW376underH668)
      console.log('under width of 376 and a height of 668')
    }
    // MOST MOBILE DEVICES
    else if (width < 500) {
      setStyles(underW500)
      console.log('under width of 500')
    }
    // IPAD/IPAD MINI PORTRAIT
    else if (width < 769) {
      setStyles(underW769)
    }
    else if (width < 821) {
      setStyles(underW821)
    }
    // IPAD PRO PORTRAIT
    else if (width < 1025) {
      setStyles(underW1025)
    }
  }, [width, height])

  return (
    <Container>
      <UIContainer
        nativeID='UIContainer'
        style={[styles.UIContainer]}
      >
        <Header width={width} height={height} />
        <View
          nativeID='wrapper'
          style={[{ minWidth: "55%", justifyContent: "center", alignItems: "center", height: "100%" }, styles.wrapper]}>
          <SignUpCore
            nativeID='signUpCore'
            style={[styles.signUpCore]}>
            {/* FIRST COLUMN */}
            <Column
              nativeID='firstColumn'
              style={[{
                borderRightColor: "rgba(226, 222, 204, .7)",
                borderRightWidth: "1px",
                flexBasis: "60%",
                justifyContent: "center",
                paddingRight: '7%'
              },
              styles.firstColumn
              ]}>
              <View style={{ justifyContent: "center", height: "60%", paddingRight: "20px" }}>

                <IvyPresto
                  fontSize={width < 500 || width < 821 ? '35px' : '45px'}
                  color={textColor}>
                  Ready to finish?
                </IvyPresto>


                <Line style={[styles.line]} />
                <SSText color={textColor} fontSize={width < 500 || width < 769 ? '17px' : '19px'} lineHeight="130%">
                  Your memory is ready to be built! If you are satisfied with all of your answers, press the "Finished" button to start the render process and take you back to the dashboard. An email will be sent to {email} once finished.
                </SSText>

                <ButtonBox
                  style={[styles.buttonBox]}
                >
                  <ConfirmationButton 
                  navigation={navigation} 
                  videoUrlArray={videoUrlArray} 
                  questionIndexArray={questionIndexArray} 
                  pictureUrlArray={pictureUrlArray} 
                  metaDataArray={metaDataArray} 
                  uid={uid}
                  email={email}
                  videoId={videoId} 
                  multipleVideoId={multipleVideoId}
                  questions={route.params.questions}
                  />
                </ButtonBox>
                {/* <button onClick={dashboard} style={{ backgroundColor: "#49381f", color: "white", padding: "10px", borderRadius: "5px", border: "none", marginTop: "20px" }}>Go to Dashboard</button> */}
              </View>

            </Column>
            <Column
              nativeID='secondColumn'
              style={[{ flexBasis: "40%", alignItems: "center" }, styles.secondColumn]}>
              <Image
                resizeMode="contain"
                source={require('../../assets/videobaking.png')}
                style={[{ height: '230px', width: '230px' }, styles.image]} />

            </Column>
          </SignUpCore>
        </View>
      </UIContainer>
      <Footer width={width} height={height} />
    </Container>
  )
}

export default VideoBakingScreen;
import { StyleSheet, Text, View, Image, TextInput, useWindowDimensions } from "react-native";
import React, { useState, useRef } from "react";
import styled, { css } from "@emotion/native";
import {
  Footer,
  Header,
  IvyPresto,
  SSTextMed,
  SSTextBold,
} from "../constants/GlobalElements";
import { useHover } from "react-native-web-hooks";
import { UserAuth } from "../context/AuthContext";
const textColor = "#49381F";
const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #F4ECD9;
  min-height: 100vh;
`;
const UIContainer = styled.View`
  width: 100%;
  align-items: center;
  height: calc(100vh - 190px);
`;
const SignInCore = styled.View`
    background: #F7F4E9;
    width: 100%;
    min-height: 400px;
    border-radius: 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 65px;
    padding-top: 45px;
    border: 1px solid rgba(226, 222, 204, .7);
    box-shadow: 0 5px 32px rgba(181, 150, 104, .1);
`
const ButtonBox = styled.View`
    align-items: end;
    justify-content: center;
    height: 80px;
    width: 100%;
`
const ConfirmButton = styled.Pressable`
  display: flex;
  border-radius: 999px;
  padding: 5px;
  width: max-content;
  height: max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
`;
const ButtonTemplate = styled.Pressable`
  display: flex;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  flex: 1;
  height: 45px;
  flex-direction: row;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
`;



const ConfirmationButton = () => {
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <ConfirmButton
      ref={ref}
      style={[{ background: "#91b207" }, isHovered && { background: "#8bab07", transform: "translateY(-1.5px)" }]}>
      <View style={{ paddingRight: "10px", paddingLeft: "20px" }}>
        <SSTextBold color={isHovered ? "#f7f5ea" : "#f7f5ea"}>Confirm</SSTextBold>
      </View>
      <View
        style={[{ background: "#9aba0b", borderRadius: "999px", height: "33px", width: "33px", display: "flex", alignItems: "center", justifyContent: "center", transitionProperty: "background", transitionDuration: ".2s", transitionTimingFunction: "ease" },
        isHovered && { background: "#96b20a" }]}>
        <Image
          resizeMode="contain"
          source={require('../../assets/darkgreenarrow.png')}
          style={{ height: '15px', width: '15px' }} />
      </View>
    </ConfirmButton>
  )
}

const AppleButton = () => {
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <ButtonTemplate
      ref={ref}
      style={[
        { background: "#1c1c1c" },
        isHovered && { background: "#000", transform: "translateY(-1.5px)" },
      ]}
    >
      <Image
        resizeMode="contain"
        source={require("../../assets/apple-logo.png")}
        style={{
          height: "25px",
          width: "25px",
          transform: "translateY(-2px)",
        }}
      />
      <View style={{ paddingRight: "10px", paddingLeft: "10px" }}>
        <SSTextMed color="white">Sign in with Apple ID</SSTextMed>
      </View>
    </ButtonTemplate>
  );
};


const GoogleButton = () => {
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <ButtonTemplate
      ref={ref}
      style={[
        { background: "#fffef9" },
        isHovered && { transform: "translateY(-1.5px)" },
      ]}
    >
      <Image
        resizeMode="contain"
        source={require("../../assets/google.png")}
        style={{
          height: "25px",
          width: "25px",
          transform: "translateY(0px)",
        }}
      />
      <View style={{ paddingRight: "10px", paddingLeft: "10px" }}>
        <SSTextMed color="rgba(0,0,0,.7)">Sign in with Google</SSTextMed>
      </View>
    </ButtonTemplate>
  );
};
const Line = styled.View`
    border-top-color: rgba(226, 222, 204, 0.7);
    border-top-width: 1px;
    margin-top: 19px;
    width: 100%;
  `;
const Input = styled.TextInput`
    height: 45px;
    width: 100%;
    border: 1px solid rgba(226, 222, 204, 1);
    border-radius: 7px;
    margin-top: 7px;
    background: #FCFAF2;
    padding-left: 12px;
    color: rgb(117, 99, 73);
  `;
//   const signInScreen = () => {
// Handlers
const SignUpConfirmationScreen = () => {
  const { width, height } = useWindowDimensions()
  const [password, onChangePass] = useState("");
  const [email, onChangeEmail] = useState("");
  const { googleSignIn, emailLogin, emailSignUp, logOut } = UserAuth();

  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      //   navigate("Home");
    } catch (err) {
      console.log(err);
    }
  };

  const handleSignOut = () => {
    try {
      logOut();
      //    navigate("Login")
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmailSignUp = () => {
    try {
      emailSignUp(email, password);
      //    navigate("Home");
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmailLogin = async () => {
    try {
      await emailLogin(email, password);
      //    navigate("Home");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <UIContainer>
        <Header width={width} height={height} />
        <View style={{ width: "500px", justifyContent: "center", height: "100%" }}>
          <SignInCore>
            <View style={{ justifyContent: "center", minHeight: "50%", width: "100%" }}>
              <IvyPresto fontSize="40px" color={textColor}>
                Sign In
              </IvyPresto>
              <Line />
              <View style={{ paddingTop: "25px" }}>
                <View style={{ marginBottom: "15px" }}>
                  <View style={{ flexDirection: "row" }}>
                    <SSTextMed color="#756349" fontSize="14px">
                      E-mail
                    </SSTextMed>
                    <SSTextMed color="tomato" fontSize="14px">
                      *
                    </SSTextMed>
                  </View>
                  <Input
                    onChangeText={onChangeEmail}
                    keyboardType="email-address"
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <SSTextMed color="#756349" fontSize="14px">
                    Password
                  </SSTextMed>
                  <SSTextMed color="tomato" fontSize="14px">
                    *
                  </SSTextMed>
                </View>
                <Input
                  onChangeText={onChangePass}
                  keyboardType="default"
                  secureTextEntry={true}
                />
              </View>
            </View>
            <ButtonBox>
              <ConfirmationButton />
            </ButtonBox>
            <View style={{ flexDirection: "row", gap: "15px", justifyContent: "center", alignItems: "cener", width: "100%", transform: "translateY(-7px)" }}>
              <Line style={{ marginTop: "9px", flex: "1" }} />
              <SSTextMed color="#756349" fontSize="14px">
                Or
              </SSTextMed>
              <Line style={{ marginTop: "9px", flex: "1" }} />
            </View>

            <View style={{ height: "60px", flexDirection: "row", gap: "15px", width: "100%", transform: "translateY(10px)" }}>
              {/* <AppleButton /> */}
              <GoogleButton />
            </View>
          </SignInCore>
        </View>
      </UIContainer>
      <Footer width={width} height={height} />
    </Container>
  );
};

export default SignUpConfirmationScreen;
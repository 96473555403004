import React from 'react'
import { ActivityIndicator,View } from 'react-native'
// import { SSTextMed } from '../constants/GlobalElements'

const Loader = () => (
    <View style={{flexDirection: "row", gap: "20px"}}>
        <ActivityIndicator size="large" color="#8BAB06" />
    </View>
);

export default Loader;
import { useContext, createContext, useState, useEffect } from "react";
import { 
    GoogleAuthProvider,
    EmailAuthProvider,
    signInWithPopup,
    signInWithRedirect,
    signOut,
    onAuthStateChanged,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    linkWithCredential,
    sendEmailVerification
} from "firebase/auth";
import { auth } from "../firebaseConfig.js";
import {addFirstLastName} from "./UserFunctions";
import { NavigationContainer } from "@react-navigation/native";

// Create Context
const AuthContext = createContext();

// Export AuthContextProvider
export const AuthContextProvider = ({children}) =>{

  // Use state to automatically update the user to the current user. 
  const [user, setUser] = useState(() => {
    const user = auth.currentUser;
    return user;
  });
      

  useEffect(() => {
    // Whenever the authStateChanged callback is called then hit the setUser state to update the users current state.
    auth.onAuthStateChanged(firebaseUser => {
      console.log('Auth changed AuthContext.js');
      console.log(firebaseUser);
      setUser(firebaseUser);

    });
  }, [])
    

  // Signup with email
  const emailSignUp = (email,password, firstName, lastName) => {
    console.log("Trying to login with" + email + "..." + password);
      createUserWithEmailAndPassword(auth, email, password)
      .then(userCredentials => {
      
        // Signed in
        const user = userCredentials.user;
        console.log('Registered with:', user.email);
        sendEmailVerification(auth.currentUser)
        .then(() => {
          alert('Email verification sent!');
        });

        if (firstName && lastName){
          const user2 = auth.currentUser;
          console.log('firstname & lastName Present'+ user);
          // Both first and last names are provided
          // user.uid
          // TODO: Write Firebase Function to handle their database information update.
          addFirstLastName(user2,firstName,lastName);
          
        } else if(firstName){
          // Only First Name Provided
        } else if(lastName){
          // Only Last Name Provided

        } else {
         console.log('No Name Provided');
        }

      })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message
        });
    };

    const emailLogin = async (email, password) => {
        try {
          await signInWithEmailAndPassword(auth, email, password);
          
        } catch (err) {
          console.error(err);
          alert(err.message);
        }
      };
      

    // Signup with Google
    const googleSignIn = () => {
        const provider = new GoogleAuthProvider();
        signInWithPopup(auth, provider).then(() => {
        }).catch((error) => {
          console.log(error);
        });
    }

    //  Sign out of the account
    const logOut = () => {
        signOut(auth);
        navigation.navigate("GetStarted");
    };
    

    return (
        <AuthContext.Provider value={{user,googleSignIn, logOut, emailSignUp,emailLogin}}>
            {children}
        </AuthContext.Provider>
    )
}

export const UserAuth = () => {
    return useContext(AuthContext);
}

import { Text, View, Pressable, Image } from 'react-native'
import React, { useEffect, useState, useRef } from 'react'
import { useHover } from 'react-native-web-hooks';
import styled, { css } from '@emotion/native'
import Animated, {
    withSpring,
    useAnimatedStyle,
    useSharedValue,
    withTiming,
    withDelay,
    FadeIn,
    Layout
} from 'react-native-reanimated';
import { SSText, SSTextBold } from './GlobalElements'

const NavContent = styled.View`
    transition: all .25s ease;
    width: max-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 12px;
    flex: 1;
    cursor: pointer;
`
export const Container = styled.View`
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    min-height: calc(100vh + 90px);
    background: #f4ecd9;
`;
export const MainBody = styled.View`
    display: flex;
    width: 80%;
    min-height: 70vh;
    flex-direction: column;
`;

export const TextWrapper = styled.View`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;
export const Column = styled.View`
    display: flex;
    width: 45%;
    height: 100%;
    justify-content: center;
`;
export const LowerBody = styled.View``;
export const NavigationSection = styled.View`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;
export const InterviewSection = styled.View`
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 100%;
    padding-left: 0.5%;
    padding-right: 0.5%;
    justify-content: space-between;
    margin-top: 40px;
`;
export const ButtonArea = styled.View`
    padding-top: 12px;
    flex-direction: row;
    justify-content: flex-start;
    width: 600px;
    flex-wrap: wrap;
`;
export const ImageContainer = styled.View`
    z-index: 777;
    position: relative;
`;
export const VideoContainer = styled.View`
    z-index: 777;
    position: relative;
`;

export const RedDot = styled.View`
    position: absolute;
    height: 15px;
    width: 15px;
    background: #e47434;
    border-radius: 999px;
    top: 15px;
    right: 15px;
    z-index: 999;
`;

export const InsertPhotoPressable = styled.Pressable`
    z-index: 998
`
const Ticker = styled.View`
    width: 100%;
    height: 100%;
    z-index: 999;
    justify-content: center;
    align-items: center;
    position: absolute;
`
const Gif = styled.Image`
`
export const GifComponent = ({ source, key, width, height }) => {
    return (
        <Ticker
            nativeID='ticker'
            key={key}
        >
            <Gif
                nativeID='gifImage'
                style={[{
                    width: 200,
                    height: 200,
                    opacity: ".6",
                    resizeMode: 'contain'
                },
                width < 500 && {
                    height: 150,
                },
                height < 415 && {
                    height: 100,
                }]}
                source={{ uri: source }}
            />
        </Ticker>
    );
};
export const NavButtonLeft = ({ height, width }) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <NavContent
            ref={ref}
            style={[{ flex: "1", textAlign: "left", opacity: ".6" },
            isHovered && { background: "#ede5d3", opacity: "1" }]}
        >
            <Image
                resizeMode="contain"
                source={require('../../assets/next.png')}
                style={[{ 
                    height: 10, 
                    width: 10, 
                    marginRight: 10,
                    transform: 'rotate(180deg)'
                    },
                    height < 415 && {
                        height: 7,
                        width: 7
                    }]}  />
            <SSText
                color="#664d2a"
                fontSize={height < 415 ? '12px' : '15px'}>
                Previous Question
            </SSText>
        </NavContent>
    )
}
export const NavButtonRight = ({ height, width }) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <NavContent
            ref={ref}
            style={[{ flex: "1", textAlign: "right", opacity: ".6" },
            isHovered && { background: "#ede5d3", opacity: "1" }]}
        >
            <SSText
                color="#664d2a"
                fontSize={height < 415 ? '12px' : '15px'}>
                Skip Question
            </SSText>
            <Image
                resizeMode="contain"
                source={require('../../assets/next.png')}
                style={[{ 
                    height: '10px', 
                    width: '10px', 
                    marginLeft: "10px" 
                    },
                    height < 415 && {
                        height: 7,
                        width: 7
                    }]} />

        </NavContent>
    )
}

export const WordAnimator = ({ word, delay, width, height }) => {
    const offset = useSharedValue(15);
    const visibility = useSharedValue(0);

    const animate = () => {
        offset.value = 0;
        visibility.value = 1;
    }
    const animationStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    translateY: withDelay(delay, withSpring(offset.value, {
                        damping: 200,
                        stiffness: 110,
                    })),
                },
            ],
            opacity: withDelay(delay, withTiming(visibility.value, {
                duration: 900
            }))
        };
    });

    useEffect(() => {
        animate();
    }, [])

    return (
        <Animated.View style={[animationStyles]}>
            <SSTextBold
                color="#382203"
                fontSize={height < 415 ? '20px' : width < 400 ? '25px' : width < 769 ? '30px' : (width < 1181 && height < 821) ? '25px' : '35px'}
                lineHeight={width < 500 ? '120%' : '115%'}>
                {word}{` `}
            </SSTextBold>
        </Animated.View>
    )
}

// BUTTONS FOR UI ON INTERVIEW PAGE
const ButtonTemplate = styled.View`
    display: flex;
    border-radius: 999px;
    padding: 5px;
    width: max-content;
    height: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all .2s ease;
    cursor: pointer;
`
export const StartRecordingButton = ({ width, height }) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <ButtonTemplate
            ref={ref}
            style={[{
                background: "#E3DAC7"
            },
            isHovered && {
                background: "#e0d7c5",
                transform: "translateY(-1.5px)"
            },
            width < 500 && {
                height: 38,
                padding: 4
            },
            height < 415 && {
                height: 33,
                padding: 3
            }]}>
            <View
                style={[{
                    paddingRight: "10px",
                    paddingLeft: "20px"
                },
                width < 500 || height < 415 && {
                    paddingRight: '7px',
                    paddingLeft: '16px'
                }]}>
                <SSTextBold color={isHovered ? "#3a2a1d" : "#5A4736"} fontSize={height < 415 ? '11px' : width < 500 ? '13px' : '15px'}>Start Recording</SSTextBold>
            </View>
            <View
                style={[{
                    borderRadius: "999px",
                    height: "33px",
                    width: "33px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F2EADA",
                    transitionProperty: "background",
                    transitionDuration: ".2s",
                    transitionTimingFunction: "ease"
                },
                isHovered && {
                    background: "#ede5d5"
                },
                width < 500 && {
                    height: 30,
                    width: 30
                },
                height < 415 && {
                    height: 27,
                    width: 27
                }]}>
                <View style={[{
                    borderRadius: "999px",
                    height: "15px",
                    width: "15px",
                    background: "#E47434"
                },
                width < 500 && {
                    height: "13px",
                    width: "13px",
                },
                height < 415 && {
                    height: 13,
                    width: 13
                }]} />
            </View>
        </ButtonTemplate>
    )
}

export const FinishRecordingButton = ({ width, height }) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <ButtonTemplate
            ref={ref}
            style={[{
                background: "#E3DAC7"
            },
            isHovered && {
                background: "#e0d7c5",
                transform: "translateY(-1.5px)"
            },
            width < 500 && {
                height: '38px',
                padding: '4px'
            },
            height < 415 && {
                height: 33,
                padding: 3
            }]}>
            <View
                style={[{
                    paddingRight: "10px",
                    paddingLeft: "20px"
                },
                width < 500 || height < 415 && {
                    paddingRight: '7px',
                    paddingLeft: '16px'
                }]}>
                <SSTextBold
                    color={isHovered ? "#3a2a1d" : "#5A4736"}
                    fontSize={height < 415 ? '11px' : width < 500 ? '13px' : '15px'}>
                    Finish Recording
                </SSTextBold>
            </View>
            <View
                style={[{
                    borderRadius: "999px",
                    height: "33px",
                    width: "33px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F2EADA",
                    transitionProperty: "background",
                    transitionDuration: ".2s",
                    transitionTimingFunction: "ease"
                },
                isHovered && {
                    background: "#ede5d5"
                },
                width < 500 && {
                    height: 30,
                    width: 30
                },
                height < 415 && {
                    height: 27,
                    width: 27
                }]}>
                <View
                    style={[{
                        borderRadius: 4,
                        height: 15,
                        width: 15,
                        background: "#F2BD00"
                    },
                    width < 500 || height < 415 && {
                        height: 13,
                        width: 13
                    }]} />
            </View>
        </ButtonTemplate>
    )
}

export const SubmitRecordingButton = ({ width, height }) => {
    const submitPath = require('../../assets/upload.png');
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <ButtonTemplate
            ref={ref}
            style={[{
                background: "#92b22a"
            },
            isHovered && {
                background: "#8ead29",
                transform: "translateY(-1.5px)"
            },
            width < 500 && {
                height: '38px',
                padding: '4px'
            },
            height < 415 && {
                height: 33,
                padding: 3
            }]}>
            <View
                style={[{
                    paddingRight: "10px",
                    paddingLeft: "20px"
                },
                width < 500 || height < 415 && {
                    paddingRight: '7px',
                    paddingLeft: '16px'
                }]}>
                <SSTextBold
                    color={isHovered ? "#f7f5ea" : "#f5f8d3"}
                    fontSize={height < 415 ? '11px' : width < 500 ? '13px' : '15px'}>
                    {width < 500 || height < 415 || (width < 1025 && height < 769 && width > 500) ? 'Submit' : 'Submit Answer'}
                </SSTextBold>
            </View>
            <View
                style={[{
                    background: "#a2c635",
                    borderRadius: "999px",
                    height: "33px",
                    width: "33px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transitionProperty: "background",
                    transitionDuration: ".2s",
                    transitionTimingFunction: "ease"
                },
                isHovered && {
                    background: "#9ebf31"
                },
                width < 500 && {
                    height: '30px',
                    width: '30px'
                },
                height < 415 && {
                    height: 27,
                    width: 27
                }]}>
                <Image
                    resizeMode="contain"
                    source={submitPath}
                    style={[{
                        height: 17,
                        width: 17,
                        transform: "translate(.5px, -1.5px)"
                    },
                    width < 500 && {
                        height: 15,
                        width: 15
                    },
                    height < 414 && {
                        height: 14,
                        width: 14,
                        transform: "translate(.5px, -1px)"
                    }
                    ]} />
            </View>
        </ButtonTemplate>
    )
}

export const PreviewButton = ({ width, height }) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <ButtonTemplate
            ref={ref}
            style={[{
                background: "#E3DAC7",
                marginLeft: 5
            },
            isHovered && {
                background: "#e0d7c5",
                transform: "translateY(-1.5px)"
            },
            width < 500 && {
                height: '38px',
                padding: '4px'
            },
            height < 415 && {
                height: 33,
                padding: 3
            }]}>
            <View
                style={[{
                    paddingRight: "10px",
                    paddingLeft: "20px"
                },
                width < 500 || height < 415 && {
                    paddingRight: '7px',
                    paddingLeft: '16px'
                }]}>
                <SSTextBold
                    color={isHovered ? "#3a2a1d" : "#5A4736"}
                    fontSize={height < 415 ? '11px' : width < 500 ? '13px' : '15px'}>
                    Preview
                </SSTextBold>
            </View>
            <View
                style={[{
                    borderRadius: "999px",
                    height: "33px",
                    width: "33px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F2EADA",
                    transitionProperty: "background",
                    transitionDuration: ".2s",
                    transitionTimingFunction: "ease"
                },
                isHovered && {
                    background: "#ede5d5"
                },
                width < 500 && {
                    height: '30px',
                    width: '30px'
                },
                height < 415 && {
                    height: 27,
                    width: 27
                }]}>
                <Image
                    resizeMode="contain"
                    source={require('../../assets/rightfacingtriangle.png')}
                    style={[{
                        height: '15px',
                        width: '15px',
                        transform: "translateX(1px)"
                    },
                    width < 500 || height < 415 && {
                        height: '13px',
                        width: '13px',

                    }]} />
            </View>
        </ButtonTemplate>
    )
}

export const DiscardButton = ({ width, height }) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <ButtonTemplate
            ref={ref}
            style={[{
                background: "#E3DAC7",
                marginLeft: 5
            },
            isHovered && {
                background: "#e0d7c5",
                transform: "translateY(-1.5px)"
            },
            width < 500 && {
                height: '38px',
                padding: '4px'
            },
            height < 415 && {
                height: 33,
                padding: 3
            }]}>
            <View
                style={[{
                    paddingRight: "10px",
                    paddingLeft: "20px"
                },
                width < 500 || height < 415 && {
                    paddingRight: '7px',
                    paddingLeft: '16px'
                }]}>
                <SSTextBold
                    color={isHovered ? "#3a2a1d" : "#5A4736"}
                    fontSize={height < 415 ? '11px' : width < 500 ? '13px' : '15px'}>
                    Discard
                </SSTextBold>
            </View>
            <View
                style={[{
                    borderRadius: "999px",
                    height: "33px",
                    width: "33px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F2EADA",
                    transitionProperty: "background",
                    transitionDuration: ".2s",
                    transitionTimingFunction: "ease"
                },
                isHovered && {
                    background: "#ede5d5"
                },
                width < 500 && {
                    height: '30px',
                    width: '30px'
                },
                height < 415 && {
                    height: 27,
                    width: 27
                }]}>
                <Image
                    resizeMode="contain"
                    source={require('../../assets/clear.png')}
                    style={[{
                        height: '14px',
                        width: '14px',
                    },
                    width < 500 || height < 415 && {
                        height: '12px',
                        width: '12px'
                    }]} />
            </View>
        </ButtonTemplate>
    )
}

export const SubmittedAlert = ({ width, height }) => {
    return (
        <ButtonTemplate
            style={[{
                backgroundColor: "#E3DAC7",
                borderRadius: "8px",
                cursor: "default"
            },
            width < 500 && {
                height: '38px',
                padding: '4px'
            },
            height < 415 && {
                height: 33,
                padding: 3
            }]}>
            <View
                style={[{
                    paddingRight: "10px",
                    paddingLeft: "20px"
                },
                width < 500 || height < 415 && {
                    paddingRight: '9px',
                    paddingLeft: '10px'
                }]}>
                <SSTextBold
                    color="#5A4736"
                    fontSize={height < 415 ? '11px' : width < 500 ? '13px' : '15px'}>
                    Submitted!
                </SSTextBold>
            </View>
            <View
                style={[{
                    borderRadius: 5,
                    height: 33,
                    width: 33,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "#F2EADA"
                },
                width < 500 && {
                    height: 30,
                    width: 30
                },
                height < 415 && {
                    height: 27,
                    width: 27
                }]}>
                <Image
                    resizeMode="contain"
                    source={require('../../assets/sparklycheck.png')}
                    style={[{
                        height: 21,
                        width: 21,
                        transform: "translateY(0px)"
                    },
                    width < 500 || height < 415 && {
                        height: 18,
                        width: 18
                    }
                    ]} />
            </View>
        </ButtonTemplate>
    )
}
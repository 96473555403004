import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Login from "./src/screens/LoginScreen";
import Home from "./src/screens/HomeScreen";
import UserDashboard from "./src/screens/UserDashboardScreen";
import InterviewInterface from "./src/screens/InterviewInterfaceScreen";
import AddProducts from "./src/components/AddProducts";
// import AdminDashboard from './src/screens/AdminDashboardScreen';
import SignUp from "./src/screens/SignUpScreen";
import GetStarted from "./src/screens/GetStartedScreen";
import TryIt from "./src/screens/TryItScreen";
import VideoBaking from "./src/screens/VideoBakingScreen";
import WatchInterview from "./src/screens/WatchInterviewScreen";
import SignIn from "./src/screens/SignInScreen";
import Register from "./src/screens/RegisterScreen";
import SignUpLogin from "./src/screens/SignUpLoginScreen";
import SignUpRegistration from "./src/screens/SignUpRegistrationScreen";
import SignUpConfirmation from "./src/screens/SignUpConfirmationScreen";
import SignUpGifteeDetails from "./src/screens/SignUpGifteeDetailsScreen";
import Store from "./src/screens/StoreScreen"
import FinishedInterview from "./src/screens/FinishedInterviewScreen";
import { AuthContextProvider } from "./src/context/AuthContext";
import React, { useEffect } from "react";

const Stack = createNativeStackNavigator();

export default function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);
  return (
    <AuthContextProvider>
      <NavigationContainer
        linking={{
          prefixes: ["https://app.storycorder.com/"],
          config: {
            screens: {
              Home: "Home",
              SignIn: "sign-in",
              Register: "register",
              UserDashboard: "dashboard",
              SignUpStepTwo: "sign-up-step-two",
              InterviewInterface: "interview-interface",
              SignUp: "sign-up",
              AddProducts: "add-products",
              GetStarted: "",
              TryIt: "try-it",
              VideoBaking: "video-baking",
              WatchInterview: "watch-interview",
              Store: 'store',
              FinishedInterview: "finished-interview"

            },
          },
        }}
      >
        <Stack.Navigator
          screenOptions={{
            headerShown: false,
          }}
        >
          <Stack.Screen name="UserDashboard" component={UserDashboard} />
          <Stack.Screen name="SignIn" component={SignIn} />
          <Stack.Screen name="Register" component={Register} />
          <Stack.Screen name="InterviewInterface" component={InterviewInterface} />
          <Stack.Screen name="Home" component={Home} />
          <Stack.Screen name="SignUp" component={SignUp} />
          <Stack.Screen name="SignUpRegistration" component={SignUpRegistration} />
          <Stack.Screen name="SignUpGifteeDetails" component={SignUpGifteeDetails} />
          <Stack.Screen name="SignUpLogin" component={SignUpLogin} />
          <Stack.Screen name="SignUpConfirmation" component={SignUpConfirmation} />
          <Stack.Screen name="Store" component={Store} />
          <Stack.Screen name="AddProducts" component={AddProducts} />
          <Stack.Screen name="GetStarted" component={GetStarted} />
          <Stack.Screen name="TryIt" component={TryIt} />
          <Stack.Screen name="VideoBaking" component={VideoBaking} />
          <Stack.Screen name="WatchInterview" component={WatchInterview} />
          <Stack.Screen name="FinishedInterview" component={FinishedInterview} />
        </Stack.Navigator>
      </NavigationContainer>
    </AuthContextProvider>
  );
}

import { View, Image, TextInput, useWindowDimensions } from "react-native";
import React, { useState, useRef, useEffect, useSyncExternalStore } from "react";
import styled, { css } from "@emotion/native";
import {
  Footer,
  Header,
  IvyPresto,
  SSTextMed,
  SSTextBold,
} from "../constants/GlobalElements";
import { useHover } from "react-native-web-hooks";
import { UserAuth } from "../context/AuthContext";
import { useNavigation } from '@react-navigation/native';

const textColor = "#49381F";

const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #F4ECD9;
  min-height: 100vh;
`;
const UIContainer = styled.View`
  width: 100%;
  align-items: center;
  height: calc(100vh - 190px);
`;
const SignInCore = styled.View`
    background: #F7F4E9;
    width: 100%;
    min-height: 400px;
    border-radius: 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 65px;
    padding-top: 45px;
    border: 1px solid rgba(226, 222, 204, .7);
    box-shadow: 0 5px 32px rgba(181, 150, 104, .1);
`
const ButtonBox = styled.View`
    align-items: end;
    justify-content: center;
    height: 80px;
    width: 100%;
`
const ConfirmButton = styled.Pressable`
  display: flex;
  border-radius: 999px;
  padding: 5px;
  width: max-content;
  height: max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
`;
const ButtonTemplate = styled.Pressable`
  display: flex;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  flex: 1;
  height: 45px;
  flex-direction: row;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
`;

const Line = styled.View`
  border-top-color: rgba(226, 222, 204, 0.7);
  border-top-width: 1px;
  margin-top: 19px;
  width: 100%;
  `
const Input = styled.TextInput`
  height: 45px;
  width: 100%;
  border: 1px solid rgba(226, 222, 204, 1);
  border-radius: 7px;
  margin-top: 7px;
  background: #FCFAF2;
  padding-left: 12px;
  color: rgb(117, 99, 73);
  `

const GoogleButton = ({ width, action }) => {
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <ButtonTemplate
      ref={ref}
      style={[
        {
          background: "white",
          shadowColor: '#B59668',
          shadowOffset: { width: 0, height: 0 },
          shadowOpacity: 0.15,
          shadowRadius: 16,
        },
        isHovered && {
          transform: "translateY(-1px)",
          shadowOffset: { width: 0, height: 3 },
          shadowOpacity: 0.15,
          shadowRadius: 16,
        }, width < 500 && {
          height: '38px'
        }
      ]}
      onPress={action}
    >
      <Image
        resizeMode="contain"
        source={require("../../assets/google.png")}
        style={[{ height: "25px", width: "25px" }, width < 500 && { height: '20px', width: '20px' }]}
      />
      <View style={{ paddingRight: "10px", paddingLeft: "10px" }} >
        <SSTextMed color="rgba(0,0,0,.7)" fontSize={width < 500 ? "13px" : "15px"}>Sign in with Google</SSTextMed>
      </View>
    </ButtonTemplate>
  );
};

const RegisterButton = ({ width, email, password, onButtonPress }) => {
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);
  // const { googleSignIn, emailLogin, emailSignUp, logOut, user } = UserAuth();

  // const handleEmailSignUp = async () => {
  //   try {
  //     emailSignUp(email, password);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  return (
    <ConfirmButton
      onPress={onButtonPress}
      ref={ref}
      style={[{
        background: "#eee3ce",
        borderRadius: 999,
        marginRight: '5px'
      },
      isHovered && {
        background: "#eadfcc",
        transform: "translateY(-1.5px)"
      },
      width < 500 && {
        padding: '4px',
        height: '38px'
      }]}>
      <View style={{ paddingRight: "10px", paddingLeft: "20px" }}>
        <SSTextBold color={isHovered ? "#3a2a1d" : "#5A4736"} fontSize={width < 500 ? "13px" : "15px"}>Register</SSTextBold>
      </View>
      <View
        style={[{
          background: "#F2EADA",
          borderRadius: "999px",
          height: "33px",
          width: "33px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transitionProperty: "background",
          transitionDuration: ".2s",
          transitionTimingFunction: "ease",
        },
        isHovered && {
          background: "#ede5d5"
        },
        width < 500 && {
          height: '30px',
          width: '30px'
        }]}
      >
        <Image
          resizeMode="contain"
          source={require('../../assets/brownrightarrow.png')}
          style={[{ height: "15px", width: "15px" }, width < 500 && { height: '13px', width: '13px' }]} />
      </View>
    </ConfirmButton>
  )
}

const RegisterScreen = ({ route })  => {
  const { width, height } = useWindowDimensions();
  const [password, onChangePass] = useState("");
  const [passwordConfirm, onChangePassConfirm] = useState("");
  const [email, onChangeEmail] = useState("");
  const [passwordMatch, onPasswordMatch] = useState(true);
  const [emailFilled, onEmailFilled] = useState(true);
  const navigation = useNavigation();
  const { googleSignIn, emailLogin, emailSignUp, logOut, user } = UserAuth();

  useEffect(() => {
    if (user) {
      if (route.params) {
        navigation.navigate("Store");
      } else
        navigation.push("UserDashboard");
    }
  }, [user]);

  console.log(user)
  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn()
    } catch (err) {
      console.log(err);
    }
  };

  const handleSignOut = () => {
    try {
      logOut();
      navigation.navigate("UserDashboard");
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmailSignUp = async () => {
    console.log(passwordConfirm)
    if(password !== passwordConfirm || password == '') {
      onEmailFilled(true);
      return onPasswordMatch(false)
    } else if (email == '') {
      onPasswordMatch(true);
      return onEmailFilled(false)
    } else {
      try {
        emailSignUp(email, password);
      } catch (err) {
        console.log(err);
      }
    };
  }

  if (user) {
    if (route.params) {
      navigation.navigate("Store");
    }
  }

  return (
    <Container>
      <UIContainer
        style={[
          height < 415 && {
            height: 'unset',
          }]}>
        <Header width={width} height={height}/>
        <View
          style={[{
            width: "500px",
            justifyContent: "center",
            height: "100%"
          },
          width < 500 && {
            width: '90%'
          },
          height < 415 && {
            height: 'unset',
            marginTop: 20,
            marginBottom: 20
          }]}>
          <SignInCore
            style={[
              width < 500 && {
                height: 'unset',
                marginTop: '-3%',
                padding: '10px',
                paddingTop: '25px',
                paddingBottom: '28px',
                justifyContent: 'flex-start'
              }]}>
            <View
              style={[{
                justifyContent: "center",
                minHeight: "50%",
                width: "100%"
              },
              width < 500 && {
                width: '95%',
                padding: '10px',
                paddingTop: '0px'
              }]}>
              <IvyPresto fontSize={width < 500 ? '30px' : '40px'} color={textColor}>
                Register
              </IvyPresto>
              <Line />
              <View style={{ paddingTop: "25px" }}>
                <View style={{ marginBottom: "15px" }}>
                  <View style={{ flexDirection: "row" }}>
                    <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                      E-mail
                    </SSTextMed>
                    <SSTextMed color="tomato" fontSize={width < 500 ? '12px' : '14px'}>
                      *
                    </SSTextMed>
                  </View>
                  <Input
                    style={[
                      width < 500 && {
                        height: '40px'
                      }]}
                    onChangeText={onChangeEmail}
                    keyboardType="email-address"
                  />
                </View>
                <View style={{ marginBottom: "15px" }}>
                  <View style={{ flexDirection: "row" }}>
                    <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                      Password
                    </SSTextMed>
                    <SSTextMed color="tomato" fontSize={width < 500 ? '12px' : '14px'}>
                      *
                    </SSTextMed>
                  </View>
                  <Input
                    onChangeText={onChangePass}
                    keyboardType="default"
                    secureTextEntry={true}
                    style={[
                      width < 500 && {
                        height: '40px'
                      }]}
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                    Confim Password
                  </SSTextMed>
                  <SSTextMed color="tomato" fontSize={width < 500 ? '12px' : '14px'}>
                    *
                  </SSTextMed>
                </View>
                <Input
                  onChangeText={onChangePassConfirm}
                  keyboardType="default"
                  secureTextEntry={true}
                  style={[
                    width < 500 && {
                      height: '40px'
                    }]}
                />
              </View>
              <View style={{ marginTop: "10px" }}>
                {!passwordMatch && <SSTextMed color="red" fontSize={width < 500 ? "13px" : "14px"}>
                  Passwords do not match!
                </SSTextMed>}
                {!emailFilled && <SSTextMed color="red" fontSize={width < 500 ? "13px" : "14px"}>
                  E-mail field cannot be empty!
                </SSTextMed>}
              </View>
            </View>
            <ButtonBox style={[{ marginTop: "10px" }, width < 500 && { width: '95%', paddingTop: '0px', paddingBottom: '0px', paddingLeft: '10px', paddingRight: '10px', height: '40px' }]}>
              <RegisterButton onButtonPress={() => handleEmailSignUp()} width={width} email={email} password={password} passwordConfirm={passwordConfirm} />
            </ButtonBox>
            <View
              style={[{
                flexDirection: "row",
                gap: "15px",
                justifyContent: "center",
                height: "30px",
                width: "100%",
              },
              width < 500 && {
                width: '95%',
                paddingLeft: '10px',
                paddingRight: '10px'
              }
              ]}
            >
              <Line style={{ marginTop: "9px", flex: "1" }} />
              <SSTextMed color="#756349" fontSize={width < 500 ? "13px" : "14px"}>
                Or
              </SSTextMed>
              <Line style={{ marginTop: "9px", flex: "1" }} />
            </View>

            <View nativeID='alternativeLogins' style={[{ flexDirection: "row", gap: "15px", paddingTop: '5px', width: '100%' }, width < 500 && { gap: '8px', width: '95%', paddingLeft: '10px', paddingRight: '10px' }]}>
              <GoogleButton width={width} action={() => handleGoogleSignIn()} />
            </View>
          </SignInCore>
        </View>
      </UIContainer>
      <Footer width={width} height={height}/>
    </Container>
  );
}

export default RegisterScreen
import React, { useState, useEffect } from 'react'
import { storage, db, functions } from '../firebaseConfig';
import { ref, uploadBytes, listAll, getDownloadURL } from 'firebase/storage'
import { collection, addDoc, getDocs, doc, onSnapshot } from "firebase/firestore"; 
import { httpsCallable } from '@firebase/functions';
import { DragDropContext } from 'react-beautiful-dnd';
// import { DragAndDrop } from './DragAndDrop';

const AddProducts = () => {

  const [productName, setProductName] = useState('');
  const [productPrice, setProductPrice] = useState(0);
  const [question, setQuestion] = useState('');
  const [questionList, setQuestionList] = useState([]);
  const [productImg, setProductImg] = useState(null);
  const [loader, setLoader] = useState(false);

  const [products, setProducts] = useState([]);

  const types = ['image/png', 'image/jpeg']

  const [imageUpload, setImageUpload] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const imagesListRef = ref(storage, "images/");

  // function to list all questions inside the question collection as an array
  const getQuestions = async () => {
    const questionList = await getDocs(collection(db, "questions"));
    questionList.forEach((doc) => {
      setQuestionList((prev) => [...prev, doc.data()]);
  
      console.log(doc.id, " => ", doc.data());
    });
  }

  useEffect(() => {
    onSnapshot(collection(db, "questions"), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          setQuestionList((prev) => [...prev, change.doc.data()]);
        }
      });
    });
    listAll(imagesListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageUrls((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  const addInterviewQuestion = httpsCallable(functions, 'addInterviewQuestion');

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    
    addInterviewQuestion({
      question: question
    }).then(result => {
      console.log(result);
    }).catch(error => {
      console.log(error);
    });

    setQuestion("");
    setLoader(false);
  }

  const handleSubmit = async (e) => {

    if (imageUpload == null) return;
    const imageRef = ref(storage, `images/hello`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrls((prev) => [...prev, url]);
      });
    });

    e.preventDefault();
    setLoader(true);

      await addDoc(collection(db, "Product"), {
        name: productName,
        price: productPrice,
        Image: imageUrls,
      })
      .then(() => {
        setLoader(false);
        alert("Your message has been submitted");
      })
      .catch((error) => {
        alert(error.message);
        setLoader(false);
      });

    setProductName("");
    setProductPrice("");
    setProductImg("");
  };

  console.log(questionList);

  return (
    <div className='container'>
      <form autoComplete='off' onSubmit={handleQuestionSubmit}>
        <label htmlFor='question-name'>Product Name</label>
        <br />
        <input 
          type='text'
          onChange={(e)=>setQuestion(e.target.value)}
          value={question} 
        />
        <button type="submit">ADD</button>
      </form>
      <br />
      <h2>Add Products</h2>
      <hr />
      <form autoComplete='off' onSubmit={handleSubmit}>
        <label htmlFor='product-name'>Product Name</label>
        <br />
        <input 
          type='text' 
          required
          onChange={(e)=>setProductName(e.target.value)}
          value={productName} 
        />
        <label htmlFor='product-price'>Product Price</label>
        <br />
        <input 
          type='number'
          required
          onChange={(e)=>setProductPrice(e.target.value)}
          value={productPrice} />
        <label htmlFor='product-img'>Product Image</label>
        <br />
        <input 
          type='file' 
          required
          onChange={(e)=>setImageUpload(e.target.files[0])}
        />
        <button type="submit">ADD</button>
      </form>
      {/* <DragAndDrop
        questionList={questionList}
      /> */}
      
      {questionList.map((doc) => {
        return <p key={doc.id}>{doc.question}</p>;
      })}
      
      {/* {imageUrls.map((url) => {
        return <img src={url} />;
      })} */}
      <br></br>

      {products.length > 0 && (
          <div className='container-fluid'>
              <h1 className='text-center'>Products</h1>
              <div className='products-box'>
                  <Products products={products}/>
              </div>
          </div>
      )}
      {products.length < 1 && (
          <div className='container-fluid'>Please wait....</div>
      )}
    </div>
  )
}

export default AddProducts

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Text, View, Image, Pressable, Linking, useWindowDimensions } from 'react-native';
import styled from '@emotion/native'
import { useFonts } from 'expo-font';
import { useHover } from 'react-native-web-hooks';
import { auth } from '../firebaseConfig'
import UserBadge from '../components/UserBadge'
import { useNavigation } from '@react-navigation/native';


// SPLASH SCREEN USED TO LOAD CUSTOM FONTS
import * as SplashScreen from 'expo-splash-screen';
SplashScreen.preventAutoHideAsync();


const InnerText = styled.Text`
  transition: all .2s ease;
`
const Headr = styled.View`
    display: flex;
    width: 100%;
    height: 110px;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 10.2%;
    flex-direction: row;
    align-items: center;
    position: relative;
    z-index: 999;
`
const NavigationContainer = styled.View`
    flex-direction: row;
    gap: 30px;
    width: max-content;
    align-items: center;
`
const HoverLine = styled.View`
    transform: translateY(11px);
    width: 100%;
    height: 3px;
    background: #ddd9c9;
    opacity: 0;
    transition: all .2s ease;
    border-radius: 2px;
`
const NavHoverable = ({ navigateTo, text, fontSize }) => {
    const navigation = useNavigation();
    const ref = useRef(null);
    const isHovered = useHover(ref);
    return (
        <>
            <Pressable ref={ref} style={{ textAlign: "right", width: "max-content" }} onPress={() => navigation.navigate(`${navigateTo}`)}>
                <SSTextBold color={isHovered ? "#382203" : "#4f3d25"} fontSize={fontSize}>
                    {text}
                </SSTextBold>
                <HoverLine style={[isHovered && { opacity: "1", transform: "translateY(10px)" }]} />
            </Pressable>
        </>
    )
}

// Header Element
const SignIn = styled.Pressable`
    display: flex;
    border-radius: 999px;
    padding: 5px;
    background: #F5A477;
    width: max-content;
    height: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all .2s ease;
`
const SignInButton = ({ width, height }) => {
    const navigation = useNavigation();
    const ref = useRef(null)
    const isHovered = useHover(ref)
    return (
        <SignIn
            ref={ref}
            style={[
                isHovered && {
                    background: "#efa275",
                    transform: "translateY(-1px)"
                },
                width < 500 && {
                    padding: '4px',
                    transform: 'translateY(-2px)'
                },
                height < 415 && {
                    padding: 3
                }
            ]}
            onPress={() => navigation.navigate("SignIn")}>

            <View style={[{ paddingRight: "10px", paddingLeft: "20px" }, width < 500 || height < 415 && { paddingRight: '8px', paddingLeft: '15px' }]}>
                <SSTextBold color={isHovered ? "#fff" : "#fff"} fontSize={width < 500 ? "13px" : height < 415 ? '12px' : "15px"}>Sign In</SSTextBold>
            </View>
            <View
                style={[{
                    background: "#eab596",
                    borderRadius: "999px",
                    height: "33px",
                    width: "33px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    transitionProperty: "background",
                    transitionDuration: ".2s",
                    transitionTimingFunction: "ease"
                },
                isHovered && {
                    background: "#e8b192",
                },
                width < 500 && {
                    height: '30px',
                    width: '30px'
                },
                height < 415 && {
                    height: '27px',
                    width: '27px'
                }]}>
                <Image
                    resizeMode="contain"
                    source={require('../../assets/ruddyrightarrow.png')}
                    style={[{
                        height: '15px',
                        width: '15px'
                    },
                    width < 500 && {
                        height: '13px',
                        width: '13px'
                    },
                    height < 415 && {
                        height: '13px',
                        width: '13px'
                    }]} />
            </View>
        </SignIn>
    )
}


export const Header = ({ width, height }) => {
    const [user, setUser] = useState(null);

    useEffect(() => {
        setUser(auth.currentUser)
    }, [])

    return (
        <Headr style={[{
            shadowColor: '#B59668',
            shadowOffset: { width: 0, height: 4 },
            shadowOpacity: 0.1,
            shadowRadius: 10,
            background: "#F8F4E9"
        },
        width < 1181 && {
            paddingLeft: "3%",
            paddingRight: "3%",
            height: 90
        },
        width < 500 && {
            height: 70
        },
        height < 415 && {
            height: 50
        },]}>
            <Pressable onPress={() => { Linking.openURL('https://storycorder.com') }}>
                <Image
                    resizeMode="contain"
                    source={require('../../assets/storycorder.png')}
                    style={[{
                        height: 75,
                        width: 250,
                        transform: "translateX(0px)"
                    },
                    width < 1130 && {
                        width: 200
                    },
                    width < 500 && {
                        width: 150
                    },
                    height < 415 && {
                        width: 140
                    },]} />
            </Pressable>
            <NavigationContainer style={[width < 500 || height < 415 && { gap: "15px" }]}>
                <NavHoverable navigateTo={"Store"} text="Store" fontSize={height < 415 ? '12px' : width < 500 ? "13px" : "15px"} />
                {
                    (user == null) ?
                        <SignInButton width={width} height={height} /> :
                        <UserBadge width={width} height={height} />
                }
            </NavigationContainer>
        </Headr>
    )
}

const SaveAndExitButton = styled.Pressable`
    display: flex;
    border-radius: 999px;
    padding: 5px;
    background: #F5A477;
    width: max-content;
    height: max-content;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    transition: all .2s ease;
`
export const InterviewHeader = ({ width, height }) => {
    const ref = useRef(null);
    const isHovered = useHover(ref);
    const navigation = useNavigation();
    return (
        <Headr style={[{
            shadowColor: '#B59668',
            shadowOffset: { width: 0, height: 4 },
            shadowOpacity: 0.1,
            shadowRadius: 10,
            background: "#F8F4E9"
        },
        width < 1181 && {
            paddingLeft: "3%",
            paddingRight: "3%",
            height: 90
        },
        width < 500 && {
            height: 70
        },
        height < 415 && {
            height: 50
        }]}>
            <Pressable onPress={() => { Linking.openURL('https://storycorder.com') }}>
                <Image
                    resizeMode="contain"
                    source={require('../../assets/storycorder.png')}
                    style={[{
                        height: 75,
                        width: 250,
                        transform: "translateX(0px)"
                    },
                    width < 1130 && {
                        width: 200
                    },
                    width < 500 && {
                        width: 150
                    },
                    height < 415 && {
                        width: 140
                    }]} />
            </Pressable>
            <NavigationContainer style={[width < 500 || height < 415 && { gap: "15px" }]}>
                <SaveAndExitButton
                    ref={ref}
                    style={[isHovered && {
                        background: "#efa275",
                        transform: "translateY(-1.5px)"
                    },
                    width < 500 && {
                        padding: '4px',
                        transform: 'translateY(-2px)'
                    },
                    height < 415 && {
                        padding: 3
                    }]}
                    onPress={() => navigation.navigate("UserDashboard")}>
                    <View style={[{ paddingRight: "10px", paddingLeft: "20px" }, width < 500 || height < 415 && { paddingRight: '8px', paddingLeft: '15px' }]}>
                        <SSTextBold color={isHovered ? "#fff" : "#fff"} fontSize={width < 500 ? "13px" : height < 415 ? '12px' : "15px"}>Save & Exit</SSTextBold>
                    </View>
                    <View
                        style={[{
                            background: "#eab596",
                            borderRadius: "999px",
                            height: "33px",
                            width: "33px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            transitionProperty: "background",
                            transitionDuration: ".2s",
                            transitionTimingFunction: "ease"
                        },
                        isHovered && {
                            background: "#e8b192"
                        },
                        width < 500 && {
                            height: '30px',
                            width: '30px'
                        },
                        height < 415 && {
                            height: '27px',
                            width: '27px'
                        }]}>
                        <Image
                            resizeMode="contain"
                            source={require('../../assets/ruddyrightarrow.png')}
                            style={[{
                                height: '15px',
                                width: '15px'
                            },
                            width < 500 && {
                                height: '13px',
                                width: '13px'
                            },
                            height < 415 && {
                                height: '13px',
                                width: '13px'
                            }]} />
                    </View>
                </SaveAndExitButton>
            </NavigationContainer>

        </Headr>)

}
const Footr = styled.View`
    display: flex;
    width: 100%;
    height: 90px;
    // justify-content: flex-end;
    padding-left: 11%;
    padding-right: 11%;
    border-top-color: #f0e5cb;
    border-top-width: 1px;
    flex-direction: row;
    align-items: center;
`
export const Footer = ({ width, height }) => {
    const ref = useRef(null);
    // Pass that ref to the hooks...
    const isHovered = useHover(ref);
    return (
        <Footr style={[
            width < 1181 && {
                paddingLeft: '3.5%',
                paddingRight: '3%',
                height: 90
            },
            width < 500 && {
                height: 70
            },
            height < 415 && {
                height: 50
            }]}>
            <View style={{ flex: "1" }}>
                <SSText color="#c8bd9e" fontSize={height < 415 ? '12px' : width < 500 ? '13px' : '15px'}>
                    © Storycorder Copyright 2023
                </SSText>
            </View>
            <Pressable ref={ref} style={{ flex: "1", textAlign: "right" }} onPress={() => { Linking.openURL('https://google.com') }}>
                <SSText color={isHovered ? "#b2a88d" : "#c8bd9e"} fontSize={height < 415 ? '12px' : width < 500 ? '13px' : '15px'}>
                    Privacy Policy
                </SSText>
            </Pressable>
        </Footr>
    )

}

// IvyPresto 
// implement like this:
// <IvyPresto text="Inner text goes here" fontSize="30px" color="#000"/>
export const IvyPresto = (props) => {
    const [fontsLoaded] = useFonts({
        'IvyPresto': require('../../assets/fonts/IvyPresto.ttf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'IvyPresto', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

// IvyPresto Italic
// implement like this:
// <IvyPrestoItalic fontSize="30px" lineHeight="110%" color="#000"/>Text</IvyPrestoItalic>

export const IvyPrestoItalic = (props) => {
    const [fontsLoaded] = useFonts({
        'IvyPrestoItalic': require('../../assets/fonts/IvyPrestoItalic.ttf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'IvyPrestoItalic', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

// IvyPresto SemiBold
// implement like this:
// <IvyPrestoSemiBold text="Inner text goes here" fontSize="30px" lineHeight="110%" color="#000"/>
export const IvyPrestoSemiBold = (props) => {
    const [fontsLoaded] = useFonts({
        'IvyPrestoSemiBold': require('../../assets/fonts/IvyPrestoSemiBold.ttf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'IvyPrestoSemiBold', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

// IvyPresto SemiBold Italic
// implement like this:
// <IvyPrestoSemiBold text="Inner text goes here" fontSize="30px" color="#000"/>
export const IvyPrestoSemiBoldItalic = (props) => {
    const [fontsLoaded] = useFonts({
        'IvyPrestoSemiBoldItalic': require('../../assets/fonts/IvyPrestoSemiBoldItalic.ttf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'IvyPrestoSemiBoldItalic', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

// Sans Serif Text
// implement like this:
// <SSText text="Inner text goes here" fontSize="30px" color="#000"/>
export const SSText = (props) => {
    const [fontsLoaded] = useFonts({
        'SansSerif': require('../../assets/fonts/NeueHaasGrotesk.otf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'SansSerif', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

// Italicized Sans Serif Text
// implement like this:
// <SSTextItalic text="Inner text goes here" fontSize="30px" color="#000"/>
export const SSTextItalic = (props) => {
    const [fontsLoaded] = useFonts({
        'SansSerifItalic': require('../../assets/fonts/NeueHaasGroteskItalic.otf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'SansSerifItalic', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

// Medium Thickness Sans Serif Text
// implement like this:
// <SSTextMed text="Inner text goes here" fontSize="30px" color="#000"/>
export const SSTextMed = (props) => {
    const [fontsLoaded] = useFonts({
        'SansSerifMed': require('../../assets/fonts/NeueHaasGroteskMed.otf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'SansSerifMed', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

// Italicized Medium Thickness Sans Serif Text
// implement like this:
// <SSTextMedItalic text="Inner text goes here" fontSize="30px" color="#000"/>
export const SSTextMedItalic = (props) => {
    const [fontsLoaded] = useFonts({
        'SansSerifMedItalic': require('../../assets/fonts/NeueHaasGroteskMedItalic.otf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'SansSerifMedItalic', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

// Bold Sans Serif Text
// implement like this:
// <SSTextBold text="Inner text goes here" fontSize="30px" color="#000"/>
export const SSTextBold = (props) => {
    const [fontsLoaded] = useFonts({
        'SansSerifBold': require('../../assets/fonts/NeueHaasGroteskBold.otf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'SansSerifBold', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

// Italicized Bold Thickness Sans Serif Text
// implement like this:
// <SSTextBoldItalic text="Inner text goes here" fontSize="30px" color="#000"/>
export const SSTextBoldItalic = (props) => {
    const [fontsLoaded] = useFonts({
        'SansSerifBoldItalic': require('../../assets/fonts/NeueHaasGroteskBoldItalic.otf'),
    });

    const onLayoutRootView = useCallback(async () => {
        if (fontsLoaded) {
            await SplashScreen.hideAsync();
        }
    }, [fontsLoaded]);

    if (!fontsLoaded) {
        return null;
    }
    return (
        <View onLayout={onLayoutRootView}>
            <InnerText style={[{ fontFamily: 'SansSerifBoldItalic', fontSize: props.fontSize, color: props.color, lineHeight: props.lineHeight }]}>
                {props.children}
            </InnerText>
        </View>

    )
}

import { StyleSheet, Text, View, Image, TextInput, useWindowDimensions } from "react-native";
import React, { useState, useRef } from "react";
import styled, { css } from "@emotion/native";
import {
  Footer,
  Header,
  IvyPresto,
  SSTextMed,
  SSTextBold,
} from "../constants/GlobalElements";
import { useHover } from "react-native-web-hooks";
import { UserAuth } from "../context/AuthContext";
import { goCheckout } from "../components/ProductGrid/ProductCheckout";
const textColor = "#49381F";
const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #F4ECD9;
  min-height: 100vh;
`;
const UIContainer = styled.View`
  width: 100%;
  align-items: center;
  height: calc(100vh - 190px);
`;
const SignInCore = styled.View`
    background: #F7F4E9;
    width: 100%;
    min-height: 400px;
    border-radius: 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 65px;
    padding-top: 45px;
    border: 1px solid rgba(226, 222, 204, .7);
    box-shadow: 0 5px 32px rgba(181, 150, 104, .1);
`
const ButtonBox = styled.View`
    align-items: end;
    justify-content: center;
    height: 80px;
    width: 100%;
`
const ConfirmButton = styled.Pressable`
  display: flex;
  border-radius: 999px;
  padding: 5px;
  width: max-content;
  height: max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
`;
const ButtonTemplate = styled.Pressable`
  display: flex;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  flex: 1;
  height: 45px;
  flex-direction: row;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
`;



const ConfirmationButton = ({ action, width }) => {
  // HOVER EFFECTS
  const ref = useRef(null);
  const isHovered = useHover(ref);
  return (
    <ConfirmButton
      onPress={action}
      ref={ref}
      style={[{
        background: "#c3cc32"
      },
      isHovered && {
        background: '#bec730',
        transform: "translateY(-1px)"
      }]}>
      <View style={{ paddingRight: "10px", paddingLeft: "20px" }}>
        <SSTextBold color={isHovered ? "#f7f5ea" : "#f7f5ea"} fontSize={width < 500 ? "13px" : "15px"}>Confirm</SSTextBold>
      </View>
      <View
        style={[{
          background: "#cbd536",
          borderRadius: "999px",
          height: "33px",
          width: "33px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transitionProperty: "background",
          transitionDuration: ".2s",
          transitionTimingFunction: "ease",
      },
      isHovered && { 
          background: "#c7d135" 
      },
        width < 500 && {
          height: '30px',
          width: '30px',
        }]}>
        <Image
          resizeMode="contain"
          source={require('../../assets/darkgreenarrow.png')}
          style={[{
            height: '15px',
            width: '15px'
          },
          width < 500 && {
            height: '13px',
            width: '13px',
          }]} />
      </View>
    </ConfirmButton>
  )
}


const Line = styled.View`
    border-top-color: rgba(226, 222, 204, 0.7);
    border-top-width: 1px;
    margin-top: 19px;
    width: 100%;
  `;
const Input = styled.TextInput`
    height: 45px;
    width: 100%;
    border: 1px solid rgba(226, 222, 204, 1);
    border-radius: 7px;
    margin-top: 7px;
    background: #FCFAF2;
    padding-left: 12px;
    color: rgb(117, 99, 73);
  `;
//   const signInScreen = () => {
// Handlers
const SignUpLoginScreen = ({ route }) => {
  const { width, height } = useWindowDimensions()
  // const { productID = "5", productTitle = "Engagement Viddy", StripePriceID = "stripePriceID", productPrice = "$Free.99", productPicUrl = "https://i.pinimg.com/564x/d3/15/30/d31530fa3c0da6109aab53c14e92cd96.jpg", videoIsFor = "purchaser" } = route.params ?? {};

  const [password, onChangePass] = useState("");
  const [email, onChangeEmail] = useState("");
  const { user, emailLogin } = UserAuth();
  const stripePriceID = route.stripePriceID;

  const handleEmailLogin = async () => {
    try {
      await emailLogin(email, password).then(goCheckout(user, stripePriceID));

    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <UIContainer>
        <Header width={width} height={height}/>
        <View style={[{ width: "500px", justifyContent: "center", height: "100%" }, width < 500 && { width: '90%' }]}>
          <SignInCore style={[width < 500 && { marginTop: '-3%', padding: '10px', paddingBottom: '35px', justifyContent: 'flex-start', minHeight: '0px', paddingTop: '25px' }]}>
            <View style={[{ justifyContent: "center", minHeight: "50%", width: "100%" }, width < 500 && { width: '95%', padding: '10px', paddingTop: '0px' }]}>
              <IvyPresto fontSize={width < 500 ? '30px' : '40px'} color={textColor}>
                Sign In
              </IvyPresto>
              <Line />
              <View style={{ paddingTop: "25px" }}>
                <View style={{ marginBottom: "15px" }}>
                  <View style={{ flexDirection: "row" }}>
                    <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                      E-mail
                    </SSTextMed>
                    <SSTextMed color="tomato" fontSize={width < 500 ? '12px' : '14px'}>
                      *
                    </SSTextMed>
                  </View>
                  <Input
                    onChangeText={onChangeEmail}
                    keyboardType="email-address"
                    style={[
                      width < 500 && {
                        height: '40px'
                      }]}
                  />
                </View>
                <View style={{ flexDirection: "row" }}>
                  <SSTextMed color="#756349" fontSize={width < 500 ? '12px' : '14px'}>
                    Password
                  </SSTextMed>
                  <SSTextMed color="tomato" fontSize={width < 500 ? '12px' : '14px'}>
                    *
                  </SSTextMed>
                </View>
                <Input
                  onChangeText={onChangePass}
                  keyboardType="default"
                  secureTextEntry={true}
                  style={[
                    width < 500 && {
                      height: '40px'
                    }]}
                />
              </View>
            </View>
            <ButtonBox style={[width < 500 && { height: '50px', width: '95%', padding: '10px', paddingTop: '25px' }]}>
              <ConfirmationButton action={() => handleEmailLogin()} width={width} />
            </ButtonBox>
          </SignInCore>
        </View>
      </UIContainer>
      <Footer width={width} height={height}/>
    </Container>
  );
};

export default SignUpLoginScreen;
import { View, Pressable, useWindowDimensions, Image } from "react-native";
import { storage, db, functions, auth } from "../firebaseConfig";
import UploadVideo from "../components/UploadVideo";

import {
  getDoc,
  getDocs,
  doc,
  arrayUnion,
  setDoc,
  updateDoc,
  arrayRemove,
  collection,
  addDoc
} from "firebase/firestore";
import React, { useEffect, useState, useRef } from "react";
import Animated, {
  withSpring,
  useAnimatedStyle,
  useSharedValue,
  withTiming,
  withDelay,
} from "react-native-reanimated";
import { Footer, InterviewHeader, SSText } from "../constants/GlobalElements";
import {
  WordAnimator,
  NavButtonRight,
  NavButtonLeft,
  StartRecordingButton,
  FinishRecordingButton,
  SubmitRecordingButton,
  PreviewButton,
  DiscardButton,
  SubmittedAlert,
  GifComponent,
  Container,
  MainBody,
  TextWrapper,
  Column,
  LowerBody,
  NavigationSection,
  InterviewSection,
  ButtonArea,
  VideoContainer,
  RedDot,
  InsertPhotoPressable
} from "../constants/InterviewElements";
import { UserAuth } from "../context/AuthContext";
import {
  ref,
  uploadBytes,
  listAll,
  getDownloadURL,
  getMetadata,
  getStorage,
} from "firebase/storage";
import { httpsCallable } from "@firebase/functions";
import { useRecordWebcam } from "react-record-webcam";
import ContentLoader from 'react-content-loader'
import { useNavigation } from '@react-navigation/native'

const LoadingBox = () => {
  return (
    <View style={{ position: 'absolute', width: 600, height: 400, borderRadius: 8, overflow: 'hidden' }}>
      <ContentLoader viewBox="0 0 600 400" backgroundColor="#dfd7c4" foregroundColor="#e5ddc9" >
        {/* Only SVG shapes */}
        <rect x="0" y="0" rx="8" ry="5" width="600" height="400" speed={5} />
      </ContentLoader>
    </View>

  )
}

const InterviewInterfaceScreen = ({ route }) => {


  // ███████ ████████  █████  ████████ ███████ 
  // ██         ██    ██   ██    ██    ██      
  // ███████    ██    ███████    ██    █████   
  //      ██    ██    ██   ██    ██    ██      
  // ███████    ██    ██   ██    ██    ███████ 
  // ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇

  const { width, height } = useWindowDimensions();
  const [number, setNumber] = useState(0);
  const [productId, setProductId] = useState([]);
  const [Questions, setQuestions] = useState([
    { id: 0, question: "Loading..." },
  ]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [questionsLoaded, setQuestionsLoaded] = useState(false);
  const [preview, setPreview] = useState(false);
  // const [videoPosition, setVideoPosition] = useState(0);
  // const { user } = UserAuth();
  const user = route.params.user;
  const [count, setCount] = useState(0);
  const [textArray, setText] = useState([]);
  const [seed, setSeed] = useState(1);
  const [recordButtonState, setRB] = useState(false);
  const [finishButtonState, setFB] = useState(true);
  const [submitButtonState, setSB] = useState(true);
  const [previewButtonState, setPB] = useState(true);
  const [discardButtonState, setDB] = useState(true);
  const [insertPhotoButtonState, setIPB] = useState(true);
  const [recording, setRecording] = useState(false);
  const [ticking, setTicker] = useState(false);
  const [gifSource, setGifSource] = useState(null);
  const navigation = useNavigation();

  // ANIMATION START VALUES
  // start recording button values
  const offsetRecordButton = useSharedValue(0);
  const visibilityRecordButton = useSharedValue(1);
  const visibilityDurationRecordButton = useSharedValue(100);

  // finish recording button values
  const offsetFinishButton = useSharedValue(-43);
  const visibilityFinishButton = useSharedValue(0);
  const visibilityDurationFinishButton = useSharedValue(400);

  // submit answer button values
  const offsetSubmitButton = useSharedValue(-86);
  const visibilitySubmitButton = useSharedValue(0);

  // preview answer button values
  const offsetPreviewButton = useSharedValue(-86);
  const visibilityPreviewButton = useSharedValue(0);
  const previewButtonDelay = useSharedValue(50);

  // discard answer button values
  const offsetDiscardButton = useSharedValue(-86);
  const visibilityDiscardButton = useSharedValue(0);
  const discardButtonDelay = useSharedValue(100);

  // 'submitted!' alert values
  const offsetSubmittedAlert = useSharedValue(-43);
  const visibilitySubmittedAlert = useSharedValue(0);
  const visibilityDurationSubmittedAlert = useSharedValue(400);

  // insert photo values 
  const offsetInsertPhotoButton = useSharedValue(-30);
  const visibilityInsertPhotoButton = useSharedValue(0);

  // submit button group visibility duration
  const buttonGroupVisibilityDuration = useSharedValue(400);

  const [start, setStart] = useState(0);
  const [stop, setStop] = useState(0);
  const [imageUpload, setImageUpload] = useState(null);
  const [durationInSeconds] = useState(0);


  // ██       ██████   ██████  ██  ██████ 
  // ██      ██    ██ ██       ██ ██      
  // ██      ██    ██ ██   ███ ██ ██      
  // ██      ██    ██ ██    ██ ██ ██      
  // ███████  ██████   ██████  ██  ██████ 
  // ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ 

  const recordWebcam = useRecordWebcam({
    mimeType: "video/x-matroska;codecs=avc1",
    frameRate: 30,
    height: 1080,
    width: 1920
  });

  useEffect(() => {
    const getQuestions = async () => {
      console.log("Try to get questions");
      const docRef = doc(db, "Products", route.params.videoId.slice(0, -1));
      // const docRef = doc(db, "Products", props.productId )
      const docSnap = await getDoc(docRef);
      console.log("Questions Are:", docSnap.data());
      setQuestions(
        docSnap.data().questions.map((q, index) => ({ id: index, question: q }))
      );
    };
    getQuestions();
    videoQuantity();

    if (Questions) {
      setQuestionsLoaded(true);
    }

  }, [questionsLoaded]);

  console.log(productId[0]?.productId);
  console.log(Questions[0]?.question);
  console.log("AAAAAAAAAAAAAA" + route.params.videoId);

  const uid = route.params.uid;
  const email = route.params.email;
  const videoId = route.params.videoId
  // const videoId = "first-dentist-appointment"
  console.log(uid)
  const storageRef = ref(storage, "videos/" + uid + "/" + videoId + "/");
  const dbRef = doc(db, "users/" + uid + "/videos/" + videoId);
  const listRef = listAll(storageRef);
  const listDocs = getDocs(collection(db, "users/" + uid + "/videos/"));
  const [docArray, setDocArray] = useState([]);
  const [multipleVideoId, setMultipleVideoId] = useState(0);
  // listDocs.forEach((doc) => {
  //   setDocArray((docArray) => [...docArray, doc]);
  // });
  console.log(docArray);

  const videoQuantity = async () => {
    setNumber(await listRef.then((res) => res.items.length));
    setCount(await listRef.then((res) => res.items.length));
  }

  useEffect(() => {
    getDocs(collection(db, "users/" + uid + "/videos/"))
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.id.slice(0, -1) === videoId && doc.data().completed != undefined) {
            setMultipleVideoId(multipleVideoId + 1);
          }
          setDocArray((docArray) => [...docArray, doc.id]);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });
    getDoc(dbRef)
      .then((doc) => {
        if (doc.exists()) {
          console.log("Document data:", doc.data());
          setVideoUrl(doc.data().url);
          setMetaData(doc.data().durationInSeconds);
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        console.log("Error getting document:", error);
      });
  }, []);

  // const renderInterview = () => {
  //   const shotstack = httpsCallable(functions, "shotStackFunctions-shotstack");
  //   shotstack({
  //     urls: videoUrlArray,
  //     duration: metaDataArray,
  //     user: uid,
  //     videoId: videoId,
  //     questions: Questions,
  //   })
  //   .then((result) => {
  //   console.log(result.data);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });

  //   // navigation.navigate("VideoBaking", { videoId: videoId, questions: Questions });
  // };

  const videoUrlArray = videoUrl.map((url) => {
    return url;
  });

  const metaDataArray = metaData.map((metaData) => {
    return parseFloat(metaData);
  });

  const handleQuestion = () => {
    // close camera
    recordWebcam.retake();
    setNumber(number + 1);
  };

  const handlePrevQuestion = () => {
    // if (number === 0) {
    //   alert("This is the first question");
    //   return;
    // }
    recordWebcam.retake();
    setNumber(number - 1);
  };

  const saveFile = async () => {
    console.log((stop - start) / 1000);
    const metadata = {
      customMetadata: {
        durationInSeconds: (stop - start) / 1000,
      },
    };
    const blob = await recordWebcam.getRecording();
    const uploadRef = ref(storage, "videos/" + uid + "/" + videoId + "/" + count);
    // create a collection in firestore with videoId as the document name
    const dbRef = doc(db, "users", uid, "videos", videoId);

    uploadBytes(uploadRef, blob, metadata).then(() => {
      getDownloadURL(uploadRef).then((url) => {
        getDoc(dbRef).then((doc) => {
          console.log(doc.data());
          if (!doc.exists()) {
            setDoc(dbRef, {
              url: arrayUnion(url),
              durationInSeconds: arrayUnion((stop - start) / 1000),
              questionIndex: arrayUnion(count),
            }, { merge: true });
          } else {
            if (doc.data().questionIndex.includes(count)) {
              console.log("questionIndex idexof", doc.data().url[doc.data().questionIndex.indexOf(count)]);
              updateDoc(dbRef, {
                url: arrayRemove(doc.data().url[doc.data().questionIndex.indexOf(count)]),
                durationInSeconds: arrayRemove(doc.data().durationInSeconds[doc.data().questionIndex.indexOf(count)]),
                questionIndex: arrayRemove(count),
              });
            }
            updateDoc(dbRef, {
              url: arrayUnion(url),
              durationInSeconds: arrayUnion((stop - start) / 1000),
              questionIndex: arrayUnion(count),
            });
            // if (doc.data().questionIndex.includes(count - 1) || count === 0) {
            //   getDoc(dbRef).then((doc) => {
            //     const sortedUrl = [];
            //     const sortedDurationInSeconds = [];
            //     const sortedQuestionIndex = [];
            //     for (let i = 0; i < doc.data().questionIndex.length; i++) {
            //       for (let j = 0; j < doc.data().questionIndex.length; j++) {
            //         if (doc.data().questionIndex[j] === i) {
            //           sortedUrl[i] = doc.data().url[j];
            //           sortedDurationInSeconds[i] = doc.data().durationInSeconds[j];
            //           sortedQuestionIndex[i] = doc.data().questionIndex[j];
            //         }
            //       }
            //     }
            //     // update dbRef with sorted data
            //     updateDoc(dbRef, {
            //       url: sortedUrl,
            //       durationInSeconds: sortedDurationInSeconds,
            //       questionIndex: sortedQuestionIndex,
            //     });
            //   });
            // alert("File Uploaded");
            // }
          }
        });
      });
    });
  };

  // useEffect(() => {
  //   recordWebcam.retake();
  // }, [count]);

  useEffect(() => {
    recordWebcam.open();
  }, []);

  const uploadImage = async () => {
    if (imageUpload == null) return;
    console.log(imageUpload);
    const imageRef = ref(storage, 'images/' + uid + '/' + videoId + '/' + count);
    uploadBytes(imageRef, imageUpload);
  };

  const startRecording = () => {
    setStart(Date.now());
    recordWebcam.start();
  };

  const stopRecording = () => {
    setStop(Date.now());
    recordWebcam.stop();
  };

  const OnRecordStart = () => {
    // ANIMATION END VALUES
    // start recording button exiting
    offsetRecordButton.value = 43;
    visibilityRecordButton.value = 0;
    setRB(true);
    // .8s per frame .gif -> https://i.imgur.com/IoeKGVm.gif
    setGifSource(`https://i.imgur.com/TG38tgG.gif?${Date.now()}`);
    setTicker(true);

    setTimeout(() => {
      setTicker(false);
      setRecording(true);
      // finish recording button entering
      offsetFinishButton.value = 0;
      visibilityFinishButton.value = 1;
      setFB(false);

      // prepping submit button group for entrance
      buttonGroupVisibilityDuration.value = 400;
      previewButtonDelay.value = 50;
      discardButtonDelay.value = 100;
      offsetSubmitButton.value = -86;
      offsetPreviewButton.value = -86;
      offsetDiscardButton.value = -86;
      offsetInsertPhotoButton.value = -30;
      visibilityDurationSubmittedAlert.value = 400;

      // prepping 'submitted!' alert for entrance
      offsetSubmittedAlert.value = -43;
      // change opacity change duration on the finish button from 400 to 100
      setTimeout(() => {
        startRecording();
        visibilityDurationFinishButton.value = 100;
      }, 400);
    }, 3000);
  };
  const OnRecordFinish = () => {
    // finish button exit and disabling
    offsetFinishButton.value = 43;
    visibilityFinishButton.value = 0;
    setFB(true);
    setRecording(false);

    // move start recording button to below submit button and change opacity change duration
    offsetRecordButton.value = -43;
    visibilityDurationRecordButton.value = 400;

    // submit button entering/enabling
    offsetSubmitButton.value = -43;
    visibilitySubmitButton.value = 1;
    setSB(false);

    // preview button entering/enabling
    offsetPreviewButton.value = -43;
    visibilityPreviewButton.value = 1;
    setPB(false);

    // discard button entering/enabling
    offsetDiscardButton.value = -43;
    visibilityDiscardButton.value = 1;
    setDB(false);

    // insert photo button entering/enabling
    offsetInsertPhotoButton.value = 0;
    visibilityInsertPhotoButton.value = 1;
    setIPB(false);

    // change exit animation opacity change duration (faster on exit)
    setTimeout(() => {
      buttonGroupVisibilityDuration.value = 100;
    }, 400);

    // change transform delay to zero (should be the same for all buttons on exit)
    setTimeout(() => {
      previewButtonDelay.value = 0;
      discardButtonDelay.value = 0;
      stopRecording();
    }, 500);
  };
  const OnSubmit = () => {
    saveFile();
    if (imageUpload != null) {
      console.log("image");
      const imageRef = ref(storage, 'images/' + uid + '/' + videoId + '/' + count);
      uploadBytes(imageRef, imageUpload).then(() => {
        setImageUpload(null);
      });
    } else {
      console.log("no image");
    }
    // uploadImage();
    recordWebcam.close();
    // prep finish recording button for entrance
    offsetFinishButton.value = -43;
    visibilityDurationFinishButton.value = 400;

    // submit button exiting/disabling
    offsetSubmitButton.value = 0;
    visibilitySubmitButton.value = 0;
    setSB(true);

    // preview button exiting/disabling
    offsetPreviewButton.value = 0;
    visibilityPreviewButton.value = 0;
    setPB(true);

    // discard button exiting/disabling
    offsetDiscardButton.value = 0;
    visibilityDiscardButton.value = 0;
    setDB(true);

    // insert photo button exiting/disabling
    offsetInsertPhotoButton.value = 30;
    visibilityInsertPhotoButton.value = 0;
    setIPB(true);

    offsetSubmittedAlert.value = 0;
    visibilitySubmittedAlert.value = 1;

    // change exit animation opacity change duration for record button (faster on exit)
    setTimeout(() => {
      visibilityDurationSubmittedAlert.value = 100;
    }, 400);

    // show submission feedback then exit
    setTimeout(() => {
      // start entrance animation for start recording button and enable
      offsetRecordButton.value = 0;
      visibilityRecordButton.value = 1;

      // start exit animation for 'submitted!' alert
      offsetSubmittedAlert.value = 43;
      visibilitySubmittedAlert.value = 0;
      setRB(false);
      LoadNewText(false);
      setTimeout(() => {
        visibilityDurationRecordButton.value = 100;
      }, 400);
    }, 1500);
    if (count === Questions.length - 1)
      recordWebcam.close();
    else 
      recordWebcam.retake();
  };
  const OnDiscard = () => {
    recordWebcam.retake();

    // prep finish recording button for entrance
    offsetFinishButton.value = -43;
    visibilityDurationFinishButton.value = 400;

    // submit button exiting/disabling
    offsetSubmitButton.value = 0;
    visibilitySubmitButton.value = 0;
    setSB(true);

    // preview button exiting/disabling
    offsetPreviewButton.value = 0;
    visibilityPreviewButton.value = 0;
    setPB(true);

    // discard button exiting/disabling
    offsetDiscardButton.value = 0;
    visibilityDiscardButton.value = 0;
    setDB(true);

    setTimeout(() => {
      // start entrance animation for start recording button and enable
      offsetRecordButton.value = 0;
      visibilityRecordButton.value = 1;
      setRB(false);
    }, 500);
  };

  // reloads container so it will re-render the animation
  const reset = () => {
    setSeed(Math.random());
  };

  const LoadNewText = async (showAlert = true) => {
    // Check if recordWebcam is in "PREVIEW" or "RECORDING" mode
    if (showAlert && (recordWebcam.status === "PREVIEW" || recordWebcam.status === "RECORDING")) {
      alert("Please finish, submit, or discard your clip before moving on to the next question.");
      return; // Return early to prevent further execution
    }

    // redirect to "FinishedInterviewScreen" if they have submitted all videos
    console.log(count);
    console.log(Questions.length);
    if (count === Questions.length - 1) {
      recordWebcam.close();

      const dbRef = doc(db, "users/" + uid + "/videos/" + videoId);
      getDoc(dbRef).then((doc) => {
        const videoUrlArray = doc.data().url.sort((a, b) => (doc.data().questionIndex[doc.data().url.indexOf(a)] > doc.data().questionIndex[doc.data().url.indexOf(b)]) ? 1 : -1);
        const questionIndexArray = doc.data().questionIndex.sort((a, b) => (a > b) ? 1 : -1);
        const durationInSecondsArray = doc.data().durationInSeconds.sort((a, b) => (doc.data().questionIndex[doc.data().durationInSeconds.indexOf(a)] > doc.data().questionIndex[doc.data().durationInSeconds.indexOf(b)]) ? 1 : -1);

        updateDoc(dbRef, {
          url: videoUrlArray,
          questionIndex: questionIndexArray,
          durationInSeconds: durationInSecondsArray,
          completed: "rendering"
        }).then(() => {
          console.log("Document successfully updated!" + durationInSecondsArray);
          navigation.navigate("VideoBaking", { videoId: videoId, questions: Questions, uid: uid, email: email, multipleVideoId: multipleVideoId });
        });
      });
    } else if (count + 1 === Questions.length) {
      setCount(0);
      setText(Questions[count].question.split(" "));
      reset();
    } else {
      if (recordWebcam.status == "PREVIEW") {
        console.log("preview on")
      } else {
        console.log("preview off")
      }

      setCount(count + 1);
      setText(Questions[count].question.split(" "));
      reset();
    }
  };

  const LoadOldText = async () => {
    // redirect to "FinishedInterviewScreen" if they have submitted all videos
    console.log(count);
    console.log(Questions.length);
    if (count + 1 === Questions.length) {
      // recordWebcam.close();

      setCount(0);
      setText(Questions[count].question.split(" "));
      reset();
    } else {
      // recordWebcam.close();

      setCount(count - 1);
      setText(Questions[count].question.split(" "));
      reset();
    }
  };

  useEffect(() => {
    console.log(Object.keys(Questions).length, count)
    if (Object.keys(Questions).length === count) {
      setCount(Object.keys(Questions).length - 1);
    } else if (Questions[0].question === "Loading...") {
      return;
    } else {
      setText(Questions[count].question.split(" "));
    }
    // console.log(Questions, count)
    // setText(Questions[count].question.split(" "));
  }, [Questions, count]);


  //   █████  ███    ██ ██ ███    ███        ███████ ████████ ██    ██ ██      ███████ ███████ 
  //  ██   ██ ████   ██ ██ ████  ████        ██         ██     ██  ██  ██      ██      ██      
  //  ███████ ██ ██  ██ ██ ██ ████ ██        ███████    ██      ████   ██      █████   ███████ 
  //  ██   ██ ██  ██ ██ ██ ██  ██  ██             ██    ██       ██    ██      ██           ██ 
  //  ██   ██ ██   ████ ██ ██      ██ ██     ███████    ██       ██    ███████ ███████ ███████ 
  // ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ ⬇ 

  const RecordButtonAnimationStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: withSpring(offsetRecordButton.value, {
            damping: 120,
            stiffness: 510,
          }),
        },
      ],
      opacity: [
        withTiming(visibilityRecordButton.value, {
          duration: visibilityDurationRecordButton.value,
        }),
      ],
    };
  });
  const SubmittedAlertAnimationStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: withSpring(offsetSubmittedAlert.value, {
            damping: 120,
            stiffness: 510,
          }),
        },
      ],
      opacity: [
        withTiming(visibilitySubmittedAlert.value, {
          duration: visibilityDurationSubmittedAlert.value,
        }),
      ],
    };
  });
  const FinishButtonAnimationStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: withSpring(offsetFinishButton.value, {
            damping: 120,
            stiffness: 510,
          }),
        },
      ],
      opacity: [
        withTiming(visibilityFinishButton.value, {
          duration: visibilityDurationFinishButton.value,
        }),
      ],
    };
  });
  const SubmitButtonAnimationStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: withSpring(offsetSubmitButton.value, {
            damping: 120,
            stiffness: 510,
          }),
        },
      ],
      opacity: [
        withTiming(visibilitySubmitButton.value, {
          duration: buttonGroupVisibilityDuration.value,
        }),
      ],
    };
  });
  const PreviewButtonAnimationStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          //previewButtonDelay.value is 50 and will change to 0 for it's exit
          translateY: withDelay(
            previewButtonDelay.value,
            withSpring(offsetPreviewButton.value, {
              damping: 120,
              stiffness: 510,
            })
          ),
        },
      ],
      opacity: [
        withDelay(
          previewButtonDelay.value,
          withTiming(visibilityPreviewButton.value, {
            duration: buttonGroupVisibilityDuration.value,
          })
        ),
      ],
    };
  });
  const DiscardButtonAnimationStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          //previewButtonDelay.value is 100 and will change to 0 for it's exit
          translateY: withDelay(
            discardButtonDelay.value,
            withSpring(offsetDiscardButton.value, {
              damping: 120,
              stiffness: 510,
            })
          ),
        },
      ],
      opacity: [
        withDelay(
          discardButtonDelay.value,
          withTiming(visibilityDiscardButton.value, {
            duration: buttonGroupVisibilityDuration.value,
          })
        ),
      ],
    };
  });
  const InsertPhotoAnimationStyles = useAnimatedStyle(() => {
    return {
      transform: [
        {
          translateY: (withSpring(offsetInsertPhotoButton.value, {
            damping: 120,
            stiffness: 510,
          })),
        },
      ],
      opacity: [(withTiming(visibilityInsertPhotoButton.value, {
        duration: buttonGroupVisibilityDuration.value
      }))]
    };
  });

  const inputFile = useRef(null)
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  if (!user) {
    <Loader />;
  } else {
    return (
      <Container nativeID='container'>
        <InterviewHeader width={width} height={height} />
        <LowerBody nativeID='lowerBody' style={{ width: "100%", flexGrow: "1", display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "center" }}>
          <MainBody
            nativeID='mainBody'
            style={[
              (width < 1025 && height > 500) && {
                width: '100%'
              },
              height < 415 && {
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center'
              },
              // for larger iPad landscape dimensions
              (width < 1181 && width > 500 && height < 821 ) && {
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '90vh'
              },
              // for iPad and iPad Mini landscape dimensions (w = 1024, h = 768)
              (width < 1025 && height < 769 && width > 500) && {
                width: '100%',
                minHeight: '90vh',
                alignItems: 'center'
              }
            ]}>
            <InterviewSection
              nativeID='interviewSection'
              style={[
                (width < 1025 && height > 500) && {
                  flexDirection: 'column-reverse',
                  marginTop: 0,
                  height: 'unset',
                  paddingLeft: 0,
                  paddingRight: 0
                },
                height < 415 && {
                  marginTop: 20,
                  height: 'unset',
                  width: '94%'
                },
                (width < 1181 && width > 500 && height < 821 ) && {
                  marginTop: 20,
                  height: 'unset',
                  width: '94%'
                },
                (width < 1025 && height < 769 && width > 500) && {
                  width: '94%',
                  marginTop: 20,
                  height: 'unset',
                  flexDirection: 'row'
                }
              ]}>
              {/* LEFT COLUMN CONTAINING QUESTION */}
              <Column
                nativeID='firstColumn'
                style={[
                  (width < 1025 && height > 500) && {
                    height: 'unset',
                    width: '100%',
                    alignItems: 'center',
                    paddingTop: 70
                  },
                  width < 500 && {
                    paddingTop: 30
                  },
                  (width < 1025 && height < 769 && width > 500) && {
                    width: '45%'
                  }]}>
                <View
                  style={[{
                    minHeight: "200px"
                  },
                  (width < 1025 && height > 500) && {
                    width: `${width - 220}px`
                  },
                  width < 500 && {
                    width: `${width - 40}px`
                  },
                  height < 415 && {
                    minHeight: 150
                  },
                  (width < 1181 && height < 821 && height > 768) && {
                    minHeight: 180
                  },
                  (width < 1025 && height < 769 && width > 500) && {
                    width: '100%'
                  }]}>
                  <SSText color="#9D927B" fontSize={height < 415 ? '16px' : width < 500 ? '17px' : '20px'}>
                    Question {count + 1} of {Questions.length}
                  </SSText>
                  <View style={[{ paddingBottom: "15px" }, width < 500 && { paddingBottom: '10px' }, height < 415 && { paddingBottom: 10 }]} />
                  <TextWrapper key={seed}>
                    {textArray.map((word, index) => {
                      return (
                        <WordAnimator key={`${word}-${index}`} index={index} delay={index * 50} word={word} width={width} height={height} />
                      )
                    })}
                  </TextWrapper>
                </View>
              </Column>
              {/* RIGHT COLUMN CONTAINING VIDEO AND BUTTONS*/}
              <Column
                nativeID='secondColumn'
                style={[{
                  alignItems: 'flex-end'
                },
                (width < 1025 && height > 500) && {
                  height: 'unset',
                  paddingTop: `70px`,
                  paddingBottom: '50px',
                  backgroundColor: '#2A2016',
                  width: '100%',
                  alignItems: 'center'
                },
                width < 500 && {
                  paddingTop: `15px`,
                  paddingBottom: '13.5px'
                },
                height < 415 && {
                  height: 'unset'
                },
                (width < 1025 && height < 769 && width > 500) && {
                  width: '45%',
                  background: 'transparent'
                }]}>
                <VideoContainer
                  nativeID='VideoContainer'
                  style={[{
                    borderRadius: "8px",
                    height: 400,
                    width: 600,
                    background: '#e2dac7',
                    overflow: 'hidden',
                    alignItems: 'center', // Center vertically
                    justifyContent: 'center', // Center horizontally
                  },
                  (width < 1025 && height > 500) && {
                    width: `${width - 200}px`,
                    height: `${(400 * (width - 200)) / 600}px`,
                  },
                  height < 415 && {
                    width: `${(width / 2) - 30}px`,
                    height: `${(400 * ((width / 2) - 30)) / 600}px`
                  },
                  (height < 415 && width < 897 && width > 667) && {
                    width: `${(width / 2) - 80}px`,
                    height: `${(400 * ((width / 2) - 80)) / 600}px`
                  },
                  (height < 415 && width < 787 && width > 667) && {
                    width: `${(width / 2) - 70}px`,
                    height: `${(400 * ((width / 2) - 70)) / 600}px`
                  },
                  (width < 1181 && width > 500 && height < 821 && height > 768) && {
                    width: `${(width / 2) - 80}px`,
                    height: `${(400 * ((width / 2) - 80)) / 600}px`
                  },
                  width < 1370 && {
                    width: 501,
                    height: 334
                  },
                  (width < 1025 && height < 769 && width > 500) && {
                    width: `${(width / 2) - 80}px`,
                    height: `${(400 * ((width / 2) - 80)) / 600}px`
                  },
                  width < 500 && {
                    width: `${width - 30}px`,
                    height: `${(400 * (width - 30)) / 600}px`,
                  }]}
                >
                  {recording &&
                    <RedDot />
                  }
                  {ticking &&
                    <GifComponent source={gifSource} width={width} height={height} />
                  }
                  <Animated.View nativeID="IPBAnimatedView" style={[InsertPhotoAnimationStyles, { position: 'absolute', top: 15, right: 15, zIndex: 999 }]}>
                    <InsertPhotoPressable nativeID='IPB' disabled={insertPhotoButtonState} onPress={onButtonClick}>
                      <Image
                        nativeID='IPBImage'
                        source={require('../../assets/add-photo.png')}
                        style={{
                          height: 30,
                          width: 30
                        }}
                      />
                      <input
                        type="file"
                        id="file"
                        accept="image/png, image/gif, image/jpeg"
                        ref={inputFile}
                        onChange={(event) => {
                          setImageUpload(event.target.files[0]);
                        }}
                        style={{
                          display: "none"
                        }}
                      />
                    </InsertPhotoPressable>
                  </Animated.View>
                  <LoadingBox />
                  <View style={{ position: "relative", zIndex: 77, objectFit: "cover" }}>
                  <video
                      ref={recordWebcam.webcamRef}
                      playsInline
                      style={Object.assign({
                        borderRadius: "8px",
                        overflow: "hidden",
                        height: 400,
                        width: 600,
                        shadowColor: '#B59668',
                        shadowOffset: { width: 0, height: 10 },
                        shadowOpacity: 1,
                        shadowRadius: 20,
                        objectFit: "contain",
                        transform: "scaleX(-1)",
                        display: `${recordWebcam.status === "OPEN" ||
                          recordWebcam.status === "RECORDING"
                          ? "block"
                          : "none"
                          }`
                      },
                        (width < 1025 && height > 500) && {
                          width: `${width - 200}px`,
                          height: `${(400 * (width - 200)) / 600}px`,
                          shadowColor: 'transparent'
                        },
                        width < 500 && {
                          width: `${width - 30}px`,
                          height: `${(400 * (width - 30)) / 600}px`,
                          shadowColor: 'transparent'
                        },
                        height < 415 && {
                          width: `${(width / 2) - 30}px`,
                          height: `${(400 * ((width / 2) - 30)) / 600}px`
                        },
                        (height < 415 && width < 897 && width > 667) && {
                          width: `${(width / 2) - 80}px`,
                          height: `${(400 * ((width / 2) - 80)) / 600}px`
                        },
                        (height < 415 && width < 787 && width > 667) && {
                          width: `${(width / 2) - 70}px`,
                          height: `${(400 * ((width / 2) - 70)) / 600}px`
                        },
                        (width < 1181 && width > 500 && height < 821 && height > 768) && {
                          width: `${(width / 2) - 80}px`,
                          height: `${(400 * ((width / 2) - 80)) / 600}px`
                        },
                        width < 1370 && {
                          width: 501,
                          height: 334
                        },
                        (width < 1025 && height < 769 && width > 500) && {
                          width: `${(width / 2) - 80}px`,
                          height: `${(400 * ((width / 2) - 80)) / 600}px`
                        }
                        )}
                      autoPlay
                      muted
                    />
                      <video
                      ref={recordWebcam.previewRef}
                      playsInline
                      style={Object.assign({
                        borderRadius: "8px",
                        overflow: "hidden",
                        // height: 400,
                        // width: 600,
                        shadowColor: '#B59668',
                        shadowOffset: { width: 0, height: 10 },
                        shadowOpacity: 1,
                        shadowRadius: 20,
                        objectFit: "contain",
                        display: `${recordWebcam.status === "PREVIEW" ? "block" : "none"}`
                      },
                        (width < 1025 && height > 500) && {
                          width: `${width - 200}px`,
                          height: `${(400 * (width - 200)) / 600}px`,
                          shadowColor: 'transparent'
                        },
                        width < 500 && {
                          width: `${width - 30}px`,
                          height: `${(400 * (width - 30)) / 600}px`,
                          shadowColor: 'transparent'
                        },
                        height < 415 && {
                          width: `${(width / 2) - 30}px`,
                          height: `${(400 * ((width / 2) - 30)) / 600}px`
                        },
                        (height < 415 && width < 897 && width > 667) && {
                          width: `${(width / 2) - 80}px`,
                          height: `${(400 * ((width / 2) - 80)) / 600}px`
                        },
                        (height < 415 && width < 787 && width > 667) && {
                          width: `${(width / 2) - 70}px`,
                          height: `${(400 * ((width / 2) - 70)) / 600}px`
                        },
                        (width < 1181 && width > 500 && height < 821 && height > 768) && {
                          width: `${(width / 2) - 80}px`,
                          height: `${(400 * ((width / 2) - 80)) / 600}px`
                        },
                        width < 1370 && {
                          width: 501,
                          height: 334
                        },
                        (width < 1025 && height < 769 && width > 500) && {
                          width: `${(width / 2) - 80}px`,
                          height: `${(400 * ((width / 2) - 80)) / 600}px`
                        })}
                        // loop
                        // autoPlay
                        controls
                    />
                  </View>

                  {/* <p>Camera status: {recordWebcam.status}</p> */}

                </VideoContainer>
                <ButtonArea style={[
                  (width < 1025 && height > 500) && {
                    width: `${width - 200}px`,
                  },
                  height < 415 && {
                    width: `${(width / 2) - 30}px`,
                    paddingTop: 8
                  },
                  // styling for wide mobile landscape viewports (e.g. iPhone XR)
                  (height < 415 && width < 897 && width > 667) && {
                    width: `${(width / 2) - 80}px`
                  },
                  (height < 415 && width < 787 && width > 667) && {
                    width: `${(width / 2) - 70}px`,
                  },
                  (width < 1181 && width > 500 && height < 821 && height > 768) && {
                    width: `${(width / 2) - 80}px`,
                  },
                  width < 1370 && {
                    width: 501,
                  },
                  (width < 1025 && height < 769 && width > 500) && {
                    width: `${(width / 2) - 80}px`,
                  },
                  width < 500 && {
                    width: `${width - 30}px`,
                  }]}>
                  <View style={{ flexDirection: "column" }}>
                    <Animated.View style={[RecordButtonAnimationStyles, { position: "absolute", zIndex: "10", }]}>
                      <Pressable onPress={() => OnRecordStart()} disabled={recordButtonState}>
                        <StartRecordingButton width={width} height={height} />
                      </Pressable>
                    </Animated.View>
                    <Animated.View style={[FinishButtonAnimationStyles, { position: "absolute", zIndex: "9" }]}>
                      <Pressable onPress={() => OnRecordFinish()} disabled={finishButtonState}>
                        <FinishRecordingButton width={width} height={height} />
                      </Pressable>
                    </Animated.View>
                    <Animated.View style={[SubmittedAlertAnimationStyles, { position: "absolute", zIndex: "3" }]}>
                      <SubmittedAlert width={width} height={height} />
                    </Animated.View>
                  </View>
                  <View style={{ flexDirection: "row", zIndex: "8", transform: "translateY(43px)" }}>
                    <Animated.View style={[SubmitButtonAnimationStyles]}>
                      <Pressable onPress={() => OnSubmit()} disabled={submitButtonState}>
                        <SubmitRecordingButton width={width} height={height} />
                      </Pressable>
                    </Animated.View>
                    {/* <Animated.View style={[PreviewButtonAnimationStyles]}>
                      <Pressable disabled={previewButtonState} onPress={() => OnPreview()}>
                        <PreviewButton width={width} height={height} />
                      </Pressable>
                    </Animated.View> */}
                    <Animated.View style={[DiscardButtonAnimationStyles]}>
                      <Pressable onPress={() => OnDiscard()} disabled={discardButtonState}>
                        <DiscardButton width={width} height={height} />
                      </Pressable>
                    </Animated.View>
                  </View>
                </ButtonArea>
              </Column>
            </InterviewSection>
            <NavigationSection
              nativeID='navigationSection'
              style={[(
                width < 1025 && height > 500) && {
                paddingLeft: 96,
                paddingRight: 100
              },
              width < 769 && {
                paddingBottom: 50
              },
              width < 500 && {
                paddingLeft: 7,
                paddingRight: 7
              },
              height < 415 && {
                justifyContent: 'space-between',
                width: '97%'
              },
              (height < 415 && width < 897) && {
                width: '96%',
                paddingBottom: 20
              },
              (height < 415 && width < 897 && width > 667) && {
                paddingTop: 10
              },
              (width < 1181 && width > 500 && height < 821) && {
                justifyContent: 'space-between',
                width: '94%',
                paddingTop: 65
              },
              (width < 1025 && height < 769 && width > 500) && {
                justifyContent: 'space-between',
                width: '96%',
                paddingTop: 65,
                paddingLeft: 0,
                paddingRight: 0
              }]}>
              <View style={{ flex: "1", justifyContent: "left", width: "50%" }}>
                <Pressable onPress={() => LoadOldText()}>
                  {count != 0 ? (
                    <NavButtonLeft width={width} height={height} />
                  ) : null}
                </Pressable>
              </View>
              <View style={{ flex: "1", alignItems: "flex-end", width: "50%" }}>
                <Pressable onPress={() => LoadNewText()}>
                  {/* {count != Questions.length - 1 ? ( */}
                    <NavButtonRight width={width} height={height} />
                  {/* ) : null} */}
                </Pressable>
              </View>
            </NavigationSection>
          </MainBody>
          <View>
            <View>
              <Pressable
                title="Next Question"
                onPress={() => handleQuestion()}
              />
              <Pressable
                
                title="Previous Question"
                onPress={() => handlePrevQuestion()}
              />
              {/* <UploadVideo videoCount={{ number: number }} /> */}
            </View>
          </View>
          {count === Questions.length - 1 ? (
            // <button onClick={renderInterview}>Render Shotstack</button>
            <Pressable
              disabled={number === 0}
              title="Finish Interview"
              onPress={() => handleCompletion()}
            />
          ) : null}
          <Footer width={width} height={height} />
        </LowerBody>
      </Container >
    );
  }
};

export default InterviewInterfaceScreen;
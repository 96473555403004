import React from "react";
import {
  ProductGridContainer,
  Product,
} from "./ProductGridElements";

import { db } from "../../firebaseConfig";
import { collection, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";

// What do we need for the individual product component?
// - Picture
// - Title
// - Price
// - Excerpt
// - Number of Questions and Estimated Time
// - Product ID?

const ProductGrid = ({width}) => {
  const [Products, setProducts] = React.useState([]);
  if (db) {
    useEffect (() => {
      try {
        console.log("Getting Products");
        const unsubscribe = onSnapshot(collection(db, "Products"), (snapshot) => {
          snapshot.forEach((doc) => {
            console.log("Getting Product")
            const _product = {
              pictureUrl: doc.data().pictureUrl,
              title: doc.data().title,
              excerpt: doc.data().excerpt,
              price: doc.data().price,
              numberOfQuestions: doc.data().numberOfQuestions,
              estTime: doc.data().estTime,
              stripePriceID: doc.data().stripePriceID,
              id: Products.length,
              key: Products.length,
              productID: doc._key.path.segments[6],
            };
            setProducts((Products) => [...Products, _product]);
            // console.log("Product Pushed Into Array");
            // console.log(Products);
          });
          unsubscribe();
        });
      } catch (error) {
        console.log("error getting products", error);
        unsubscribe();
      }
    }, [])
  }

  console.log("made it to product grid")
  return (
    <ProductGridContainer>
      {Products.map((product, index) => {
        console.log("made it to product map")
        return (
          <Product
            key={`${product.id}${index}`}
            id={index}
            stripePriceID={product.stripePriceID}
            productID={product.productID}
            title={product.title}
            navigateTo={product.navigateTo}
            pictureUrl={product.pictureUrl}
            numberOfQuestions={product.numberOfQuestions}
            estTime={product.estTime}
            excerpt={product.excerpt}
            price={product.price}
            width={width}
          />
        );
      })}
    </ProductGridContainer>
  );
};

export default ProductGrid;

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator} from "firebase/firestore";
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getAuth, connectAuthEmulator} from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB1vug3uQh1VlRDWD58hjUZGFynBoWwNHc",
  authDomain: "app.storycorder.com",
  projectId: "justbecuz-52a74",
  storageBucket: "justbecuz-52a74.appspot.com",
  messagingSenderId: "898570594532",
  appId: "1:898570594532:web:03cc97cc57aa2ce03d64f1",
  measurementId: "G-NTLL61Y1XZ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics();

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth();
// connectAuthEmulator(auth, 'http://localhost:9093'); // Remove to switch to production

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore();
// connectFirestoreEmulator(db, 'localhost', 8093); // Remove to switch to production

// Initialize Cloud Storage and get a reference to the service
const storage = getStorage();
// connectStorageEmulator(storage, "localhost", 9193); // Remove to switch to production

// Initialize Firebase Functions
const functions = getFunctions();
// connectFunctionsEmulator(functions, "localhost", 5003);

// Initialize Hosting
// const hosting = getHosting();
// connectHostingEmulator(hosting, "localhost", 5000);


// Check state of logged in user
// onAuthStateChanged(auth, user => {
//     if(user != null) {
//         console.log('logged in!');
//     } else {
//         console.log('No user');
//     }
// })

export { auth, db, storage, functions };
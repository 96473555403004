import { db } from "../firebaseConfig";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  doc
} from "firebase/firestore";

export const addFirstLastName = async (user2, firstName, lastName) => {

  // ES9 Method
  try {

    const docRef = doc(db, "users", user2.uid);
    const data = {
        firstName: firstName,
        lastName: lastName
    };
    updateDoc(docRef, data).then(docRef => {
        console.log("A New Document field has been added")
    })
    .catch(err => {
        console.log(err);
    })

    // Wait for the CheckoutSession to get attached by the extension
    const unsubscribe = onSnapshot(docRef, (snap) => {
      console.log("In Snapshot");
      const { error, firstName } = snap.data();
      if (error) {
        // Show an error to your customer and
        // inspect your Cloud Function logs in the Firebase console.
        alert(`An error occured: ${error.message}`);
      }
      if (firstName) {
        // We have a Stripe Checkout URL, let's redirect.
        console.log("First Name Added To Database");
      }
      unsubscribe();
    });
  } catch (e) {
    console.log(e);
  }
};

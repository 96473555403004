import React, { useState } from 'react';
import { View, Text, Pressable, useWindowDimensions } from 'react-native';
import styled from '@emotion/native'
import { Header, SSText } from '../constants/GlobalElements'

import {UserAuth} from '../context/AuthContext'

const Container = styled.View`
  width: 100%;
  background: #f4ecd9;
  min-height: 100vh;
  align-items: center;
`
const FooterSpace = styled.View`
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
`
const NavButton = styled.Pressable`
  margin-bottom: 5px;
  background: rgba(255,255,255,.5);
  padding: 9px;
  border-radius: 10px;
  transition: all .3s ease;
  text-align: center;
  width: 150px;
`
const Span = styled.Text`
  font-size: 10px;
  transition: all .3s ease;
  color: rgba(0,0,0,.8)
`

const HomeScreen = ({ navigation }) => {
  const { width, height } = useWindowDimensions()
  const { user } = UserAuth();
  const [admin, setAdmin] = useState();

  console.log(user)

  if (admin) {
    return (
      <Container>
        <Header width={width} height={height}/>
        <View style={{ paddingTop: "36px", width: "79%", alignItems: "left" }}>
          <SSText fontSize="70px">Home</SSText>
          <View style={{ paddingBottom: "20px" }} />
          <NavButton
            title="Manage Account"
            onPress={() => navigation.navigate('UserDashboard')}
          >
            <Span>
              User Dashboard
            </Span>
          </NavButton>
          <NavButton
            title="Sign In"
            onPress={() => navigation.navigate('SignIn')}
          >
            <Span>
              Sign In Screen
            </Span>
          </NavButton>
          <NavButton
            title="Storycorder Store"
            onPress={() => navigation.navigate('Store')}
          >
            <Span>
              Store
            </Span>
          </NavButton>
          <NavButton
            title="Interview Interface"
            onPress={() => {
              navigation.navigate('InterviewInterface', {
                user: user
              })
            }}
          >
            <Span>
              Interview Interface
            </Span>
          </NavButton>
          <NavButton
            title="Add Products"
            onPress={() => navigation.navigate('AddProducts')}
          >
            <Span>
              AddProducts
            </Span>
          </NavButton>
          <NavButton
            // title="Get Started Page"
            onPress={() => navigation.navigate('GetStarted')}
          >
            <Span>
              Get Started Page
            </Span>
          </NavButton>
          {/* <NavButton
            title="Sign Up Page"
            onPress={() => navigation.navigate('SignUp')}
          >
            <Span>
              Sign Up Page
            </Span>
          </NavButton> */}
          <NavButton
            title="Try It Page"
            onPress={() => navigation.navigate('TryIt')}
          >
            <Span>
              Try It Interview
            </Span>
          </NavButton>
          <NavButton
            title="Video Baking Page"
            onPress={() => navigation.navigate('VideoBaking')}
          >
            <Span>
              Video Baking Page
            </Span>
          </NavButton>
          <NavButton
            title="Watch Interview Page"
            onPress={() => navigation.navigate('WatchInterview')}
          >
            <Span>
              Watch Interview Page
            </Span>
          </NavButton>
          <NavButton
            title="Admin Dashboard"
            onPress={() => navigation.navigate('AdminDashboard')}
            style={{ background: "tomato" }}
          >
            <Span style={{ color: "black" }}>
              Admin Dashboard
            </Span>
          </NavButton></View>
        <FooterSpace>
          <Footer width={width} height={height}/>
        </FooterSpace>
      </Container>
    )
  } else {
    return (
      <Container>
        <Header width={width} height={height}/>
        <View style={{ paddingTop: "36px", width: "79%", alignItems: "left" }}>
          <SSText fontSize="70px">Home</SSText>
          <View style={{ paddingBottom: "20px" }} />
          <NavButton
            title="Manage Account"
            onPress={() => navigation.navigate('UserDashboard')}
          >
            <Span>
              User Dashboard
            </Span>
          </NavButton>
          <NavButton
            title="Sign In"
            onPress={() => navigation.navigate('SignIn')}
          >
            <Span>
              Sign In Screen
            </Span>
          </NavButton>
          <NavButton
            title="Storycorder Store"
            onPress={() => navigation.navigate('Store')}
          >
            <Span>
              Store
            </Span>
          </NavButton>
          <NavButton
            title="Interview Interface"
            onPress={() => {
              navigation.navigate('InterviewInterface', {
                user: user
              })
            }}
          >
            <Span>
              Interview Interface
            </Span>
          </NavButton>
          {/* <NavButton
            title="Add Products"
            onPress={() => navigation.navigate('AddProducts')}
          >
            <Span>
              AddProducts
            </Span>
          </NavButton> */}
          <NavButton
            title="Get Started Page"
            onPress={() => navigation.navigate('GetStarted')}
          >
            <Span>
              Get Started Page
            </Span>
          </NavButton>
          <NavButton
            title="Sign Up Page"
            onPress={() => navigation.navigate('SignUp')}
          >
            <Span>
              Sign Up Page
            </Span>
          </NavButton>
          <NavButton
            title="Try It Page"
            onPress={() => navigation.navigate('TryIt')}
          >
            <Span>
              Try It Interview
            </Span>
          </NavButton>
          <NavButton
            title="Video Baking Page"
            onPress={() => navigation.navigate('VideoBaking')}
          >
            <Span>
              Video Baking Page
            </Span>
          </NavButton>
          <NavButton
            title="Watch Interview Page"
            onPress={() => navigation.navigate('WatchInterview')}
          >
            <Span>
              Watch Interview Page
            </Span>
          </NavButton>
          <NavButton
            title="Done With Interview Page"
            onPress={() => navigation.navigate('FinishedInterview')}
          >
            <Span>
              Done With Interview Page
            </Span>
          </NavButton>
        </View>
      </Container>
    )
  }
}

export default HomeScreen

import { StyleSheet } from 'react-native';

export const standardStyles = StyleSheet.create({

});
export const underW500 = StyleSheet.create({
    product: {
        flexBasis: '100%'
    }
});
export const underW376underH668 = StyleSheet.create({

});
export const underW769 = StyleSheet.create({
    product: {
        flexBasis: '48%'
    }
});
export const underW821 = StyleSheet.create({
    product: {
        flexBasis: '48.5%'
    }
});
export const underW913 = StyleSheet.create({
    product: {
        flexBasis: '48.5%'
    }
});


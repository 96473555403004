import { React, useState } from 'react';
import { KeyboardAvoidingView, StyleSheet, Text, View } from 'react-native';
import { TextInput, TouchableOpacity } from 'react-native-web';
import { GoogleButton } from 'react-google-button';
import { UserAuth } from '../context/AuthContext';
import styled, { css } from "@emotion/native";
import {
  Footer,
  Header,
  IvyPresto,
  SSTextMed,
  SSTextBold,
} from "../constants/GlobalElements";
import { useHover } from "react-native-web-hooks";
const textColor = "#49381F";
const Container = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #F4ECD9;
  min-height: 100vh;
`;
const UIContainer = styled.View`
  width: 100%;
  align-items: center;
  height: calc(100vh - 190px);
`;
const SignInCore = styled.View`
    background: #F7F4E9;
    width: 100%;
    min-height: 400px;
    border-radius: 28px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 65px;
    padding-top: 45px;
    border: 1px solid rgba(226, 222, 204, .7);
    box-shadow: 0 5px 32px rgba(181, 150, 104, .1);
`
const ButtonBox = styled.View`
    align-items: end;
    justify-content: center;
    height: 80px;
    width: 100%;
`
const ConfirmButton = styled.Pressable`
  display: flex;
  border-radius: 999px;
  padding: 5px;
  width: max-content;
  height: max-content;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
`;
const ButtonTemplate = styled.Pressable`
  display: flex;
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  flex: 1;
  height: 45px;
  flex-direction: row;
  flex-basis: 50%;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
`;

const LoginScreen = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { googleSignIn, user, emailLogin, emailSignUp, logOut } = UserAuth();


  const handleGoogleSignIn = async () => {
    try {
      await googleSignIn();
      navigate("UserDashboard");
    } catch (err) {
      console.log(err);
    }
  }

  const handleSignOut = () => {
    try {
      logOut();
      navigate("UserDashboard")
    } catch (err) {
      console.log(err);
    };
  }

  const handleEmailSignUp = () => {
    try {
      emailSignUp(email, password);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEmailLogin = async () => {
    try {
      await emailLogin(email, password);
      navigation.navigate("UserDashboard");
    } catch (err) {
      console.log(err);
    };
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior="padding"
    >
      <View style={styles.inputContainer}>
        <TextInput
          placeholder="Email"
          value={email}
          onChangeText={text => setEmail(text)}
          style={styles.input}
        />
        <TextInput
          placeholder="Password"
          value={password}
          onChangeText={text => setPassword(text)}
          style={styles.input}
          secureTextEntry
        />
      </View>
      {user ? <button onClick={handleSignOut}>Logout</button> : <p>Not Signed In</p>}
      <View style={styles.buttonContainer}>
        <TouchableOpacity
          onPress={handleEmailLogin}
          style={styles.button}
        >
          <Text style={styles.button}>Login</Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => handleEmailSignUp.then(() => navigation.navigate("UserDashboard"))}
          style={[styles.button, styles.buttonOutline]}
        >
          <Text style={styles.buttonOutlineText}>Register</Text>
        </TouchableOpacity>
        <GoogleButton onClick={handleGoogleSignIn} />
        <TouchableOpacity
          onPress={() => goCheckout(user)}
          style={styles.button}
        >
          <Text style={styles.button}>Checkout</Text>
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  )
}

export default LoginScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputContainer: {
    width: '80%'
  },
  input: {
    backgroundColor: 'white',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 10,
    marginTop: 5,
  },
  buttonContainer: {
    width: '60%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
  },
  button: {
    backgroundColor: '#0782F9',
    width: '100%',
    padding: 15,
    borderRadius: 10,
    alignItems: 'center',
  },
  buttonOutline: {
    backgroundColor: 'white',
    marginTop: 5,
    borderColor: '#0782F9',
    borderWidth: 2,
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: 16,
  },
  buttonOutlineText: {
    color: '#0782F9',
    fontWeight: '700',
    fontSize: 16,
  },
})